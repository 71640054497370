'use strict';


function isWhitespace(s) {
  if (s === /* ' ' */32 || s === /* '\t' */9) {
    return true;
  } else {
    return s === /* '\n' */10;
  }
}

function isLowerLetter(s) {
  if (s >= /* 'a' */97) {
    return s <= /* 'z' */122;
  } else {
    return false;
  }
}

function isUpperLetter(s) {
  if (s >= /* 'A' */65) {
    return s <= /* 'Z' */90;
  } else {
    return false;
  }
}

function isLetter(s) {
  if (isLowerLetter(s)) {
    return true;
  } else {
    return isUpperLetter(s);
  }
}

function isNumeric(s) {
  if (s >= /* '0' */48) {
    return s <= /* '9' */57;
  } else {
    return false;
  }
}

function isAlphanumeric(s) {
  if (isLetter(s)) {
    return true;
  } else {
    return isNumeric(s);
  }
}

exports.isWhitespace = isWhitespace;
exports.isLowerLetter = isLowerLetter;
exports.isUpperLetter = isUpperLetter;
exports.isLetter = isLetter;
exports.isNumeric = isNumeric;
exports.isAlphanumeric = isAlphanumeric;
/* No side effect */
