'use strict';

var Utils$Poly = require("../utils/Utils.bs.js");
var LexError$Poly = require("./LexError.bs.js");

function classForTokenType(tokenType) {
  switch (tokenType) {
    case /* NORMAL */0 :
        return "normal";
    case /* OPERATOR */1 :
        return "operator";
    case /* IDENTIFIER */2 :
        return "identifier";
    case /* NUMBER */3 :
        return "number";
    case /* TEXT */4 :
        return "text";
    case /* RESERVE_WORD */5 :
        return "reserveword";
    case /* COMMENT */6 :
        return "comment";
    case /* ERROR */7 :
        return "error";
    
  }
}

function toString(token) {
  if (typeof token === "number") {
    switch (token) {
      case /* SEMICOLON */0 :
          return "SEMICOLON";
      case /* RETURN */1 :
          return "RETURN";
      case /* COMMA */2 :
          return "COMMA";
      case /* COLON */3 :
          return "COLON";
      case /* DOT */4 :
          return "DOT";
      case /* RANGE */5 :
          return "RANGE";
      case /* QUOTE */6 :
          return "QUOTE";
      case /* TICK */7 :
          return "TICK";
      case /* INTERP_START */8 :
          return "INTERP_START";
      case /* INTERP_END */9 :
          return "INTERP_END";
      case /* LPAREN */10 :
          return "LPAREN";
      case /* RPAREN */11 :
          return "RPAREN";
      case /* LBRACE */12 :
          return "LBRACE";
      case /* RBRACE */13 :
          return "RBRACE";
      case /* LBRACKET */14 :
          return "LBRACKET";
      case /* RBRACKET */15 :
          return "RBRACKET";
      case /* ASSIGN */16 :
          return "ASSIGN";
      case /* PLUS */17 :
          return "PLUS";
      case /* MINUS */18 :
          return "MINUS";
      case /* MULTIPLY */19 :
          return "MULTIPLY";
      case /* DIVIDE */20 :
          return "DIVIDE";
      case /* GREATER */21 :
          return "GREATER";
      case /* LESSER */22 :
          return "LESSER";
      case /* POWER */23 :
          return "POWER";
      case /* EQUALS */24 :
          return "EQUALS";
      case /* NOT */25 :
          return "NOT";
      case /* UNEQUALS */26 :
          return "UNEQUALS";
      case /* INCREMENT */27 :
          return "INCREMENT";
      case /* DECREMENT */28 :
          return "DECREMENT";
      case /* PLUS_EQUALS */29 :
          return "PLUS_EQUALS";
      case /* MINUS_EQUALS */30 :
          return "MINUS_EQUALS";
      case /* MULTIPLY_EQUALS */31 :
          return "MULTIPLY_EQUALS";
      case /* DIVIDE_EQUALS */32 :
          return "DIVIDE_EQUALS";
      case /* GREATER_EQUALS */33 :
          return "GREATER_EQUALS";
      case /* LESSER_EQUALS */34 :
          return "LESSER_EQUALS";
      case /* ARROW */35 :
          return "ARROW";
      case /* BITOR */36 :
          return "BITOR";
      case /* OR */37 :
          return "OR";
      case /* OR_EQUALS */38 :
          return "OR_EQUALS";
      case /* BITAND */39 :
          return "BITAND";
      case /* AND */40 :
          return "AND";
      case /* AND_EQUALS */41 :
          return "AND_EQUALS";
      case /* XOR */42 :
          return "XOR";
      case /* XOR_EQUALS */43 :
          return "XOR_EQUALS";
      case /* IF */44 :
          return "IF";
      case /* ELSE */45 :
          return "ELSE";
      case /* FOR */46 :
          return "FOR";
      case /* WHILE */47 :
          return "WHILE";
      case /* DO */48 :
          return "DO";
      case /* LET */49 :
          return "LET";
      case /* TYPE */50 :
          return "TYPE";
      case /* CLASS */51 :
          return "CLASS";
      case /* IN */52 :
          return "IN";
      
    }
  } else {
    switch (token.TAG | 0) {
      case /* IDENTIFIER */0 :
          return Utils$Poly.variantToString("IDENTIFIER", {
                      hd: Utils$Poly.$$escape(token._0),
                      tl: /* [] */0
                    });
      case /* NUMBER */1 :
          return Utils$Poly.variantToString("NUMBER", {
                      hd: Utils$Poly.$$escape(token._0),
                      tl: /* [] */0
                    });
      case /* TYPECLASS */2 :
          return Utils$Poly.variantToString("TYPECLASS", {
                      hd: Utils$Poly.$$escape(token._0),
                      tl: /* [] */0
                    });
      case /* TEXT */3 :
          return Utils$Poly.variantToString("TEXT", {
                      hd: Utils$Poly.$$escape(token._0),
                      tl: /* [] */0
                    });
      case /* ERROR */4 :
          return Utils$Poly.variantToString("ERROR", {
                      hd: LexError$Poly.errorToString(token._0),
                      tl: /* [] */0
                    });
      
    }
  }
}

function optionToString(optionToken) {
  return Utils$Poly.optionToString(optionToken, toString);
}

function getType(token) {
  if (typeof token === "number") {
    switch (token) {
      case /* QUOTE */6 :
      case /* TICK */7 :
          return /* TEXT */4;
      case /* SEMICOLON */0 :
      case /* COMMA */2 :
      case /* COLON */3 :
      case /* LPAREN */10 :
      case /* RPAREN */11 :
      case /* LBRACE */12 :
      case /* RBRACE */13 :
      case /* LBRACKET */14 :
      case /* RBRACKET */15 :
          return /* NORMAL */0;
      case /* RETURN */1 :
      case /* IF */44 :
      case /* ELSE */45 :
      case /* FOR */46 :
      case /* WHILE */47 :
      case /* DO */48 :
      case /* LET */49 :
      case /* TYPE */50 :
      case /* CLASS */51 :
      case /* IN */52 :
          return /* RESERVE_WORD */5;
      default:
        return /* OPERATOR */1;
    }
  } else {
    switch (token.TAG | 0) {
      case /* NUMBER */1 :
          return /* NUMBER */3;
      case /* IDENTIFIER */0 :
      case /* TYPECLASS */2 :
          return /* IDENTIFIER */2;
      case /* TEXT */3 :
          return /* TEXT */4;
      case /* ERROR */4 :
          return /* ERROR */7;
      
    }
  }
}

exports.classForTokenType = classForTokenType;
exports.toString = toString;
exports.optionToString = optionToString;
exports.getType = getType;
/* No side effect */
