'use strict';

var List = require("rescript/lib/js/list.js");
var Token$Poly = require("../lexer/Token.bs.js");
var Utils$Poly = require("../utils/Utils.bs.js");

function tokenToSimple(token) {
  return token.token;
}

function optionTokenToSimple(optionToken) {
  if (optionToken !== undefined) {
    return optionToken.token;
  }
  
}

function expressionErrorToSimple(e) {
  switch (e.TAG | 0) {
    case /* UNEXPECTED_PRIMARY_TOKEN */0 :
        return {
                TAG: /* UNEXPECTED_PRIMARY_TOKEN */0,
                _0: optionTokenToSimple(e.errorToken)
              };
    case /* EXPECTING_LOWERCASE_VARIABLE */1 :
        return {
                TAG: /* EXPECTING_LOWERCASE_VARIABLE */1,
                _0: e.errorToken.token
              };
    case /* UNEXPECTED_ARRAY_TOKEN */2 :
        return {
                TAG: /* UNEXPECTED_ARRAY_TOKEN */2,
                _0: optionTokenToSimple(e.errorToken)
              };
    case /* EXPECTING_INDEX_RBRACKET */3 :
        return /* EXPECTING_INDEX_BRACKET */1;
    case /* EXPECTING_INTERP_END */4 :
        return /* EXPECTING_INTERP_END */2;
    case /* UNEXPECTED_TEXT_TOKEN */5 :
        return {
                TAG: /* UNEXPECTED_TEXT_TOKEN */3,
                _0: optionTokenToSimple(e.errorToken)
              };
    case /* UNEXPECTED_GROUPING_TOKEN */6 :
        return {
                TAG: /* UNEXPECTED_GROUPING_TOKEN */4,
                _0: optionTokenToSimple(e.errorToken)
              };
    case /* TUPLE_UNNAMED_AFTER_NAMED */7 :
        if (e.mode) {
          return {
                  TAG: /* TUPLE_UNNAMED_AFTER_NAMED */5,
                  _0: /* CALL */1
                };
        } else {
          return {
                  TAG: /* TUPLE_UNNAMED_AFTER_NAMED */5,
                  _0: /* TUPLE */0
                };
        }
    case /* UNEXPECTED_DICTIONARY_TOKEN */8 :
        return {
                TAG: /* UNEXPECTED_DICTIONARY_TOKEN */6,
                _0: optionTokenToSimple(e.errorToken)
              };
    case /* DICTIONARY_NONVARIABLE_SHORTFORM */9 :
        return /* DICTIONARY_NONVARIABLE_SHORTFORM */3;
    case /* EXPECTING_UPPERCASE_TYPE */10 :
        return {
                TAG: /* EXPECTING_UPPERCASE_TYPE */7,
                _0: e.errorToken.token
              };
    case /* UNEXPECTED_TYPE_TOKEN */11 :
        return {
                TAG: /* UNEXPECTED_TYPE_TOKEN */8,
                _0: optionTokenToSimple(e.errorToken)
              };
    case /* UNEXPECTED_COMMA_IN_ARRAY_TYPE */12 :
        return /* UNEXPECTED_COMMA_IN_ARRAY_TYPE */4;
    case /* EXPECTING_ARRAY_TYPE_RBRACKET */13 :
        return /* EXPECTING_ARRAY_TYPE_RBRACKET */5;
    case /* EXPECTING_DICTIONARY_TYPE_RBRACE */14 :
        return /* EXPECTING_DICTIONARY_TYPE_RBRACE */6;
    case /* EXPECTING_DICTIONARY_TYPE_COLON */15 :
        return /* EXPECTING_DICTIONARY_TYPE_COLON */7;
    case /* UNEXPECTED_TUPLE_TYPE_TOKEN */16 :
        return {
                TAG: /* UNEXPECTED_TUPLE_TYPE_TOKEN */9,
                _0: optionTokenToSimple(e.errorToken)
              };
    case /* EXPECTING_LOWERCASE_TUPLE_NAMED_PARAMETER */17 :
        return {
                TAG: /* EXPECTING_LOWERCASE_TUPLE_NAMED_PARAMETER */10,
                _0: e.errorToken.token
              };
    case /* TUPLE_TYPE_UNNAMED_AFTER_NAMED */18 :
        return /* TUPLE_TYPE_UNNAMED_AFTER_NAMED */8;
    case /* TUPLE_TYPE_NAMED_AFTER_UNNAMED */19 :
        return /* TUPLE_TYPE_NAMED_AFTER_UNNAMED */9;
    case /* TUPLE_TYPE_NAMED_AFTER_DEFAULT */20 :
        return /* TUPLE_TYPE_NAMED_AFTER_DEFAULT */10;
    case /* SHOULD_NOT_HAPPEN */21 :
        return /* SHOULD_NOT_HAPPEN */11;
    
  }
}

function tupleModeToString(t) {
  if (t) {
    return "CALL";
  } else {
    return "TUPLE";
  }
}

function expressionErrorSimpleToString(e) {
  if (typeof e === "number") {
    switch (e) {
      case /* EXPECTING_RPAREN */0 :
          return "EXPECTING_RPAREN";
      case /* EXPECTING_INDEX_BRACKET */1 :
          return "EXPECTING_INDEX_BRACKET";
      case /* EXPECTING_INTERP_END */2 :
          return "EXPECTING_INTERP_END";
      case /* DICTIONARY_NONVARIABLE_SHORTFORM */3 :
          return "DICTIONARY_NONVARIABLE_SHORTFORM";
      case /* UNEXPECTED_COMMA_IN_ARRAY_TYPE */4 :
          return "UNEXPECTED_COMMA_IN_ARRAY_TYPE";
      case /* EXPECTING_ARRAY_TYPE_RBRACKET */5 :
          return "EXPECTING_ARRAY_TYPE_RBRACKET";
      case /* EXPECTING_DICTIONARY_TYPE_RBRACE */6 :
          return "EXPECTING_DICTIONARY_TYPE_RBRACE";
      case /* EXPECTING_DICTIONARY_TYPE_COLON */7 :
          return "EXPECTING_DICTIONARY_TYPE_COLON";
      case /* TUPLE_TYPE_UNNAMED_AFTER_NAMED */8 :
          return "TUPLE_TYPE_UNNAMED_AFTER_NAMED";
      case /* TUPLE_TYPE_NAMED_AFTER_UNNAMED */9 :
          return "TUPLE_TYPE_NAMED_AFTER_UNNAMED";
      case /* TUPLE_TYPE_NAMED_AFTER_DEFAULT */10 :
          return "TUPLE_TYPE_NAMED_AFTER_DEFAULT";
      case /* SHOULD_NOT_HAPPEN */11 :
          return "SHOULD_NOT_HAPPEN";
      
    }
  } else {
    switch (e.TAG | 0) {
      case /* UNEXPECTED_PRIMARY_TOKEN */0 :
          return Utils$Poly.variantToString("UNEXPECTED_PRIMARY_TOKEN", {
                      hd: Token$Poly.optionToString(e._0),
                      tl: /* [] */0
                    });
      case /* EXPECTING_LOWERCASE_VARIABLE */1 :
          return Utils$Poly.variantToString("EXPECTING_LOWERCASE_VARIABLE", {
                      hd: Token$Poly.toString(e._0),
                      tl: /* [] */0
                    });
      case /* UNEXPECTED_ARRAY_TOKEN */2 :
          return Utils$Poly.variantToString("UNEXPECTED_ARRAY_TOKEN", {
                      hd: Token$Poly.optionToString(e._0),
                      tl: /* [] */0
                    });
      case /* UNEXPECTED_TEXT_TOKEN */3 :
          return Utils$Poly.variantToString("UNEXPECTED_TEXT_TOKEN", {
                      hd: Token$Poly.optionToString(e._0),
                      tl: /* [] */0
                    });
      case /* UNEXPECTED_GROUPING_TOKEN */4 :
          return Utils$Poly.variantToString("UNEXPECTED_GROUPING_TOKEN", {
                      hd: Token$Poly.optionToString(e._0),
                      tl: /* [] */0
                    });
      case /* TUPLE_UNNAMED_AFTER_NAMED */5 :
          return Utils$Poly.variantToString("TUPLE_UNNAMED_AFTER_NAMED", {
                      hd: e._0 ? "CALL" : "TUPLE",
                      tl: /* [] */0
                    });
      case /* UNEXPECTED_DICTIONARY_TOKEN */6 :
          return Utils$Poly.variantToString("UNEXPECTED_DICTIONARY_TOKEN", {
                      hd: Token$Poly.optionToString(e._0),
                      tl: /* [] */0
                    });
      case /* EXPECTING_UPPERCASE_TYPE */7 :
          return Utils$Poly.variantToString("EXPECTING_UPPERCASE_TYPE", {
                      hd: Token$Poly.toString(e._0),
                      tl: /* [] */0
                    });
      case /* UNEXPECTED_TYPE_TOKEN */8 :
          return Utils$Poly.variantToString("UNEXPECTED_TYPE_TOKEN", {
                      hd: Token$Poly.optionToString(e._0),
                      tl: /* [] */0
                    });
      case /* UNEXPECTED_TUPLE_TYPE_TOKEN */9 :
          return Utils$Poly.variantToString("UNEXPECTED_TUPLE_TYPE_TOKEN", {
                      hd: Token$Poly.optionToString(e._0),
                      tl: /* [] */0
                    });
      case /* EXPECTING_LOWERCASE_TUPLE_NAMED_PARAMETER */10 :
          return Utils$Poly.variantToString("EXPECTING_LOWERCASE_TUPLE_NAMED_PARAMETER", {
                      hd: Token$Poly.toString(e._0),
                      tl: /* [] */0
                    });
      
    }
  }
}

function expressionErrorToSimpleString(e) {
  return expressionErrorSimpleToString(expressionErrorToSimple(e));
}

function textToSimple(text) {
  if (text.TAG === /* CONTENTS */0) {
    return {
            TAG: /* CONTENTS */0,
            _0: text.text
          };
  } else {
    return {
            TAG: /* INTERPOLATE */1,
            _0: expressionToSimple(text.expression)
          };
  }
}

function literalToSimple(literal) {
  switch (literal.TAG | 0) {
    case /* NUMBER */0 :
        return {
                TAG: /* NUMBER */0,
                _0: literal.number
              };
    case /* TEXT */1 :
        return {
                TAG: /* TEXT */1,
                _0: List.map(textToSimple, literal.text)
              };
    case /* DICTIONARY_ITEM_TEXT */2 :
        return {
                TAG: /* DICTIONARY_ITEM_TEXT */2,
                _0: literal.contents
              };
    case /* ARRAY */3 :
        return {
                TAG: /* ARRAY */3,
                _0: List.map(expressionToSimple, literal._0.elements)
              };
    case /* TUPLE */4 :
        var match = literal._0;
        return {
                TAG: /* TUPLE */4,
                _0: List.map(expressionToSimple, match.unnamedTerms),
                _1: List.map((function (param) {
                        return [
                                param[0],
                                expressionToSimple(param[2])
                              ];
                      }), match.namedTerms)
              };
    case /* DICTIONARY */5 :
        return {
                TAG: /* DICTIONARY */5,
                _0: List.map(dictionaryItemToSimple, literal._0.items)
              };
    
  }
}

function expressionToSimple(expression) {
  switch (expression.TAG | 0) {
    case /* GROUPING */0 :
        return {
                TAG: /* GROUPING */0,
                _0: expressionToSimple(expression._0.expression)
              };
    case /* EQUALS */1 :
        var match = expression._0;
        return {
                TAG: /* EQUALS */1,
                _0: expressionToSimple(match.left),
                _1: expressionToSimple(match.right)
              };
    case /* UNEQUALS */2 :
        var match$1 = expression._0;
        return {
                TAG: /* UNEQUALS */2,
                _0: expressionToSimple(match$1.left),
                _1: expressionToSimple(match$1.right)
              };
    case /* GREATER */3 :
        var match$2 = expression._0;
        return {
                TAG: /* GREATER */3,
                _0: expressionToSimple(match$2.left),
                _1: expressionToSimple(match$2.right)
              };
    case /* LESSER */4 :
        var match$3 = expression._0;
        return {
                TAG: /* LESSER */4,
                _0: expressionToSimple(match$3.left),
                _1: expressionToSimple(match$3.right)
              };
    case /* GREATER_EQUALS */5 :
        var match$4 = expression._0;
        return {
                TAG: /* GREATER_EQUALS */5,
                _0: expressionToSimple(match$4.left),
                _1: expressionToSimple(match$4.right)
              };
    case /* LESSER_EQUALS */6 :
        var match$5 = expression._0;
        return {
                TAG: /* LESSER_EQUALS */6,
                _0: expressionToSimple(match$5.left),
                _1: expressionToSimple(match$5.right)
              };
    case /* PLUS */7 :
        var match$6 = expression._0;
        return {
                TAG: /* PLUS */7,
                _0: expressionToSimple(match$6.left),
                _1: expressionToSimple(match$6.right)
              };
    case /* MINUS */8 :
        var match$7 = expression._0;
        return {
                TAG: /* MINUS */8,
                _0: expressionToSimple(match$7.left),
                _1: expressionToSimple(match$7.right)
              };
    case /* DIVIDE */9 :
        var match$8 = expression._0;
        return {
                TAG: /* DIVIDE */9,
                _0: expressionToSimple(match$8.left),
                _1: expressionToSimple(match$8.right)
              };
    case /* MULTIPLY */10 :
        var match$9 = expression._0;
        return {
                TAG: /* MULTIPLY */10,
                _0: expressionToSimple(match$9.left),
                _1: expressionToSimple(match$9.right)
              };
    case /* POWER */11 :
        var match$10 = expression._0;
        return {
                TAG: /* POWER */11,
                _0: expressionToSimple(match$10.left),
                _1: expressionToSimple(match$10.right)
              };
    case /* RANGE */12 :
        var match$11 = expression._0;
        var start = match$11.startExpression;
        if (start !== undefined) {
          return {
                  TAG: /* RANGE */12,
                  _0: expressionToSimple(start),
                  _1: expressionToSimple(match$11.endExpression)
                };
        } else {
          return {
                  TAG: /* RANGE */12,
                  _0: undefined,
                  _1: expressionToSimple(match$11.endExpression)
                };
        }
    case /* NEGATE */13 :
        return {
                TAG: /* NEGATE */13,
                _0: expressionToSimple(expression._0.operand)
              };
    case /* NOT */14 :
        return {
                TAG: /* NOT */14,
                _0: expressionToSimple(expression._0.operand)
              };
    case /* VARIABLE */15 :
        return {
                TAG: /* VARIABLE */15,
                _0: expression._0.name
              };
    case /* INDEX */16 :
        return {
                TAG: /* INDEX */16,
                _0: expressionToSimple(expression.expression),
                _1: expressionToSimple(expression.index)
              };
    case /* CALL */17 :
        return {
                TAG: /* CALL */17,
                _0: expressionToSimple(expression.expression),
                _1: List.map(expressionToSimple, expression.unnamedArgs),
                _2: List.map((function (param) {
                        return [
                                param[0],
                                expressionToSimple(param[2])
                              ];
                      }), expression.namedArgs)
              };
    case /* LITERAL */18 :
        return {
                TAG: /* LITERAL */18,
                _0: literalToSimple(expression._0)
              };
    case /* EXPRESSION_ERROR */19 :
        return {
                TAG: /* EXPRESSION_ERROR */19,
                _0: expressionErrorToSimple(expression._0)
              };
    
  }
}

function typeExpressionToSimple(typeExpression) {
  switch (typeExpression.TAG | 0) {
    case /* TYPE */0 :
        return {
                TAG: /* TYPE */0,
                _0: typeExpression.name
              };
    case /* ARRAY_TYPE */1 :
        return {
                TAG: /* ARRAY_TYPE */1,
                _0: typeExpressionToSimple(typeExpression.typeExpression)
              };
    case /* TUPLE_TYPE */2 :
        return {
                TAG: /* TUPLE_TYPE */2,
                _0: List.map(typeExpressionToSimple, typeExpression.types)
              };
    case /* NAMED_TUPLE_TYPE */3 :
        return {
                TAG: /* NAMED_TUPLE_TYPE */3,
                _0: List.map((function (param) {
                        return [
                                param[0],
                                typeExpressionToSimple(param[2])
                              ];
                      }), typeExpression.namedTypes),
                _1: List.map((function (param) {
                        return [
                                param[0],
                                typeExpressionToSimple(param[2]),
                                expressionToSimple(param[4])
                              ];
                      }), typeExpression.defaultTypes)
              };
    case /* DICTIONARY_TYPE */4 :
        return {
                TAG: /* DICTIONARY_TYPE */4,
                _0: typeExpressionToSimple(typeExpression.keyType),
                _1: typeExpressionToSimple(typeExpression.valueType)
              };
    
  }
}

function dictionaryItemToSimple(dictionaryItem) {
  if (dictionaryItem.TAG === /* REGULAR */0) {
    return {
            TAG: /* REGULAR */0,
            _0: expressionToSimple(dictionaryItem.key),
            _1: expressionToSimple(dictionaryItem.value)
          };
  } else {
    return {
            TAG: /* SHORTFORM */1,
            _0: dictionaryItem.key.name
          };
  }
}

function variableToSimple(variable) {
  return variable.name;
}

function variableDeclarationToSimple(variableDeclaration) {
  var match = variableDeclaration.variableType;
  return [
          variableDeclaration.variable.name,
          match !== undefined ? typeExpressionToSimple(match.typeExpression) : undefined
        ];
}

function textSimpleToString(t) {
  if (t.TAG === /* CONTENTS */0) {
    return Utils$Poly.variantToString("CONTENTS", {
                hd: Utils$Poly.$$escape(t._0),
                tl: /* [] */0
              });
  } else {
    return Utils$Poly.variantToString("INTERPOLATE", {
                hd: expressionSimpleToString(t._0),
                tl: /* [] */0
              });
  }
}

function expressionSimpleToString(expressionSimple) {
  switch (expressionSimple.TAG | 0) {
    case /* GROUPING */0 :
        return Utils$Poly.variantToString("GROUPING", {
                    hd: expressionSimpleToString(expressionSimple._0),
                    tl: /* [] */0
                  });
    case /* EQUALS */1 :
        return binExpressionToString("EQUALS", expressionSimple._0, expressionSimple._1);
    case /* UNEQUALS */2 :
        return binExpressionToString("UNEQUALS", expressionSimple._0, expressionSimple._1);
    case /* GREATER */3 :
        return binExpressionToString("GREATER", expressionSimple._0, expressionSimple._1);
    case /* LESSER */4 :
        return binExpressionToString("LESSER", expressionSimple._0, expressionSimple._1);
    case /* GREATER_EQUALS */5 :
        return binExpressionToString("GREATER_EQUALS", expressionSimple._0, expressionSimple._1);
    case /* LESSER_EQUALS */6 :
        return binExpressionToString("LESSER_EQUALS", expressionSimple._0, expressionSimple._1);
    case /* PLUS */7 :
        return binExpressionToString("PLUS", expressionSimple._0, expressionSimple._1);
    case /* MINUS */8 :
        return binExpressionToString("MINUS", expressionSimple._0, expressionSimple._1);
    case /* DIVIDE */9 :
        return binExpressionToString("DIVIDE", expressionSimple._0, expressionSimple._1);
    case /* MULTIPLY */10 :
        return binExpressionToString("MULTIPLY", expressionSimple._0, expressionSimple._1);
    case /* POWER */11 :
        return binExpressionToString("POWER", expressionSimple._0, expressionSimple._1);
    case /* RANGE */12 :
        return Utils$Poly.variantToString("RANGE", {
                    hd: Utils$Poly.optionToString(expressionSimple._0, expressionSimpleToString),
                    tl: {
                      hd: expressionSimpleToString(expressionSimple._1),
                      tl: /* [] */0
                    }
                  });
    case /* NEGATE */13 :
        return unaExpressionToString("NEGATE", expressionSimple._0);
    case /* NOT */14 :
        return unaExpressionToString("NOT", expressionSimple._0);
    case /* VARIABLE */15 :
        return Utils$Poly.variantToString("VARIABLE", {
                    hd: Utils$Poly.$$escape(expressionSimple._0),
                    tl: /* [] */0
                  });
    case /* INDEX */16 :
        return Utils$Poly.variantToString("INDEX", {
                    hd: expressionSimpleToString(expressionSimple._0),
                    tl: {
                      hd: expressionSimpleToString(expressionSimple._1),
                      tl: /* [] */0
                    }
                  });
    case /* CALL */17 :
        return Utils$Poly.variantToString("CALL", {
                    hd: expressionSimpleToString(expressionSimple._0),
                    tl: {
                      hd: Utils$Poly.listToString(List.map(expressionSimpleToString, expressionSimple._1)),
                      tl: {
                        hd: Utils$Poly.listToString(List.map((function (param) {
                                    return Utils$Poly.tupleToString({
                                                hd: Utils$Poly.$$escape(param[0]),
                                                tl: {
                                                  hd: expressionSimpleToString(param[1]),
                                                  tl: /* [] */0
                                                }
                                              });
                                  }), expressionSimple._2)),
                        tl: /* [] */0
                      }
                    }
                  });
    case /* LITERAL */18 :
        return Utils$Poly.variantToString("LITERAL", {
                    hd: literalSimpleToString(expressionSimple._0),
                    tl: /* [] */0
                  });
    case /* EXPRESSION_ERROR */19 :
        return Utils$Poly.variantToString("EXPRESSION_ERROR", {
                    hd: expressionErrorSimpleToString(expressionSimple._0),
                    tl: /* [] */0
                  });
    
  }
}

function literalSimpleToString(l) {
  switch (l.TAG | 0) {
    case /* NUMBER */0 :
        return Utils$Poly.variantToString("NUMBER", {
                    hd: Utils$Poly.floatToString(l._0),
                    tl: /* [] */0
                  });
    case /* TEXT */1 :
        return Utils$Poly.variantToString("TEXT", {
                    hd: Utils$Poly.listToString(List.map(textSimpleToString, l._0)),
                    tl: /* [] */0
                  });
    case /* DICTIONARY_ITEM_TEXT */2 :
        return Utils$Poly.variantToString("DICTIONARY_ITEM_TEXT", {
                    hd: Utils$Poly.$$escape(l._0),
                    tl: /* [] */0
                  });
    case /* ARRAY */3 :
        return Utils$Poly.variantToString("ARRAY", {
                    hd: Utils$Poly.listToString(List.map(expressionSimpleToString, l._0)),
                    tl: /* [] */0
                  });
    case /* TUPLE */4 :
        return Utils$Poly.variantToString("TUPLE", {
                    hd: Utils$Poly.listToString(List.map(expressionSimpleToString, l._0)),
                    tl: {
                      hd: Utils$Poly.listToString(List.map((function (param) {
                                  return "(" + (Utils$Poly.$$escape(param[0]) + ("," + (expressionSimpleToString(param[1]) + ")")));
                                }), l._1)),
                      tl: /* [] */0
                    }
                  });
    case /* DICTIONARY */5 :
        return Utils$Poly.variantToString("DICTIONARY", {
                    hd: Utils$Poly.listToString(List.map(dictionaryItemSimpleToString, l._0)),
                    tl: /* [] */0
                  });
    
  }
}

function unaExpressionToString(op, left) {
  return Utils$Poly.variantToString(op, {
              hd: expressionSimpleToString(left),
              tl: /* [] */0
            });
}

function binExpressionToString(op, left, right) {
  return Utils$Poly.variantToString(op, {
              hd: expressionSimpleToString(left),
              tl: {
                hd: expressionSimpleToString(right),
                tl: /* [] */0
              }
            });
}

function typeExpressionSimpleToString(typeExpressionSimple) {
  switch (typeExpressionSimple.TAG | 0) {
    case /* TYPE */0 :
        return Utils$Poly.variantToString("TYPE", {
                    hd: Utils$Poly.$$escape(typeExpressionSimple._0),
                    tl: /* [] */0
                  });
    case /* ARRAY_TYPE */1 :
        return Utils$Poly.variantToString("ARRAY_TYPE", {
                    hd: typeExpressionSimpleToString(typeExpressionSimple._0),
                    tl: /* [] */0
                  });
    case /* TUPLE_TYPE */2 :
        return Utils$Poly.variantToString("TUPLE_TYPE", {
                    hd: Utils$Poly.listToString(List.map(typeExpressionSimpleToString, typeExpressionSimple._0)),
                    tl: /* [] */0
                  });
    case /* NAMED_TUPLE_TYPE */3 :
        return Utils$Poly.variantToString("NAMED_TUPLE_TYPE", {
                    hd: Utils$Poly.listToString(List.map((function (param) {
                                return Utils$Poly.tupleToString({
                                            hd: Utils$Poly.$$escape(param[0]),
                                            tl: {
                                              hd: typeExpressionSimpleToString(param[1]),
                                              tl: /* [] */0
                                            }
                                          });
                              }), typeExpressionSimple._0)),
                    tl: {
                      hd: Utils$Poly.listToString(List.map((function (param) {
                                  return Utils$Poly.tupleToString({
                                              hd: Utils$Poly.$$escape(param[0]),
                                              tl: {
                                                hd: typeExpressionSimpleToString(param[1]),
                                                tl: {
                                                  hd: expressionSimpleToString(param[2]),
                                                  tl: /* [] */0
                                                }
                                              }
                                            });
                                }), typeExpressionSimple._1)),
                      tl: /* [] */0
                    }
                  });
    case /* DICTIONARY_TYPE */4 :
        return Utils$Poly.variantToString("DICTIONARY_TYPE", {
                    hd: typeExpressionSimpleToString(typeExpressionSimple._0),
                    tl: {
                      hd: typeExpressionSimpleToString(typeExpressionSimple._1),
                      tl: /* [] */0
                    }
                  });
    
  }
}

function dictionaryItemSimpleToString(d) {
  if (d.TAG === /* REGULAR */0) {
    return Utils$Poly.variantToString("REGULAR", {
                hd: expressionSimpleToString(d._0),
                tl: {
                  hd: expressionSimpleToString(d._1),
                  tl: /* [] */0
                }
              });
  } else {
    return Utils$Poly.variantToString("SHORTFORM", {
                hd: Utils$Poly.$$escape(d._0),
                tl: /* [] */0
              });
  }
}

var variableSimpleToString = Utils$Poly.$$escape;

function variableDeclarationSimpleToString(variableDeclarationSimple) {
  return Utils$Poly.tupleToString({
              hd: Utils$Poly.$$escape(variableDeclarationSimple[0]),
              tl: {
                hd: Utils$Poly.optionToString(variableDeclarationSimple[1], typeExpressionSimpleToString),
                tl: /* [] */0
              }
            });
}

function expressionToSimpleString(expression) {
  return expressionSimpleToString(expressionToSimple(expression));
}

function literalToSimpleString(l) {
  return literalSimpleToString(literalToSimple(l));
}

function statementErrorToSimple(e) {
  switch (e.TAG | 0) {
    case /* EXPECTING_SEMICOLON_AFTER_DECLARATION_STATEMENT */0 :
        return /* EXPECTING_SEMICOLON_AFTER_DECLARATION_STATEMENT */0;
    case /* EXPECTING_EQUALS_AFTER_VARIABLE_DECLARATION_STATEMENT */1 :
        return /* EXPECTING_EQUALS_AFTER_VARIABLE_DECLARATION_STATEMENT */1;
    case /* EXPECTING_VARIABLE_DECLARATION_STATEMENT */2 :
        return /* EXPECTING_VARIABLE_DECLARATION_STATEMENT */2;
    case /* EXPECTING_SEMICOLON_AFTER_REASSIGN_STATEMENT */3 :
        return /* EXPECTING_SEMICOLON_AFTER_REASSIGN_STATEMENT */3;
    case /* EXPECTING_EQUALS_AFTER_REASSIGN_STATEMENT */4 :
        return /* EXPECTING_EQUALS_AFTER_REASSIGN_STATEMENT */4;
    case /* EXPECTING_SEMICOLON_AFTER_EXPRESSION_STATEMENT */5 :
        return /* EXPECTING_SEMICOLON_AFTER_EXPRESSION_STATEMENT */5;
    case /* EXPECTING_RBRACE */6 :
        return /* EXPECTING_RBRACE */6;
    case /* EXPECTING_BRACE_AFTER_IF */7 :
        return /* EXPECTING_BRACE_AFTER_IF */7;
    case /* EXPECTING_IF_OR_BLOCK_AFTER_ELSE */8 :
        return /* EXPECTING_IF_OR_BLOCK_AFTER_ELSE */8;
    case /* EXPECTING_IN_TOKEN_FOR_IN */9 :
        return /* EXPECTING_IN_TOKEN_FOR_IN */10;
    case /* EXPECTING_BRACE_AFTER_FOR_IN */10 :
        return /* EXPECTING_BRACE_AFTER_FOR_IN */11;
    
  }
}

function statementErrorSimpleToString(e) {
  switch (e) {
    case /* EXPECTING_SEMICOLON_AFTER_DECLARATION_STATEMENT */0 :
        return "EXPECTING_SEMICOLON_AFTER_DECLARATION_STATEMENT";
    case /* EXPECTING_EQUALS_AFTER_VARIABLE_DECLARATION_STATEMENT */1 :
        return "EXPECTING_EQUALS_AFTER_VARIABLE_DECLARATION_STATEMENT";
    case /* EXPECTING_VARIABLE_DECLARATION_STATEMENT */2 :
        return "EXPECTING_VARIABLE_DECLARATION_STATEMENT";
    case /* EXPECTING_SEMICOLON_AFTER_REASSIGN_STATEMENT */3 :
        return "EXPECTING_SEMICOLON_AFTER_REASSIGN_STATEMENT";
    case /* EXPECTING_EQUALS_AFTER_REASSIGN_STATEMENT */4 :
        return "EXPECTING_EQUALS_AFTER_REASSIGN_STATEMENT";
    case /* EXPECTING_SEMICOLON_AFTER_EXPRESSION_STATEMENT */5 :
        return "EXPECTING_SEMICOLON_AFTER_EXPRESSION_STATEMENT";
    case /* EXPECTING_RBRACE */6 :
        return "EXPECTING_RBRACE";
    case /* EXPECTING_BRACE_AFTER_IF */7 :
        return "EXPECTING_BRACE_AFTER_IF";
    case /* EXPECTING_IF_OR_BLOCK_AFTER_ELSE */8 :
        return "EXPECTING_IF_OR_BLOCK_AFTER_ELSE";
    case /* EXPECTING_IDENTIFIER_EXPRESSION_ERROR_FOR_IN */9 :
        return "EXPECTING_IDENTIFIER_EXPRESSION_ERROR_FOR_IN";
    case /* EXPECTING_IN_TOKEN_FOR_IN */10 :
        return "EXPECTING_IN_TOKEN_FOR_IN";
    case /* EXPECTING_BRACE_AFTER_FOR_IN */11 :
        return "EXPECTING_BRACE_AFTER_FOR_IN";
    
  }
}

function statementErrorToSimpleString(e) {
  return statementErrorSimpleToString(statementErrorToSimple(e));
}

function statementToSimple(statement) {
  if (typeof statement === "number") {
    return /* STATEMENT_ERROR */0;
  }
  switch (statement.TAG | 0) {
    case /* DECLARATION */0 :
        return {
                TAG: /* DECLARATION */0,
                _0: variableDeclarationToSimple(statement.variableDeclaration),
                _1: expressionToSimple(statement.expression)
              };
    case /* REASSIGN */1 :
        return {
                TAG: /* REASSIGN */1,
                _0: statement.variable.name,
                _1: expressionToSimple(statement.expression)
              };
    case /* EXPRESSION */2 :
        return {
                TAG: /* EXPRESSION */2,
                _0: expressionToSimple(statement.expression)
              };
    case /* IF */3 :
        return {
                TAG: /* IF */3,
                _0: ifToSimple(statement._0)
              };
    case /* FOR_IN */4 :
        return {
                TAG: /* FOR_IN */4,
                _0: variableDeclarationToSimple(statement.identifier),
                _1: expressionToSimple(statement.expression),
                _2: blockToSimple(statement.block)
              };
    case /* BLOCK */5 :
        return {
                TAG: /* BLOCK */5,
                _0: blockToSimple(statement._0)
              };
    
  }
}

function blockToSimple(block) {
  return List.map(statementToSimple, block.statements);
}

function ifToSimple(ifStatement) {
  var elseStatement = ifStatement.elseStatement;
  return [
          expressionToSimple(ifStatement.expression),
          blockToSimple(ifStatement.block),
          elseStatement !== undefined ? elseToSimple(elseStatement) : undefined
        ];
}

function elseToSimple(elseStatement) {
  if (elseStatement.TAG === /* ELSE_IF */0) {
    return {
            TAG: /* ELSE_IF */0,
            _0: ifToSimple(elseStatement.ifStatement)
          };
  } else {
    return {
            TAG: /* ELSE */1,
            _0: blockToSimple(elseStatement.block)
          };
  }
}

function statementSimpleToString(statementSimple) {
  if (typeof statementSimple === "number") {
    return "STATEMENT_ERROR";
  }
  switch (statementSimple.TAG | 0) {
    case /* DECLARATION */0 :
        return Utils$Poly.variantToString("DECLARATION", {
                    hd: variableDeclarationSimpleToString(statementSimple._0),
                    tl: {
                      hd: expressionSimpleToString(statementSimple._1),
                      tl: /* [] */0
                    }
                  });
    case /* REASSIGN */1 :
        return Utils$Poly.variantToString("REASSIGN", {
                    hd: Utils$Poly.$$escape(statementSimple._0),
                    tl: {
                      hd: expressionSimpleToString(statementSimple._1),
                      tl: /* [] */0
                    }
                  });
    case /* EXPRESSION */2 :
        return Utils$Poly.variantToString("EXPRESSION", {
                    hd: expressionSimpleToString(statementSimple._0),
                    tl: /* [] */0
                  });
    case /* IF */3 :
        return Utils$Poly.variantToString("IF", {
                    hd: ifStatementSimpleToString(statementSimple._0),
                    tl: /* [] */0
                  });
    case /* FOR_IN */4 :
        return Utils$Poly.variantToString("FOR_IN", {
                    hd: variableDeclarationSimpleToString(statementSimple._0),
                    tl: {
                      hd: expressionSimpleToString(statementSimple._1),
                      tl: {
                        hd: blockSimpleToString(statementSimple._2),
                        tl: /* [] */0
                      }
                    }
                  });
    case /* BLOCK */5 :
        return Utils$Poly.variantToString("BLOCK", {
                    hd: blockSimpleToString(statementSimple._0),
                    tl: /* [] */0
                  });
    
  }
}

function blockSimpleToString(blockSimple) {
  return Utils$Poly.listToString(List.map(statementSimpleToString, blockSimple));
}

function ifStatementSimpleToString(ifStatementSimple) {
  return Utils$Poly.tupleToString({
              hd: expressionSimpleToString(ifStatementSimple[0]),
              tl: {
                hd: blockSimpleToString(ifStatementSimple[1]),
                tl: {
                  hd: Utils$Poly.optionToString(ifStatementSimple[2], elseStatementSimpleToString),
                  tl: /* [] */0
                }
              }
            });
}

function elseStatementSimpleToString(elseStatementSimple) {
  if (elseStatementSimple.TAG === /* ELSE_IF */0) {
    return Utils$Poly.variantToString("ELSE_IF", {
                hd: ifStatementSimpleToString(elseStatementSimple._0),
                tl: /* [] */0
              });
  } else {
    return Utils$Poly.variantToString("ELSE", {
                hd: blockSimpleToString(elseStatementSimple._0),
                tl: /* [] */0
              });
  }
}

function statementToSimpleString(statement) {
  return statementSimpleToString(statementToSimple(statement));
}

function programToSimple(program) {
  return List.map(statementToSimple, program);
}

function programSimpleToString(programSimple) {
  return Utils$Poly.listToString(List.map(statementSimpleToString, programSimple));
}

function programToSimpleString(program) {
  var programSimple = List.map(statementToSimple, program);
  return Utils$Poly.listToString(List.map(statementSimpleToString, programSimple));
}

exports.tokenToSimple = tokenToSimple;
exports.optionTokenToSimple = optionTokenToSimple;
exports.expressionErrorToSimple = expressionErrorToSimple;
exports.tupleModeToString = tupleModeToString;
exports.expressionErrorSimpleToString = expressionErrorSimpleToString;
exports.expressionErrorToSimpleString = expressionErrorToSimpleString;
exports.textToSimple = textToSimple;
exports.dictionaryItemToSimple = dictionaryItemToSimple;
exports.literalToSimple = literalToSimple;
exports.typeExpressionToSimple = typeExpressionToSimple;
exports.variableDeclarationToSimple = variableDeclarationToSimple;
exports.variableToSimple = variableToSimple;
exports.expressionToSimple = expressionToSimple;
exports.textSimpleToString = textSimpleToString;
exports.dictionaryItemSimpleToString = dictionaryItemSimpleToString;
exports.literalSimpleToString = literalSimpleToString;
exports.variableDeclarationSimpleToString = variableDeclarationSimpleToString;
exports.variableSimpleToString = variableSimpleToString;
exports.typeExpressionSimpleToString = typeExpressionSimpleToString;
exports.expressionSimpleToString = expressionSimpleToString;
exports.binExpressionToString = binExpressionToString;
exports.unaExpressionToString = unaExpressionToString;
exports.expressionToSimpleString = expressionToSimpleString;
exports.literalToSimpleString = literalToSimpleString;
exports.statementErrorToSimple = statementErrorToSimple;
exports.statementErrorSimpleToString = statementErrorSimpleToString;
exports.statementErrorToSimpleString = statementErrorToSimpleString;
exports.statementToSimple = statementToSimple;
exports.blockToSimple = blockToSimple;
exports.ifToSimple = ifToSimple;
exports.elseToSimple = elseToSimple;
exports.statementSimpleToString = statementSimpleToString;
exports.blockSimpleToString = blockSimpleToString;
exports.ifStatementSimpleToString = ifStatementSimpleToString;
exports.elseStatementSimpleToString = elseStatementSimpleToString;
exports.statementToSimpleString = statementToSimpleString;
exports.programToSimple = programToSimple;
exports.programSimpleToString = programSimpleToString;
exports.programToSimpleString = programToSimpleString;
/* No side effect */
