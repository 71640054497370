'use strict';

var List = require("rescript/lib/js/list.js");
var Utils$Poly = require("../utils/Utils.bs.js");
var Position$Poly = require("../position/Position.bs.js");
var ParseError$Poly = require("./ParseError.bs.js");

function atEnd(ctx) {
  return ctx.position >= List.length(ctx.tokens);
}

function getToken(ctx, index) {
  if (index >= List.length(ctx.tokens) || index < 0) {
    return ;
  }
  var t = List.nth(ctx.tokens, index);
  var lexError = t.token;
  if (typeof lexError === "number" || lexError.TAG !== /* ERROR */4) {
    return t;
  } else {
    return ParseError$Poly.pushError(ctx, {
                TAG: /* LEX_ERROR */0,
                _0: lexError._0
              });
  }
}

function getTokenOffset(ctx, offset) {
  return getToken(ctx, ctx.position + offset | 0);
}

function currentToken(ctx) {
  return getTokenOffset(ctx, 0);
}

function nextToken(ctx) {
  return getTokenOffset(ctx, 1);
}

function previousToken(ctx) {
  return getTokenOffset(ctx, -1);
}

function nextTokenOnNewline(ctx) {
  var match = getTokenOffset(ctx, 0);
  var match$1 = getTokenOffset(ctx, 1);
  if (match !== undefined && match$1 !== undefined) {
    return Position$Poly.endPos(match.range).line < Position$Poly.startPos(match$1.range).line;
  } else {
    return match$1 === undefined;
  }
}

function offsetPosition(ctx, offset) {
  return {
          source: ctx.source,
          position: ctx.position + offset | 0,
          tokens: ctx.tokens,
          diagnostics: ctx.diagnostics,
          fixes: ctx.fixes,
          lexErrors: ctx.lexErrors,
          parseTree: ctx.parseTree
        };
}

function incrementPosition(ctx) {
  return offsetPosition(ctx, 1);
}

function decrementPosition(ctx) {
  return offsetPosition(ctx, -1);
}

function currentPosition(ctx) {
  var match = getTokenOffset(ctx, 0);
  var match$1 = Utils$Poly.last(ctx.tokens);
  if (match !== undefined) {
    return Position$Poly.startPos(match.range);
  } else if (match$1 !== undefined) {
    return Position$Poly.endPos(match$1.range);
  } else {
    return Position$Poly.beginningPosition;
  }
}

function currentPreviousTokenEndPosition(ctx) {
  var match = getTokenOffset(ctx, -1);
  var match$1 = Utils$Poly.last(ctx.tokens);
  if (match !== undefined) {
    return Position$Poly.endPos(match.range);
  } else if (match$1 !== undefined) {
    return Position$Poly.endPos(match$1.range);
  } else {
    return Position$Poly.beginningPosition;
  }
}

function currentEndPosition(ctx) {
  var match = getTokenOffset(ctx, 0);
  var match$1 = Utils$Poly.last(ctx.tokens);
  if (match !== undefined) {
    return Position$Poly.endPos(match.range);
  } else if (match$1 !== undefined) {
    return Position$Poly.endPos(match$1.range);
  } else {
    return Position$Poly.beginningPosition;
  }
}

exports.atEnd = atEnd;
exports.getToken = getToken;
exports.getTokenOffset = getTokenOffset;
exports.currentToken = currentToken;
exports.nextToken = nextToken;
exports.previousToken = previousToken;
exports.nextTokenOnNewline = nextTokenOnNewline;
exports.offsetPosition = offsetPosition;
exports.incrementPosition = incrementPosition;
exports.decrementPosition = decrementPosition;
exports.currentPosition = currentPosition;
exports.currentPreviousTokenEndPosition = currentPreviousTokenEndPosition;
exports.currentEndPosition = currentEndPosition;
/* No side effect */
