'use strict';

var Ast$Poly = require("./Ast.bs.js");
var Utils$Poly = require("../utils/Utils.bs.js");
var LexError$Poly = require("../lexer/LexError.bs.js");
var AstSimple$Poly = require("./AstSimple.bs.js");
var Diagnostic$Poly = require("../diagnostic/Diagnostic.bs.js");

function getDiagnosticLevel(parseDiagnostic) {
  return parseDiagnostic[1];
}

function parseErrorToSimple(parseError) {
  switch (parseError.TAG | 0) {
    case /* LEX_ERROR */0 :
        return {
                TAG: /* LEX_ERROR */0,
                _0: LexError$Poly.errorToSimple(parseError._0)
              };
    case /* EXPRESSION_ERROR */1 :
        return {
                TAG: /* EXPRESSION_ERROR */1,
                _0: AstSimple$Poly.expressionErrorToSimple(parseError._0)
              };
    case /* STATEMENT_ERROR */2 :
        return {
                TAG: /* STATEMENT_ERROR */2,
                _0: AstSimple$Poly.statementErrorToSimple(parseError._0)
              };
    
  }
}

function parseErrorToString(parseError) {
  switch (parseError.TAG | 0) {
    case /* LEX_ERROR */0 :
        return Utils$Poly.variantToString("LEX_ERROR", {
                    hd: LexError$Poly.errorToString(parseError._0),
                    tl: /* [] */0
                  });
    case /* EXPRESSION_ERROR */1 :
        return Utils$Poly.variantToString("EXPRESSION_ERROR", {
                    hd: Ast$Poly.expressionErrorToString(parseError._0),
                    tl: /* [] */0
                  });
    case /* STATEMENT_ERROR */2 :
        return Utils$Poly.variantToString("STATEMENT_ERROR", {
                    hd: Ast$Poly.statementErrorToString(parseError._0),
                    tl: /* [] */0
                  });
    
  }
}

function parseErrorSimpleToString(parseErrorSimple) {
  switch (parseErrorSimple.TAG | 0) {
    case /* LEX_ERROR */0 :
        return Utils$Poly.variantToString("LEX_ERROR", {
                    hd: LexError$Poly.errorSimpleToString(parseErrorSimple._0),
                    tl: /* [] */0
                  });
    case /* EXPRESSION_ERROR */1 :
        return Utils$Poly.variantToString("EXPRESSION_ERROR", {
                    hd: AstSimple$Poly.expressionErrorSimpleToString(parseErrorSimple._0),
                    tl: /* [] */0
                  });
    case /* STATEMENT_ERROR */2 :
        return Utils$Poly.variantToString("STATEMENT_ERROR", {
                    hd: AstSimple$Poly.statementErrorSimpleToString(parseErrorSimple._0),
                    tl: /* [] */0
                  });
    
  }
}

function parseErrorToSimpleString(parseError) {
  return parseErrorSimpleToString(parseErrorToSimple(parseError));
}

function parseDiagnosticToString(parseDiagnostic) {
  return Utils$Poly.variantToString(Diagnostic$Poly.diagnosticLevelToString(parseDiagnostic[1]), {
              hd: parseErrorToString(parseDiagnostic[0]),
              tl: /* [] */0
            });
}

exports.getDiagnosticLevel = getDiagnosticLevel;
exports.parseErrorToSimple = parseErrorToSimple;
exports.parseErrorToString = parseErrorToString;
exports.parseErrorSimpleToString = parseErrorSimpleToString;
exports.parseErrorToSimpleString = parseErrorToSimpleString;
exports.parseDiagnosticToString = parseDiagnosticToString;
/* No side effect */
