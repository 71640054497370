'use strict';

var List = require("rescript/lib/js/list.js");
var $$String = require("rescript/lib/js/string.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Utils$Poly = require("../utils/Utils.bs.js");
var Parser$Poly = require("./Parser.bs.js");
var Formatter$Poly = require("../formatter/Formatter.bs.js");
var AstSimplify$Poly = require("./AstSimplify.bs.js");

function group(x) {
  return Formatter$Poly.group(Formatter$Poly.concat(x));
}

function groupAll(x) {
  return Formatter$Poly.groupAll(Formatter$Poly.concat(x));
}

function nest(x) {
  return Formatter$Poly.nest(Formatter$Poly.concat(x));
}

function nestAll(x) {
  return Formatter$Poly.nestAll(Formatter$Poly.concat(x));
}

var trailingComma = Formatter$Poly.fromSplitString("", ",");

function splitSeparate(separator, _end, ds) {
  return Formatter$Poly.concat({
              hd: Formatter$Poly.separate(separator, ds),
              tl: {
                hd: _end,
                tl: /* [] */0
              }
            });
}

function commaSeparate(x) {
  return splitSeparate(Formatter$Poly.concat({
                  hd: Formatter$Poly.fromString(","),
                  tl: {
                    hd: Formatter$Poly.space,
                    tl: /* [] */0
                  }
                }), trailingComma, x);
}

function formatGrouping(left, right, d) {
  return Formatter$Poly.concat({
              hd: Formatter$Poly.fromString(left),
              tl: {
                hd: Formatter$Poly.nestAll(Formatter$Poly.concat({
                          hd: Formatter$Poly.blank,
                          tl: {
                            hd: d,
                            tl: {
                              hd: Formatter$Poly.blank,
                              tl: /* [] */0
                            }
                          }
                        })),
                tl: {
                  hd: Formatter$Poly.fromString(right),
                  tl: /* [] */0
                }
              }
            });
}

function parens(x) {
  return formatGrouping("(", ")", x);
}

function blankGrouping(x) {
  return formatGrouping("", "", x);
}

function brackets(x) {
  return formatGrouping("[", "]", x);
}

function braces(x) {
  return formatGrouping("{", "}", x);
}

function escapeQuote(s) {
  var c = Utils$Poly.firstChar(s);
  if (c === undefined) {
    return s;
  }
  if (c === 34) {
    return "\\\"" + escapeQuote(Utils$Poly.stringTail(s));
  }
  if (c === 92) {
    return "\\\\" + escapeQuote(Utils$Poly.stringTail(s));
  }
  if (c < 14) {
    switch (c) {
      case 8 :
          return "\\b" + escapeQuote(Utils$Poly.stringTail(s));
      case 9 :
          return "\\t" + escapeQuote(Utils$Poly.stringTail(s));
      case 10 :
          return "\\n" + escapeQuote(Utils$Poly.stringTail(s));
      case 0 :
      case 1 :
      case 2 :
      case 3 :
      case 4 :
      case 5 :
      case 6 :
      case 7 :
      case 11 :
      case 12 :
          break;
      case 13 :
          return "\\r" + escapeQuote(Utils$Poly.stringTail(s));
      
    }
  }
  return $$String.make(1, c) + escapeQuote(Utils$Poly.stringTail(s));
}

function escapeTick(s) {
  var c = Utils$Poly.firstChar(s);
  if (c === undefined) {
    return s;
  }
  if (c === 92) {
    return "\\\\" + escapeTick(Utils$Poly.stringTail(s));
  }
  if (c >= 14) {
    if (c === 96) {
      return "\\`" + escapeTick(Utils$Poly.stringTail(s));
    }
    
  } else if (c >= 8) {
    switch (c) {
      case 8 :
          return "\\b" + escapeTick(Utils$Poly.stringTail(s));
      case 9 :
          return "\\t" + escapeTick(Utils$Poly.stringTail(s));
      case 10 :
      case 11 :
      case 12 :
          break;
      case 13 :
          return "\\r" + escapeTick(Utils$Poly.stringTail(s));
      
    }
  }
  return $$String.make(1, c) + escapeTick(Utils$Poly.stringTail(s));
}

function variableDeclarationDocument(v) {
  var variableType = v.variableType;
  var variable = v.variable;
  if (variableType === undefined) {
    return Formatter$Poly.fromString(variable.name);
  }
  var x_0 = Formatter$Poly.concat({
        hd: Formatter$Poly.fromString(variable.name),
        tl: {
          hd: Formatter$Poly.fromString(": "),
          tl: /* [] */0
        }
      });
  var x_1 = {
    hd: typeExpressionDocument(variableType.typeExpression),
    tl: /* [] */0
  };
  var x = {
    hd: x_0,
    tl: x_1
  };
  return Formatter$Poly.nest(Formatter$Poly.concat(x));
}

function inlineExpressionDocument(expression) {
  switch (expression.TAG | 0) {
    case /* INDEX */16 :
        var match = expression.expression;
        if (match.TAG === /* VARIABLE */15) {
          return [
                  match._0.name + "[",
                  Formatter$Poly.concat({
                        hd: Formatter$Poly.blank,
                        tl: {
                          hd: firstExpressionGroupAll(expression.index),
                          tl: {
                            hd: Formatter$Poly.blank,
                            tl: /* [] */0
                          }
                        }
                      }),
                  "]"
                ];
        } else {
          return ;
        }
    case /* CALL */17 :
        var match$1 = expression.expression;
        if (match$1.TAG === /* VARIABLE */15) {
          return [
                  match$1._0.name + "(",
                  Formatter$Poly.concat({
                        hd: Formatter$Poly.blank,
                        tl: {
                          hd: commaSeparate(Pervasives.$at(List.map(firstExpressionGroupAll, expression.unnamedArgs), List.map((function (param) {
                                          var x_0 = Formatter$Poly.fromString(param[0] + ":");
                                          var x_1 = {
                                            hd: Formatter$Poly.space,
                                            tl: {
                                              hd: firstExpressionGroupAll(param[2]),
                                              tl: /* [] */0
                                            }
                                          };
                                          var x = {
                                            hd: x_0,
                                            tl: x_1
                                          };
                                          return Formatter$Poly.nest(Formatter$Poly.concat(x));
                                        }), expression.namedArgs))),
                          tl: {
                            hd: Formatter$Poly.blank,
                            tl: /* [] */0
                          }
                        }
                      }),
                  ")"
                ];
        } else {
          return ;
        }
    case /* LITERAL */18 :
        var match$2 = expression._0;
        switch (match$2.TAG | 0) {
          case /* ARRAY */3 :
              return [
                      "[",
                      Formatter$Poly.concat({
                            hd: Formatter$Poly.blank,
                            tl: {
                              hd: commaSeparate(List.map(firstExpressionGroupAll, match$2._0.elements)),
                              tl: {
                                hd: Formatter$Poly.blank,
                                tl: /* [] */0
                              }
                            }
                          }),
                      "]"
                    ];
          case /* TUPLE */4 :
              var match$3 = match$2._0;
              var unnamedTerms = match$3.unnamedTerms;
              var tupleType = match$3.tupleType;
              if (unnamedTerms && !unnamedTerms.tl && !match$3.namedTerms) {
                return [
                        "(",
                        Formatter$Poly.concat({
                              hd: Formatter$Poly.blank,
                              tl: {
                                hd: splitSeparate(Formatter$Poly.concat({
                                          hd: Formatter$Poly.fromString(","),
                                          tl: {
                                            hd: Formatter$Poly.space,
                                            tl: /* [] */0
                                          }
                                        }), Formatter$Poly.fromString(","), {
                                      hd: firstExpressionGroupAll(unnamedTerms.hd),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Formatter$Poly.blank,
                                  tl: /* [] */0
                                }
                              }
                            }),
                        ")"
                      ];
              }
              return [
                      tupleType === /* TOP_LEVEL */0 ? "" : "(",
                      Formatter$Poly.concat({
                            hd: Formatter$Poly.blank,
                            tl: {
                              hd: commaSeparate(Pervasives.$at(List.map(firstExpressionGroupAll, unnamedTerms), List.map((function (param) {
                                              var x_0 = Formatter$Poly.fromString(param[0] + ":");
                                              var x_1 = {
                                                hd: Formatter$Poly.space,
                                                tl: {
                                                  hd: firstExpressionGroupAll(param[2]),
                                                  tl: /* [] */0
                                                }
                                              };
                                              var x = {
                                                hd: x_0,
                                                tl: x_1
                                              };
                                              return Formatter$Poly.nest(Formatter$Poly.concat(x));
                                            }), match$3.namedTerms))),
                              tl: {
                                hd: Formatter$Poly.blank,
                                tl: /* [] */0
                              }
                            }
                          }),
                      tupleType === /* TOP_LEVEL */0 ? "" : ")"
                    ];
          case /* DICTIONARY */5 :
              return [
                      "{",
                      Formatter$Poly.concat({
                            hd: Formatter$Poly.blank,
                            tl: {
                              hd: commaSeparate(List.map(dictionaryItemDocument, match$2._0.items)),
                              tl: {
                                hd: Formatter$Poly.blank,
                                tl: /* [] */0
                              }
                            }
                          }),
                      "}"
                    ];
          default:
            return ;
        }
        break;
    default:
      return ;
  }
}

function ifDocument(ifStatement) {
  var match = ifStatement.elseStatement;
  var tmp;
  tmp = match !== undefined ? (
      match.TAG === /* ELSE_IF */0 ? ({
            hd: Formatter$Poly.space,
            tl: {
              hd: Formatter$Poly.fromString("else"),
              tl: {
                hd: Formatter$Poly.space,
                tl: {
                  hd: ifDocument(match.ifStatement),
                  tl: /* [] */0
                }
              }
            }
          }) : ({
            hd: Formatter$Poly.space,
            tl: {
              hd: Formatter$Poly.fromString("else"),
              tl: {
                hd: Formatter$Poly.space,
                tl: {
                  hd: blockDocument(match.block),
                  tl: /* [] */0
                }
              }
            }
          })
    ) : /* [] */0;
  return Formatter$Poly.concat(Pervasives.$at({
                  hd: Formatter$Poly.fromString("if"),
                  tl: {
                    hd: Formatter$Poly.space,
                    tl: {
                      hd: _expressionDocument(ifStatement.expression, true, false),
                      tl: {
                        hd: Formatter$Poly.space,
                        tl: {
                          hd: blockDocument(ifStatement.block),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }, tmp));
}

function _expressionDocument(expression, first, surroundedByParens) {
  switch (expression.TAG | 0) {
    case /* GROUPING */0 :
        return formatGrouping("(", ")", _expressionDocument(expression._0.expression, false, true));
    case /* EQUALS */1 :
        return binaryExpressionDocument(expression._0, "==");
    case /* UNEQUALS */2 :
        return binaryExpressionDocument(expression._0, "!=");
    case /* GREATER */3 :
        return binaryExpressionDocument(expression._0, ">");
    case /* LESSER */4 :
        return binaryExpressionDocument(expression._0, "<");
    case /* GREATER_EQUALS */5 :
        return binaryExpressionDocument(expression._0, ">=");
    case /* LESSER_EQUALS */6 :
        return binaryExpressionDocument(expression._0, "<=");
    case /* PLUS */7 :
        return binaryExpressionDocument(expression._0, "+");
    case /* MINUS */8 :
        return binaryExpressionDocument(expression._0, "-");
    case /* DIVIDE */9 :
        return binaryExpressionDocument(expression._0, "/");
    case /* MULTIPLY */10 :
        return binaryExpressionDocument(expression._0, "*");
    case /* POWER */11 :
        return binaryExpressionDocument(expression._0, "**");
    case /* RANGE */12 :
        return rangeExpressionDocument(expression._0);
    case /* NEGATE */13 :
        return negateOp(expression._0, !first, surroundedByParens);
    case /* NOT */14 :
        return Formatter$Poly.concat({
                    hd: Formatter$Poly.fromString("!"),
                    tl: {
                      hd: _expressionDocument(expression._0.operand, false, false),
                      tl: /* [] */0
                    }
                  });
    case /* VARIABLE */15 :
        return Formatter$Poly.fromString(expression._0.name);
    case /* INDEX */16 :
        var x_0 = Formatter$Poly.fromString("[");
        var x_1 = {
          hd: Formatter$Poly.blank,
          tl: {
            hd: firstExpressionGroupAll(expression.index),
            tl: {
              hd: Formatter$Poly.blank,
              tl: /* [] */0
            }
          }
        };
        var x = {
          hd: x_0,
          tl: x_1
        };
        return Formatter$Poly.concat({
                    hd: _expressionDocument(expression.expression, false, false),
                    tl: {
                      hd: Formatter$Poly.nestAll(Formatter$Poly.concat(x)),
                      tl: {
                        hd: Formatter$Poly.fromString("]"),
                        tl: /* [] */0
                      }
                    }
                  });
    case /* CALL */17 :
        var x_0$1 = Formatter$Poly.fromString("(");
        var x_1$1 = {
          hd: Formatter$Poly.blank,
          tl: {
            hd: commaSeparate(Pervasives.$at(List.map(firstExpressionGroupAll, expression.unnamedArgs), List.map((function (param) {
                            var x_0 = Formatter$Poly.fromString(param[0] + ":");
                            var x_1 = {
                              hd: Formatter$Poly.space,
                              tl: {
                                hd: firstExpressionGroupAll(param[2]),
                                tl: /* [] */0
                              }
                            };
                            var x = {
                              hd: x_0,
                              tl: x_1
                            };
                            return Formatter$Poly.nest(Formatter$Poly.concat(x));
                          }), expression.namedArgs))),
            tl: {
              hd: Formatter$Poly.blank,
              tl: /* [] */0
            }
          }
        };
        var x$1 = {
          hd: x_0$1,
          tl: x_1$1
        };
        return Formatter$Poly.concat({
                    hd: _expressionDocument(expression.expression, false, false),
                    tl: {
                      hd: Formatter$Poly.nestAll(Formatter$Poly.concat(x$1)),
                      tl: {
                        hd: Formatter$Poly.fromString(")"),
                        tl: /* [] */0
                      }
                    }
                  });
    case /* LITERAL */18 :
        var match = expression._0;
        switch (match.TAG | 0) {
          case /* NUMBER */0 :
              return Formatter$Poly.fromString(Utils$Poly.floatToStringFormat(match.number));
          case /* TEXT */1 :
              var token = match.startQuoteToken.token;
              var quoteText = token === /* TICK */7 ? "`" : "\"";
              return Formatter$Poly.concat(Pervasives.$at({
                              hd: Formatter$Poly.fromString(quoteText),
                              tl: /* [] */0
                            }, Pervasives.$at(List.map((function (x) {
                                        return textExpressionDocument(x, token);
                                      }), match.text), {
                                  hd: Formatter$Poly.fromString(quoteText),
                                  tl: /* [] */0
                                })));
          case /* DICTIONARY_ITEM_TEXT */2 :
              return Formatter$Poly.fromString(match.contents);
          case /* ARRAY */3 :
              return formatGrouping("[", "]", commaSeparate(List.map(firstExpressionGroupAll, match._0.elements)));
          case /* TUPLE */4 :
              var match$1 = match._0;
              var unnamedTerms = match$1.unnamedTerms;
              if (unnamedTerms && !unnamedTerms.tl && !match$1.namedTerms) {
                return formatGrouping("(", ")", Formatter$Poly.concat({
                                hd: firstExpressionGroupAll(unnamedTerms.hd),
                                tl: {
                                  hd: Formatter$Poly.fromString(","),
                                  tl: /* [] */0
                                }
                              }));
              }
              return (
                        match$1.tupleType === /* TOP_LEVEL */0 ? blankGrouping : parens
                      )(commaSeparate(Pervasives.$at(List.map(firstExpressionGroupAll, unnamedTerms), List.map((function (param) {
                                        var x_0 = Formatter$Poly.fromString(param[0] + ":");
                                        var x_1 = {
                                          hd: Formatter$Poly.space,
                                          tl: {
                                            hd: firstExpressionGroupAll(param[2]),
                                            tl: /* [] */0
                                          }
                                        };
                                        var x = {
                                          hd: x_0,
                                          tl: x_1
                                        };
                                        return Formatter$Poly.nest(Formatter$Poly.concat(x));
                                      }), match$1.namedTerms))));
          case /* DICTIONARY */5 :
              return formatGrouping("{", "}", commaSeparate(List.map(dictionaryItemDocument, match._0.items)));
          
        }
        break;
    case /* EXPRESSION_ERROR */19 :
        return /* Empty */0;
    
  }
}

function blockDocument(block) {
  return Formatter$Poly.concat({
              hd: Formatter$Poly.fromString("{"),
              tl: {
                hd: Formatter$Poly.newline,
                tl: {
                  hd: Formatter$Poly.indent(programDocument(block.statements)),
                  tl: {
                    hd: Formatter$Poly.newline,
                    tl: {
                      hd: Formatter$Poly.fromString("}"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function textExpressionDocument(text, quoteToken) {
  if (text.TAG !== /* CONTENTS */0) {
    return Formatter$Poly.concat({
                hd: Formatter$Poly.fromString("\\["),
                tl: {
                  hd: Formatter$Poly.blank,
                  tl: {
                    hd: Formatter$Poly.indent(firstExpressionGroupAll(text.expression)),
                    tl: {
                      hd: Formatter$Poly.blank,
                      tl: {
                        hd: Formatter$Poly.fromString("]"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              });
  }
  var text$1 = text.text;
  if (quoteToken === 7) {
    return Formatter$Poly.fromString(escapeTick(text$1));
  } else {
    return Formatter$Poly.fromString(escapeQuote(text$1));
  }
}

function dictionaryItemDocument(item) {
  if (item.TAG !== /* REGULAR */0) {
    return Formatter$Poly.fromString(item.key.name);
  }
  var value = item.value;
  var key = item.key;
  var match = inlineExpressionDocument(value);
  if (match !== undefined) {
    var x_0 = _expressionDocument(key, false, false);
    var x_1 = {
      hd: Formatter$Poly.fromString(": " + match[0]),
      tl: /* [] */0
    };
    var x = {
      hd: x_0,
      tl: x_1
    };
    var x_0$1 = Formatter$Poly.group(Formatter$Poly.concat(x));
    var x_1$1 = {
      hd: match[1],
      tl: /* [] */0
    };
    var x$1 = {
      hd: x_0$1,
      tl: x_1$1
    };
    return Formatter$Poly.concat({
                hd: Formatter$Poly.nestAll(Formatter$Poly.concat(x$1)),
                tl: {
                  hd: Formatter$Poly.fromString(match[2]),
                  tl: /* [] */0
                }
              });
  }
  var x_0$2 = Formatter$Poly.concat({
        hd: _expressionDocument(key, false, false),
        tl: {
          hd: Formatter$Poly.fromString(":"),
          tl: /* [] */0
        }
      });
  var x_1$2 = {
    hd: Formatter$Poly.space,
    tl: {
      hd: firstExpressionGroupAll(value),
      tl: /* [] */0
    }
  };
  var x$2 = {
    hd: x_0$2,
    tl: x_1$2
  };
  return Formatter$Poly.nest(Formatter$Poly.concat(x$2));
}

function firstExpressionGroupAll(expression) {
  var x_0 = _expressionDocument(expression, true, false);
  var x = {
    hd: x_0,
    tl: /* [] */0
  };
  return Formatter$Poly.groupAll(Formatter$Poly.concat(x));
}

function rangeExpressionDocument(rangeOp) {
  var start = rangeOp.startExpression;
  if (start !== undefined) {
    return Formatter$Poly.concat({
                hd: _expressionDocument(start, false, false),
                tl: {
                  hd: Formatter$Poly.fromString(".."),
                  tl: {
                    hd: _expressionDocument(rangeOp.endExpression, false, false),
                    tl: /* [] */0
                  }
                }
              });
  } else {
    return Formatter$Poly.concat({
                hd: Formatter$Poly.fromString(".."),
                tl: {
                  hd: _expressionDocument(rangeOp.endExpression, false, false),
                  tl: /* [] */0
                }
              });
  }
}

function binaryExpressionDocument(binOp, operator) {
  var match = binOp.right;
  if (match.TAG !== /* GROUPING */0) {
    return Formatter$Poly.concat({
                hd: _expressionDocument(binOp.left, false, false),
                tl: {
                  hd: Formatter$Poly.space,
                  tl: {
                    hd: Formatter$Poly.fromString(operator + " "),
                    tl: {
                      hd: _expressionDocument(binOp.right, false, false),
                      tl: /* [] */0
                    }
                  }
                }
              });
  }
  var x_0 = Formatter$Poly.fromString(operator);
  var x_1 = {
    hd: Formatter$Poly.space,
    tl: {
      hd: Formatter$Poly.fromString("("),
      tl: /* [] */0
    }
  };
  var x = {
    hd: x_0,
    tl: x_1
  };
  var x_0$1 = Formatter$Poly.group(Formatter$Poly.concat(x));
  var x_1$1 = {
    hd: Formatter$Poly.blank,
    tl: {
      hd: _expressionDocument(match._0.expression, false, true),
      tl: {
        hd: Formatter$Poly.blank,
        tl: /* [] */0
      }
    }
  };
  var x$1 = {
    hd: x_0$1,
    tl: x_1$1
  };
  return Formatter$Poly.concat({
              hd: _expressionDocument(binOp.left, false, false),
              tl: {
                hd: Formatter$Poly.space,
                tl: {
                  hd: Formatter$Poly.nestAll(Formatter$Poly.concat(x$1)),
                  tl: {
                    hd: Formatter$Poly.fromString(")"),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function negateOp(unOp, parenWrap, surroundedByParens) {
  var match = unOp.operand;
  if (match.TAG !== /* GROUPING */0) {
    if (surroundedByParens) {
      return Formatter$Poly.concat({
                  hd: Formatter$Poly.fromString("-"),
                  tl: {
                    hd: _expressionDocument(unOp.operand, false, false),
                    tl: /* [] */0
                  }
                });
    } else {
      return Formatter$Poly.concat({
                  hd: Formatter$Poly.fromString("("),
                  tl: {
                    hd: Formatter$Poly.fromString("-"),
                    tl: {
                      hd: _expressionDocument(unOp.operand, false, true),
                      tl: {
                        hd: Formatter$Poly.fromString(")"),
                        tl: /* [] */0
                      }
                    }
                  }
                });
    }
  }
  var l = match._0.expression;
  if (l.TAG === /* LITERAL */18 && l._0.TAG === /* NUMBER */0) {
    return Formatter$Poly.concat({
                hd: Formatter$Poly.fromString("(-"),
                tl: {
                  hd: _expressionDocument(l, false, false),
                  tl: {
                    hd: Formatter$Poly.fromString(")"),
                    tl: /* [] */0
                  }
                }
              });
  }
  if (parenWrap) {
    return Formatter$Poly.concat({
                hd: Formatter$Poly.fromString("(-"),
                tl: {
                  hd: Formatter$Poly.space,
                  tl: {
                    hd: _expressionDocument(unOp.operand, false, false),
                    tl: {
                      hd: Formatter$Poly.fromString(")"),
                      tl: /* [] */0
                    }
                  }
                }
              });
  } else {
    return Formatter$Poly.concat({
                hd: Formatter$Poly.fromString("-"),
                tl: {
                  hd: Formatter$Poly.space,
                  tl: {
                    hd: _expressionDocument(unOp.operand, false, false),
                    tl: /* [] */0
                  }
                }
              });
  }
}

function programDocument(program) {
  return Formatter$Poly.separate(Formatter$Poly.newline, List.map(statementDocument, program));
}

function typeExpressionDocument(typeExpression) {
  switch (typeExpression.TAG | 0) {
    case /* TYPE */0 :
        return Formatter$Poly.fromString(typeExpression.name);
    case /* ARRAY_TYPE */1 :
        return formatGrouping("[", "]", typeExpressionDocument(typeExpression.typeExpression));
    case /* TUPLE_TYPE */2 :
        return formatGrouping("(", ")", commaSeparate(List.map(typeExpressionDocument, typeExpression.types)));
    case /* NAMED_TUPLE_TYPE */3 :
        return formatGrouping("(", ")", commaSeparate(Pervasives.$at(List.map((function (param) {
                                  var x_0 = Formatter$Poly.fromString(param[0] + ":");
                                  var x_1 = {
                                    hd: Formatter$Poly.space,
                                    tl: {
                                      hd: typeExpressionDocument(param[2]),
                                      tl: /* [] */0
                                    }
                                  };
                                  var x = {
                                    hd: x_0,
                                    tl: x_1
                                  };
                                  return Formatter$Poly.nest(Formatter$Poly.concat(x));
                                }), typeExpression.namedTypes), List.map((function (param) {
                                  var x_0 = Formatter$Poly.fromString(param[0] + ":");
                                  var x_1 = {
                                    hd: Formatter$Poly.space,
                                    tl: {
                                      hd: typeExpressionDocument(param[2]),
                                      tl: {
                                        hd: Formatter$Poly.space,
                                        tl: {
                                          hd: Formatter$Poly.fromString("="),
                                          tl: {
                                            hd: Formatter$Poly.space,
                                            tl: {
                                              hd: _expressionDocument(param[4], false, false),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  };
                                  var x = {
                                    hd: x_0,
                                    tl: x_1
                                  };
                                  return Formatter$Poly.nest(Formatter$Poly.concat(x));
                                }), typeExpression.defaultTypes))));
    case /* DICTIONARY_TYPE */4 :
        var x_0 = Formatter$Poly.concat({
              hd: typeExpressionDocument(typeExpression.keyType),
              tl: {
                hd: Formatter$Poly.fromString(":"),
                tl: /* [] */0
              }
            });
        var x_1 = {
          hd: Formatter$Poly.space,
          tl: {
            hd: typeExpressionDocument(typeExpression.valueType),
            tl: /* [] */0
          }
        };
        var x = {
          hd: x_0,
          tl: x_1
        };
        return formatGrouping("{", "}", Formatter$Poly.nest(Formatter$Poly.concat(x)));
    
  }
}

function statementDocument(statement) {
  if (typeof statement === "number") {
    return /* Empty */0;
  }
  switch (statement.TAG | 0) {
    case /* DECLARATION */0 :
        var expression = statement.expression;
        var variableDeclaration = statement.variableDeclaration;
        var match = inlineExpressionDocument(expression);
        if (match !== undefined) {
          var x_0 = Formatter$Poly.fromString("let ");
          var x_1 = {
            hd: variableDeclarationDocument(variableDeclaration),
            tl: {
              hd: Formatter$Poly.fromString(" = " + match[0]),
              tl: /* [] */0
            }
          };
          var x = {
            hd: x_0,
            tl: x_1
          };
          var x_0$1 = Formatter$Poly.group(Formatter$Poly.concat(x));
          var x_1$1 = {
            hd: match[1],
            tl: /* [] */0
          };
          var x$1 = {
            hd: x_0$1,
            tl: x_1$1
          };
          return Formatter$Poly.concat({
                      hd: Formatter$Poly.nestAll(Formatter$Poly.concat(x$1)),
                      tl: {
                        hd: Formatter$Poly.fromString(match[2] + ";"),
                        tl: /* [] */0
                      }
                    });
        }
        var x_0$2 = Formatter$Poly.fromString("let ");
        var x_1$2 = {
          hd: variableDeclarationDocument(variableDeclaration),
          tl: {
            hd: Formatter$Poly.space,
            tl: {
              hd: Formatter$Poly.fromString("="),
              tl: /* [] */0
            }
          }
        };
        var x$2 = {
          hd: x_0$2,
          tl: x_1$2
        };
        var x_0$3 = _expressionDocument(expression, true, false);
        var x_1$3 = {
          hd: Formatter$Poly.fromString(";"),
          tl: /* [] */0
        };
        var x$3 = {
          hd: x_0$3,
          tl: x_1$3
        };
        var x_0$4 = Formatter$Poly.group(Formatter$Poly.concat(x$2));
        var x_1$4 = {
          hd: Formatter$Poly.space,
          tl: {
            hd: Formatter$Poly.groupAll(Formatter$Poly.concat(x$3)),
            tl: /* [] */0
          }
        };
        var x$4 = {
          hd: x_0$4,
          tl: x_1$4
        };
        return Formatter$Poly.nestAll(Formatter$Poly.concat(x$4));
    case /* REASSIGN */1 :
        var expression$1 = statement.expression;
        var variable = statement.variable;
        var match$1 = inlineExpressionDocument(expression$1);
        if (match$1 !== undefined) {
          var x_0$5 = Formatter$Poly.fromString(variable.name);
          var x_1$5 = {
            hd: Formatter$Poly.fromString(" = " + match$1[0]),
            tl: /* [] */0
          };
          var x$5 = {
            hd: x_0$5,
            tl: x_1$5
          };
          var x_0$6 = Formatter$Poly.group(Formatter$Poly.concat(x$5));
          var x_1$6 = {
            hd: match$1[1],
            tl: /* [] */0
          };
          var x$6 = {
            hd: x_0$6,
            tl: x_1$6
          };
          return Formatter$Poly.concat({
                      hd: Formatter$Poly.nestAll(Formatter$Poly.concat(x$6)),
                      tl: {
                        hd: Formatter$Poly.fromString(match$1[2] + ";"),
                        tl: /* [] */0
                      }
                    });
        }
        var x_0$7 = Formatter$Poly.fromString(variable.name);
        var x_1$7 = {
          hd: Formatter$Poly.space,
          tl: {
            hd: Formatter$Poly.fromString("="),
            tl: /* [] */0
          }
        };
        var x$7 = {
          hd: x_0$7,
          tl: x_1$7
        };
        var x_0$8 = _expressionDocument(expression$1, true, false);
        var x_1$8 = {
          hd: Formatter$Poly.fromString(";"),
          tl: /* [] */0
        };
        var x$8 = {
          hd: x_0$8,
          tl: x_1$8
        };
        var x_0$9 = Formatter$Poly.group(Formatter$Poly.concat(x$7));
        var x_1$9 = {
          hd: Formatter$Poly.space,
          tl: {
            hd: Formatter$Poly.groupAll(Formatter$Poly.concat(x$8)),
            tl: /* [] */0
          }
        };
        var x$9 = {
          hd: x_0$9,
          tl: x_1$9
        };
        return Formatter$Poly.nestAll(Formatter$Poly.concat(x$9));
    case /* EXPRESSION */2 :
        var x_0$10 = _expressionDocument(statement.expression, true, false);
        var x_1$10 = {
          hd: Formatter$Poly.fromString(";"),
          tl: /* [] */0
        };
        var x$10 = {
          hd: x_0$10,
          tl: x_1$10
        };
        return Formatter$Poly.nestAll(Formatter$Poly.concat(x$10));
    case /* IF */3 :
        return ifDocument(statement._0);
    case /* FOR_IN */4 :
        return Formatter$Poly.concat({
                    hd: Formatter$Poly.fromString("for"),
                    tl: {
                      hd: Formatter$Poly.space,
                      tl: {
                        hd: variableDeclarationDocument(statement.identifier),
                        tl: {
                          hd: Formatter$Poly.space,
                          tl: {
                            hd: Formatter$Poly.fromString("in"),
                            tl: {
                              hd: Formatter$Poly.space,
                              tl: {
                                hd: _expressionDocument(statement.expression, true, false),
                                tl: {
                                  hd: Formatter$Poly.space,
                                  tl: {
                                    hd: blockDocument(statement.block),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  });
    case /* BLOCK */5 :
        return blockDocument(statement._0);
    
  }
}

function firstExpressionDocument(expression) {
  return _expressionDocument(expression, true, false);
}

function variableDocument(v) {
  return Formatter$Poly.fromString(v.name);
}

function expressionDocumentInParens(expression) {
  return _expressionDocument(expression, false, true);
}

function expressionDocument(expression) {
  return _expressionDocument(expression, false, false);
}

function firstExpressionGroup(expression) {
  var x_0 = _expressionDocument(expression, true, false);
  var x = {
    hd: x_0,
    tl: /* [] */0
  };
  return Formatter$Poly.group(Formatter$Poly.concat(x));
}

function format(program, width) {
  var d = programDocument(program);
  var atom = Formatter$Poly.render(width, 2, Formatter$Poly.toAtoms(d));
  return Formatter$Poly.toString(atom, 2);
}

function formatFromString(s, width) {
  var match = Parser$Poly.parseSimpleProgram(s);
  var program = AstSimplify$Poly.programSimplify(match[1]);
  return format(program, width);
}

function atomsFromString(s) {
  var match = Parser$Poly.parseSimpleProgram(s);
  var program = AstSimplify$Poly.programSimplify(match[1]);
  var d = programDocument(program);
  var atoms = Formatter$Poly.toAtoms(d);
  return Utils$Poly.listToString(List.map(Formatter$Poly.atomToString, atoms));
}

function formattedAtomsFromString(s, width) {
  var match = Parser$Poly.parseSimpleProgram(s);
  var program = AstSimplify$Poly.programSimplify(match[1]);
  var d = programDocument(program);
  return Formatter$Poly.atomToString(Formatter$Poly.render(width, 2, Formatter$Poly.toAtoms(d)));
}

var s = Formatter$Poly.fromString;

var ss = Formatter$Poly.fromSplitString;

var concat = Formatter$Poly.concat;

var space = Formatter$Poly.space;

var empty = /* Empty */0;

var blank = Formatter$Poly.blank;

var newline = Formatter$Poly.newline;

var indent = Formatter$Poly.indent;

var separate = Formatter$Poly.separate;

var tab = 2;

exports.s = s;
exports.ss = ss;
exports.concat = concat;
exports.space = space;
exports.empty = empty;
exports.blank = blank;
exports.newline = newline;
exports.indent = indent;
exports.separate = separate;
exports.group = group;
exports.groupAll = groupAll;
exports.nest = nest;
exports.nestAll = nestAll;
exports.trailingComma = trailingComma;
exports.splitSeparate = splitSeparate;
exports.commaSeparate = commaSeparate;
exports.formatGrouping = formatGrouping;
exports.parens = parens;
exports.blankGrouping = blankGrouping;
exports.brackets = brackets;
exports.braces = braces;
exports.escapeQuote = escapeQuote;
exports.escapeTick = escapeTick;
exports.firstExpressionDocument = firstExpressionDocument;
exports.firstExpressionGroup = firstExpressionGroup;
exports.firstExpressionGroupAll = firstExpressionGroupAll;
exports.expressionDocument = expressionDocument;
exports.expressionDocumentInParens = expressionDocumentInParens;
exports._expressionDocument = _expressionDocument;
exports.textExpressionDocument = textExpressionDocument;
exports.inlineExpressionDocument = inlineExpressionDocument;
exports.dictionaryItemDocument = dictionaryItemDocument;
exports.binaryExpressionDocument = binaryExpressionDocument;
exports.rangeExpressionDocument = rangeExpressionDocument;
exports.negateOp = negateOp;
exports.variableDeclarationDocument = variableDeclarationDocument;
exports.variableDocument = variableDocument;
exports.typeExpressionDocument = typeExpressionDocument;
exports.blockDocument = blockDocument;
exports.ifDocument = ifDocument;
exports.statementDocument = statementDocument;
exports.programDocument = programDocument;
exports.tab = tab;
exports.format = format;
exports.formatFromString = formatFromString;
exports.atomsFromString = atomsFromString;
exports.formattedAtomsFromString = formattedAtomsFromString;
/* trailingComma Not a pure module */
