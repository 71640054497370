'use strict';

var List = require("rescript/lib/js/list.js");
var Lex$Poly = require("../lexer/Lex.bs.js");
var Utils$Poly = require("../utils/Utils.bs.js");
var Position$Poly = require("../position/Position.bs.js");

function tupleModeToString(t) {
  if (t) {
    return "CALL";
  } else {
    return "TUPLE";
  }
}

function expressionErrorToString(e) {
  switch (e.TAG | 0) {
    case /* UNEXPECTED_PRIMARY_TOKEN */0 :
        return Utils$Poly.variantDictToString("UNEXPECTED_PRIMARY_TOKEN", {
                    hd: [
                      "errorToken",
                      Utils$Poly.optionToString(e.errorToken, Lex$Poly.tokenToString)
                    ],
                    tl: /* [] */0
                  });
    case /* EXPECTING_LOWERCASE_VARIABLE */1 :
        return Utils$Poly.variantDictToString("EXPECTING_LOWERCASE_VARIABLE", {
                    hd: [
                      "errorToken",
                      Lex$Poly.tokenToString(e.errorToken)
                    ],
                    tl: /* [] */0
                  });
    case /* UNEXPECTED_ARRAY_TOKEN */2 :
        return Utils$Poly.variantDictToString("UNEXPECTED_ARRAY_TOKEN", {
                    hd: [
                      "errorToken",
                      Lex$Poly.optionTokenToString(e.errorToken)
                    ],
                    tl: {
                      hd: [
                        "lbracket",
                        Lex$Poly.tokenToString(e.lbracket)
                      ],
                      tl: {
                        hd: [
                          "commas",
                          Lex$Poly.tokenListToString(e.commas)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* EXPECTING_INDEX_RBRACKET */3 :
        return Utils$Poly.variantDictToString("EXPECTING_INDEX_RBRACKET", {
                    hd: [
                      "lbracket",
                      Lex$Poly.tokenToString(e.lbracket)
                    ],
                    tl: {
                      hd: [
                        "errorToken",
                        Lex$Poly.optionTokenToString(e.errorToken)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* EXPECTING_INTERP_END */4 :
        return Utils$Poly.variantDictToString("EXPECTING_INTERP_END", {
                    hd: [
                      "interpStartToken",
                      Lex$Poly.tokenToString(e.interpStartToken)
                    ],
                    tl: {
                      hd: [
                        "errorToken",
                        Lex$Poly.optionTokenToString(e.errorToken)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* UNEXPECTED_TEXT_TOKEN */5 :
        return Utils$Poly.variantDictToString("UNEXPECTED_TEXT_TOKEN", {
                    hd: [
                      "startQuoteToken",
                      Lex$Poly.tokenToString(e.startQuoteToken)
                    ],
                    tl: {
                      hd: [
                        "errorToken",
                        Lex$Poly.optionTokenToString(e.errorToken)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* UNEXPECTED_GROUPING_TOKEN */6 :
        return Utils$Poly.variantDictToString("UNEXPECTED_GROUPING_TOKEN", {
                    hd: [
                      "errorToken",
                      Lex$Poly.optionTokenToString(e.errorToken)
                    ],
                    tl: {
                      hd: [
                        "lparen",
                        Lex$Poly.tokenToString(e.lparen)
                      ],
                      tl: {
                        hd: [
                          "commas",
                          Lex$Poly.tokenListToString(e.commas)
                        ],
                        tl: {
                          hd: [
                            "namedTerms",
                            String(e.namedTerms)
                          ],
                          tl: {
                            hd: [
                              "unnamedTerms",
                              String(e.unnamedTerms)
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  });
    case /* TUPLE_UNNAMED_AFTER_NAMED */7 :
        return Utils$Poly.variantDictToString("UNEXPECTED_GROUPING_TOKEN", {
                    hd: [
                      "range",
                      Position$Poly.rangeToString(e.range)
                    ],
                    tl: {
                      hd: [
                        "lparen",
                        Lex$Poly.tokenToString(e.lparen)
                      ],
                      tl: {
                        hd: [
                          "commas",
                          Lex$Poly.tokenListToString(e.commas)
                        ],
                        tl: {
                          hd: [
                            "namedTerms",
                            String(e.namedTerms)
                          ],
                          tl: {
                            hd: [
                              "mode",
                              e.mode ? "CALL" : "TUPLE"
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  });
    case /* UNEXPECTED_DICTIONARY_TOKEN */8 :
        return Utils$Poly.variantDictToString("UNEXPECTED_DICTIONARY_TOKEN", {
                    hd: [
                      "errorToken",
                      Lex$Poly.optionTokenToString(e.errorToken)
                    ],
                    tl: {
                      hd: [
                        "leftBrace",
                        Lex$Poly.tokenToString(e.leftBrace)
                      ],
                      tl: {
                        hd: [
                          "commas",
                          Lex$Poly.tokenListToString(e.commas)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* DICTIONARY_NONVARIABLE_SHORTFORM */9 :
        return Utils$Poly.variantDictToString("DICTIONARY_NONVARIABLE_SHORTFORM", {
                    hd: [
                      "range",
                      Position$Poly.rangeToString(e.range)
                    ],
                    tl: {
                      hd: [
                        "lbraceToken",
                        Lex$Poly.tokenToString(e.lbraceToken)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* EXPECTING_UPPERCASE_TYPE */10 :
        return Utils$Poly.variantDictToString("EXPECTING_UPPERCASE_TYPE", {
                    hd: [
                      "errorToken",
                      Lex$Poly.tokenToString(e.errorToken)
                    ],
                    tl: /* [] */0
                  });
    case /* UNEXPECTED_TYPE_TOKEN */11 :
        return Utils$Poly.variantDictToString("UNEXPECTED_TYPE_TOKEN", {
                    hd: [
                      "errorToken",
                      Utils$Poly.optionToString(e.errorToken, Lex$Poly.tokenToString)
                    ],
                    tl: /* [] */0
                  });
    case /* UNEXPECTED_COMMA_IN_ARRAY_TYPE */12 :
        return Utils$Poly.variantDictToString("UNEXPECTED_COMMA_IN_ARRAY_TYPE", {
                    hd: [
                      "lbracketToken",
                      Lex$Poly.tokenToString(e.lbracketToken)
                    ],
                    tl: {
                      hd: [
                        "commaToken",
                        Lex$Poly.tokenToString(e.commaToken)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* EXPECTING_ARRAY_TYPE_RBRACKET */13 :
        return Utils$Poly.variantDictToString("EXPECTING_ARRAY_TYPE_RBRACKET", {
                    hd: [
                      "lbracketToken",
                      Lex$Poly.tokenToString(e.lbracketToken)
                    ],
                    tl: {
                      hd: [
                        "errorToken",
                        Lex$Poly.optionTokenToString(e.errorToken)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* EXPECTING_DICTIONARY_TYPE_RBRACE */14 :
    case /* EXPECTING_DICTIONARY_TYPE_COLON */15 :
        return Utils$Poly.variantDictToString("EXPECTING_DICTIONARY_TYPE_RBRACE", {
                    hd: [
                      "lbraceToken",
                      Lex$Poly.tokenToString(e.lbraceToken)
                    ],
                    tl: {
                      hd: [
                        "errorToken",
                        Lex$Poly.optionTokenToString(e.errorToken)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* UNEXPECTED_TUPLE_TYPE_TOKEN */16 :
        return Utils$Poly.variantDictToString("UNEXPECTED_TUPLE_TYPE_TOKEN", {
                    hd: [
                      "errorToken",
                      Lex$Poly.optionTokenToString(e.errorToken)
                    ],
                    tl: {
                      hd: [
                        "lparen",
                        Lex$Poly.tokenToString(e.lparen)
                      ],
                      tl: {
                        hd: [
                          "commas",
                          Lex$Poly.tokenListToString(e.commas)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* EXPECTING_LOWERCASE_TUPLE_NAMED_PARAMETER */17 :
        return Utils$Poly.variantDictToString("EXPECTING_LOWERCASE_TUPLE_NAMED_PARAMETER", {
                    hd: [
                      "errorToken",
                      Lex$Poly.tokenToString(e.errorToken)
                    ],
                    tl: /* [] */0
                  });
    case /* TUPLE_TYPE_UNNAMED_AFTER_NAMED */18 :
        return Utils$Poly.variantDictToString("TUPLE_TYPE_UNNAMED_AFTER_NAMED", {
                    hd: [
                      "range",
                      Position$Poly.rangeToString(e.range)
                    ],
                    tl: {
                      hd: [
                        "lparenToken",
                        Lex$Poly.tokenToString(e.lparenToken)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* TUPLE_TYPE_NAMED_AFTER_UNNAMED */19 :
        return Utils$Poly.variantDictToString("TUPLE_TYPE_NAMED_AFTER_UNNAMED", {
                    hd: [
                      "range",
                      Position$Poly.rangeToString(e.range)
                    ],
                    tl: {
                      hd: [
                        "lparenToken",
                        Lex$Poly.tokenToString(e.lparenToken)
                      ],
                      tl: {
                        hd: [
                          "colonToken",
                          Lex$Poly.tokenToString(e.colonToken)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* TUPLE_TYPE_NAMED_AFTER_DEFAULT */20 :
        return Utils$Poly.variantDictToString("TUPLE_TYPE_NAMED_AFTER_DEFAULT", {
                    hd: [
                      "range",
                      Position$Poly.rangeToString(e.range)
                    ],
                    tl: {
                      hd: [
                        "lparenToken",
                        Lex$Poly.tokenToString(e.lparenToken)
                      ],
                      tl: {
                        hd: [
                          "colonToken",
                          Lex$Poly.tokenToString(e.colonToken)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* SHOULD_NOT_HAPPEN */21 :
        return Utils$Poly.variantDictToString("SHOULD_NOT_HAPPEN", {
                    hd: [
                      "tokens",
                      Utils$Poly.join(List.map(Lex$Poly.tokenToString, e.tokens))
                    ],
                    tl: /* [] */0
                  });
    
  }
}

function textToString(t) {
  if (t.TAG === /* CONTENTS */0) {
    return Utils$Poly.variantDictToString("CONTENTS", {
                hd: [
                  "text",
                  Utils$Poly.$$escape(t.text)
                ],
                tl: {
                  hd: [
                    "textToken",
                    Lex$Poly.tokenToString(t.textToken)
                  ],
                  tl: /* [] */0
                }
              });
  } else {
    return Utils$Poly.variantDictToString("INTERPOLATE", {
                hd: [
                  "interpStartToken",
                  Lex$Poly.tokenToString(t.interpStartToken)
                ],
                tl: {
                  hd: [
                    "interpEndToken",
                    Lex$Poly.tokenToString(t.interpEndToken)
                  ],
                  tl: {
                    hd: [
                      "expression",
                      expressionToString(t.expression)
                    ],
                    tl: /* [] */0
                  }
                }
              });
  }
}

function variableToString(variable) {
  return Utils$Poly.dictToString({
              hd: [
                "name",
                Utils$Poly.$$escape(variable.name)
              ],
              tl: {
                hd: [
                  "variableToken",
                  Lex$Poly.tokenToString(variable.variableToken)
                ],
                tl: /* [] */0
              }
            });
}

function tupleTypeToString(tupleType) {
  if (tupleType) {
    return Utils$Poly.variantDictToString("PAREN_ENCLOSED", {
                hd: [
                  "lparen",
                  Lex$Poly.tokenToString(tupleType.lparen)
                ],
                tl: {
                  hd: [
                    "rparen",
                    Lex$Poly.tokenToString(tupleType.rparen)
                  ],
                  tl: /* [] */0
                }
              });
  } else {
    return "TOP_LEVEL";
  }
}

function expressionToString(expression) {
  switch (expression.TAG | 0) {
    case /* GROUPING */0 :
        var match = expression._0;
        return Utils$Poly.variantDictToString("GROUPING", {
                    hd: [
                      "expression",
                      expressionToString(match.expression)
                    ],
                    tl: {
                      hd: [
                        "lparen",
                        Lex$Poly.tokenToString(match.lparen)
                      ],
                      tl: {
                        hd: [
                          "rparen",
                          Lex$Poly.tokenToString(match.rparen)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* EQUALS */1 :
        var match$1 = expression._0;
        return binExpressionToString("EQUALS", match$1.left, match$1.right, match$1.binaryOperator);
    case /* UNEQUALS */2 :
        var match$2 = expression._0;
        return binExpressionToString("UNEQUALS", match$2.left, match$2.right, match$2.binaryOperator);
    case /* GREATER */3 :
        var match$3 = expression._0;
        return binExpressionToString("GREATER", match$3.left, match$3.right, match$3.binaryOperator);
    case /* LESSER */4 :
        var match$4 = expression._0;
        return binExpressionToString("LESSER", match$4.left, match$4.right, match$4.binaryOperator);
    case /* GREATER_EQUALS */5 :
        var match$5 = expression._0;
        return binExpressionToString("GREATER_EQUALS", match$5.left, match$5.right, match$5.binaryOperator);
    case /* LESSER_EQUALS */6 :
        var match$6 = expression._0;
        return binExpressionToString("LESSER_EQUALS", match$6.left, match$6.right, match$6.binaryOperator);
    case /* PLUS */7 :
        var match$7 = expression._0;
        return binExpressionToString("PLUS", match$7.left, match$7.right, match$7.binaryOperator);
    case /* MINUS */8 :
        var match$8 = expression._0;
        return binExpressionToString("MINUS", match$8.left, match$8.right, match$8.binaryOperator);
    case /* DIVIDE */9 :
        var match$9 = expression._0;
        return binExpressionToString("DIVIDE", match$9.left, match$9.right, match$9.binaryOperator);
    case /* MULTIPLY */10 :
        var match$10 = expression._0;
        return binExpressionToString("MULTIPLY", match$10.left, match$10.right, match$10.binaryOperator);
    case /* POWER */11 :
        var match$11 = expression._0;
        return binExpressionToString("POWER", match$11.left, match$11.right, match$11.binaryOperator);
    case /* RANGE */12 :
        var match$12 = expression._0;
        return Utils$Poly.variantDictToString("RANGE", {
                    hd: [
                      "startExpression",
                      Utils$Poly.optionToString(match$12.startExpression, expressionToString)
                    ],
                    tl: {
                      hd: [
                        "endExpression",
                        expressionToString(match$12.endExpression)
                      ],
                      tl: {
                        hd: [
                          "operator",
                          Lex$Poly.tokenToString(match$12.operator)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* NEGATE */13 :
        var match$13 = expression._0;
        return unaExpressionToString("NEGATE", match$13.operand, match$13.unaryOperator);
    case /* NOT */14 :
        var match$14 = expression._0;
        return unaExpressionToString("NOT", match$14.operand, match$14.unaryOperator);
    case /* VARIABLE */15 :
        return Utils$Poly.variantToString("VARIABLE", {
                    hd: variableToString(expression._0),
                    tl: /* [] */0
                  });
    case /* INDEX */16 :
        return Utils$Poly.variantDictToString("INDEX", {
                    hd: [
                      "expression",
                      expressionToString(expression.expression)
                    ],
                    tl: {
                      hd: [
                        "index",
                        expressionToString(expression.index)
                      ],
                      tl: {
                        hd: [
                          "lbracket",
                          Lex$Poly.tokenToString(expression.lbracket)
                        ],
                        tl: {
                          hd: [
                            "rbracket",
                            Lex$Poly.tokenToString(expression.rbracket)
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  });
    case /* CALL */17 :
        return Utils$Poly.variantDictToString("CALL", {
                    hd: [
                      "expression",
                      expressionToString(expression.expression)
                    ],
                    tl: {
                      hd: [
                        "unnamedArgs",
                        Utils$Poly.listToString(List.map(expressionToString, expression.unnamedArgs))
                      ],
                      tl: {
                        hd: [
                          "namedArgs",
                          Utils$Poly.listToString(List.map((function (param) {
                                      return Utils$Poly.tupleToString({
                                                  hd: Utils$Poly.$$escape(param[0]),
                                                  tl: {
                                                    hd: Lex$Poly.tokenToString(param[1]),
                                                    tl: {
                                                      hd: expressionToString(param[2]),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                });
                                    }), expression.namedArgs))
                        ],
                        tl: {
                          hd: [
                            "lparen",
                            Lex$Poly.tokenToString(expression.lparen)
                          ],
                          tl: {
                            hd: [
                              "rparen",
                              Lex$Poly.tokenToString(expression.rparen)
                            ],
                            tl: {
                              hd: [
                                "callCommas",
                                Lex$Poly.tokenListToString(expression.callCommas)
                              ],
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  });
    case /* LITERAL */18 :
        return Utils$Poly.variantToString("LITERAL", {
                    hd: literalToString(expression._0),
                    tl: /* [] */0
                  });
    case /* EXPRESSION_ERROR */19 :
        return Utils$Poly.variantToString("EXPRESSION_ERROR", {
                    hd: expressionErrorToString(expression._0),
                    tl: /* [] */0
                  });
    
  }
}

function binExpressionToString(op, left, right, operator) {
  return Utils$Poly.variantDictToString(op, {
              hd: [
                "left",
                expressionToString(left)
              ],
              tl: {
                hd: [
                  "right",
                  expressionToString(right)
                ],
                tl: {
                  hd: [
                    "operator",
                    Lex$Poly.tokenToString(operator)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function unaExpressionToString(op, left, operator) {
  return Utils$Poly.variantDictToString(op, {
              hd: [
                "left",
                expressionToString(left)
              ],
              tl: {
                hd: [
                  "operator",
                  Lex$Poly.tokenToString(operator)
                ],
                tl: /* [] */0
              }
            });
}

function literalToString(l) {
  switch (l.TAG | 0) {
    case /* NUMBER */0 :
        return Utils$Poly.variantDictToString("NUMBER", {
                    hd: [
                      "number",
                      Utils$Poly.floatToString(l.number)
                    ],
                    tl: {
                      hd: [
                        "token",
                        Lex$Poly.tokenToString(l.token)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* TEXT */1 :
        return Utils$Poly.variantDictToString("TEXT", {
                    hd: [
                      "startQuoteToken",
                      Lex$Poly.tokenToString(l.startQuoteToken)
                    ],
                    tl: {
                      hd: [
                        "endQuoteToken",
                        Lex$Poly.tokenToString(l.endQuoteToken)
                      ],
                      tl: {
                        hd: [
                          "text",
                          Utils$Poly.listToString(List.map(textToString, l.text))
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* DICTIONARY_ITEM_TEXT */2 :
        return Utils$Poly.variantDictToString("DICTIONARY_ITEM_TEXT", {
                    hd: [
                      "textToken",
                      Lex$Poly.tokenToString(l.textToken)
                    ],
                    tl: {
                      hd: [
                        "contents",
                        Utils$Poly.$$escape(l.contents)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* ARRAY */3 :
        var match = l._0;
        return Utils$Poly.variantDictToString("ARRAY", {
                    hd: [
                      "lbracket",
                      Lex$Poly.tokenToString(match.lbracket)
                    ],
                    tl: {
                      hd: [
                        "rbracket",
                        Lex$Poly.tokenToString(match.rbracket)
                      ],
                      tl: {
                        hd: [
                          "commas",
                          Utils$Poly.listToString(List.map(Lex$Poly.tokenToString, match.commas))
                        ],
                        tl: {
                          hd: [
                            "elements",
                            Utils$Poly.listToString(List.map(expressionToString, match.elements))
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  });
    case /* TUPLE */4 :
        var match$1 = l._0;
        return Utils$Poly.variantDictToString("TUPLE", {
                    hd: [
                      "tupleType",
                      tupleTypeToString(match$1.tupleType)
                    ],
                    tl: {
                      hd: [
                        "unnamedTerms",
                        Utils$Poly.listToString(List.map(expressionToString, match$1.unnamedTerms))
                      ],
                      tl: {
                        hd: [
                          "namedTerms",
                          Utils$Poly.listToString(List.map((function (param) {
                                      return Utils$Poly.tupleToString({
                                                  hd: Utils$Poly.$$escape(param[0]),
                                                  tl: {
                                                    hd: Lex$Poly.tokenToString(param[1]),
                                                    tl: {
                                                      hd: expressionToString(param[2]),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                });
                                    }), match$1.namedTerms))
                        ],
                        tl: {
                          hd: [
                            "tupleCommas",
                            Utils$Poly.listToString(List.map(Lex$Poly.tokenToString, match$1.tupleCommas))
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  });
    case /* DICTIONARY */5 :
        var match$2 = l._0;
        return Utils$Poly.variantDictToString("DICTIONARY", {
                    hd: [
                      "leftBrace",
                      Lex$Poly.tokenToString(match$2.leftBrace)
                    ],
                    tl: {
                      hd: [
                        "rightBrace",
                        Lex$Poly.tokenToString(match$2.rightBrace)
                      ],
                      tl: {
                        hd: [
                          "dictionaryCommas",
                          Utils$Poly.listToString(List.map(Lex$Poly.tokenToString, match$2.dictionaryCommas))
                        ],
                        tl: {
                          hd: [
                            "items",
                            Utils$Poly.listToString(List.map((function (item) {
                                        if (item.TAG === /* REGULAR */0) {
                                          return Utils$Poly.variantDictToString("REGULAR", {
                                                      hd: [
                                                        "key",
                                                        expressionToString(item.key)
                                                      ],
                                                      tl: {
                                                        hd: [
                                                          "value",
                                                          expressionToString(item.value)
                                                        ],
                                                        tl: /* [] */0
                                                      }
                                                    });
                                        } else {
                                          return Utils$Poly.variantDictToString("SHORTFORM", {
                                                      hd: [
                                                        "key",
                                                        variableToString(item.key)
                                                      ],
                                                      tl: /* [] */0
                                                    });
                                        }
                                      }), match$2.items))
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  });
    
  }
}

function typeExpressionToString(typeExpression) {
  switch (typeExpression.TAG | 0) {
    case /* TYPE */0 :
        return Utils$Poly.variantDictToString("TYPE", {
                    hd: [
                      "name",
                      Utils$Poly.$$escape(typeExpression.name)
                    ],
                    tl: {
                      hd: [
                        "token",
                        Lex$Poly.tokenToString(typeExpression.token)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* ARRAY_TYPE */1 :
        return Utils$Poly.variantDictToString("ARRAY_TYPE", {
                    hd: [
                      "typeExpression",
                      typeExpressionToString(typeExpression.typeExpression)
                    ],
                    tl: {
                      hd: [
                        "leftBracket",
                        Lex$Poly.tokenToString(typeExpression.leftBracket)
                      ],
                      tl: {
                        hd: [
                          "rightBracket",
                          Lex$Poly.tokenToString(typeExpression.rightBracket)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* TUPLE_TYPE */2 :
        return Utils$Poly.variantDictToString("TUPLE_TYPE", {
                    hd: [
                      "tupleType",
                      tupleTypeToString(typeExpression.tupleType)
                    ],
                    tl: {
                      hd: [
                        "types",
                        Utils$Poly.listToString(List.map(typeExpressionToString, typeExpression.types))
                      ],
                      tl: {
                        hd: [
                          "commas",
                          Lex$Poly.tokenListToString(typeExpression.commas)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* NAMED_TUPLE_TYPE */3 :
        return Utils$Poly.variantDictToString("TUPLE_TYPE", {
                    hd: [
                      "tupleType",
                      tupleTypeToString(typeExpression.tupleType)
                    ],
                    tl: {
                      hd: [
                        "namedTypes",
                        Utils$Poly.listToString(List.map((function (param) {
                                    return Utils$Poly.tupleToString({
                                                hd: Utils$Poly.$$escape(param[0]),
                                                tl: {
                                                  hd: Lex$Poly.tokenToString(param[1]),
                                                  tl: {
                                                    hd: typeExpressionToString(param[2]),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              });
                                  }), typeExpression.namedTypes))
                      ],
                      tl: {
                        hd: [
                          "defaultTypes",
                          Utils$Poly.listToString(List.map((function (param) {
                                      return Utils$Poly.tupleToString({
                                                  hd: Utils$Poly.$$escape(param[0]),
                                                  tl: {
                                                    hd: Lex$Poly.tokenToString(param[1]),
                                                    tl: {
                                                      hd: typeExpressionToString(param[2]),
                                                      tl: {
                                                        hd: Lex$Poly.tokenToString(param[3]),
                                                        tl: {
                                                          hd: expressionToString(param[4]),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                });
                                    }), typeExpression.defaultTypes))
                        ],
                        tl: {
                          hd: [
                            "commas",
                            Lex$Poly.tokenListToString(typeExpression.commas)
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  });
    case /* DICTIONARY_TYPE */4 :
        return Utils$Poly.variantDictToString("DICTIONARY_TYPE", {
                    hd: [
                      "keyType",
                      typeExpressionToString(typeExpression.keyType)
                    ],
                    tl: {
                      hd: [
                        "valueType",
                        typeExpressionToString(typeExpression.valueType)
                      ],
                      tl: {
                        hd: [
                          "leftBrace",
                          Lex$Poly.tokenToString(typeExpression.leftBrace)
                        ],
                        tl: {
                          hd: [
                            "colon",
                            Lex$Poly.tokenToString(typeExpression.colon)
                          ],
                          tl: {
                            hd: [
                              "rightBrace",
                              Lex$Poly.tokenToString(typeExpression.rightBrace)
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  });
    
  }
}

function variableTypeToString(variableType) {
  return Utils$Poly.dictToString({
              hd: [
                "colon",
                Lex$Poly.tokenToString(variableType.colon)
              ],
              tl: {
                hd: [
                  "typeExpression",
                  typeExpressionToString(variableType.typeExpression)
                ],
                tl: /* [] */0
              }
            });
}

function variableDeclarationToString(variableDeclaration) {
  return Utils$Poly.dictToString({
              hd: [
                "variable",
                variableToString(variableDeclaration.variable)
              ],
              tl: {
                hd: [
                  "variableType",
                  Utils$Poly.optionToString(variableDeclaration.variableType, variableTypeToString)
                ],
                tl: /* [] */0
              }
            });
}

function statementErrorToString(statementError) {
  switch (statementError.TAG | 0) {
    case /* EXPECTING_SEMICOLON_AFTER_DECLARATION_STATEMENT */0 :
        return Utils$Poly.variantDictToString("EXPECTING_SEMICOLON_AFTER_DECLARATION_STATEMENT", {
                    hd: [
                      "errorToken",
                      Utils$Poly.optionToString(statementError.errorToken, Lex$Poly.tokenToString)
                    ],
                    tl: {
                      hd: [
                        "range",
                        Position$Poly.rangeToString(statementError.range)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* EXPECTING_EQUALS_AFTER_VARIABLE_DECLARATION_STATEMENT */1 :
        return Utils$Poly.variantDictToString("EXPECTING_EQUALS_AFTER_VARIABLE_DECLARATION_STATEMENT", {
                    hd: [
                      "letToken",
                      Lex$Poly.tokenToString(statementError.letToken)
                    ],
                    tl: {
                      hd: [
                        "errorToken",
                        Utils$Poly.optionToString(statementError.errorToken, Lex$Poly.tokenToString)
                      ],
                      tl: {
                        hd: [
                          "position",
                          Position$Poly.positionToString(statementError.position)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* EXPECTING_VARIABLE_DECLARATION_STATEMENT */2 :
        return Utils$Poly.variantDictToString("EXPECTING_VARIABLE_DECLARATION_STATEMENT", {
                    hd: [
                      "errorToken",
                      Utils$Poly.optionToString(statementError.errorToken, Lex$Poly.tokenToString)
                    ],
                    tl: {
                      hd: [
                        "position",
                        Position$Poly.positionToString(statementError.position)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* EXPECTING_SEMICOLON_AFTER_REASSIGN_STATEMENT */3 :
        return Utils$Poly.variantDictToString("EXPECTING_SEMICOLON_AFTER_REASSIGN_STATEMENT", {
                    hd: [
                      "errorToken",
                      Utils$Poly.optionToString(statementError.errorToken, Lex$Poly.tokenToString)
                    ],
                    tl: {
                      hd: [
                        "range",
                        Position$Poly.rangeToString(statementError.range)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* EXPECTING_EQUALS_AFTER_REASSIGN_STATEMENT */4 :
        return Utils$Poly.variantDictToString("EXPECTING_EQUALS_AFTER_REASSIGN_STATEMENT", {
                    hd: [
                      "variableToken",
                      Lex$Poly.tokenToString(statementError.variableToken)
                    ],
                    tl: {
                      hd: [
                        "errorToken",
                        Utils$Poly.optionToString(statementError.errorToken, Lex$Poly.tokenToString)
                      ],
                      tl: {
                        hd: [
                          "position",
                          Position$Poly.positionToString(statementError.position)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* EXPECTING_SEMICOLON_AFTER_EXPRESSION_STATEMENT */5 :
        return Utils$Poly.variantDictToString("EXPECTING_SEMICOLON_AFTER_EXPRESSION_STATEMENT", {
                    hd: [
                      "errorToken",
                      Utils$Poly.optionToString(statementError.errorToken, Lex$Poly.tokenToString)
                    ],
                    tl: {
                      hd: [
                        "range",
                        Position$Poly.rangeToString(statementError.range)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* EXPECTING_RBRACE */6 :
        return Utils$Poly.variantDictToString("EXPECTING_RBRACE", {
                    hd: [
                      "lbraceToken",
                      Lex$Poly.tokenToString(statementError.lbraceToken)
                    ],
                    tl: {
                      hd: [
                        "position",
                        Position$Poly.positionToString(statementError.position)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* EXPECTING_BRACE_AFTER_IF */7 :
        return Utils$Poly.variantDictToString("EXPECTING_BRACE_AFTER_IF", {
                    hd: [
                      "ifToken",
                      Lex$Poly.tokenToString(statementError.ifToken)
                    ],
                    tl: {
                      hd: [
                        "errorToken",
                        Utils$Poly.optionToString(statementError.errorToken, Lex$Poly.tokenToString)
                      ],
                      tl: {
                        hd: [
                          "position",
                          Position$Poly.positionToString(statementError.position)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* EXPECTING_IF_OR_BLOCK_AFTER_ELSE */8 :
        return Utils$Poly.variantDictToString("EXPECTING_IF_OR_BLOCK_AFTER_ELSE", {
                    hd: [
                      "ifToken",
                      Lex$Poly.tokenToString(statementError.ifToken)
                    ],
                    tl: {
                      hd: [
                        "elseToken",
                        Lex$Poly.tokenToString(statementError.elseToken)
                      ],
                      tl: {
                        hd: [
                          "errorToken",
                          Utils$Poly.optionToString(statementError.errorToken, Lex$Poly.tokenToString)
                        ],
                        tl: {
                          hd: [
                            "position",
                            Position$Poly.positionToString(statementError.position)
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  });
    case /* EXPECTING_IN_TOKEN_FOR_IN */9 :
        return Utils$Poly.variantDictToString("EXPECTING_IN_TOKEN_FOR_IN", {
                    hd: [
                      "forToken",
                      Lex$Poly.tokenToString(statementError.forToken)
                    ],
                    tl: {
                      hd: [
                        "errorToken",
                        Lex$Poly.optionTokenToString(statementError.errorToken)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* EXPECTING_BRACE_AFTER_FOR_IN */10 :
        return Utils$Poly.variantDictToString("EXPECTING_BRACE_AFTER_FOR_IN", {
                    hd: [
                      "forToken",
                      Lex$Poly.tokenToString(statementError.forToken)
                    ],
                    tl: {
                      hd: [
                        "inToken",
                        Lex$Poly.tokenToString(statementError.inToken)
                      ],
                      tl: {
                        hd: [
                          "errorToken",
                          Lex$Poly.optionTokenToString(statementError.errorToken)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    
  }
}

function statementToString(s) {
  if (typeof s === "number") {
    return "STATEMENT_ERROR";
  }
  switch (s.TAG | 0) {
    case /* DECLARATION */0 :
        return Utils$Poly.variantDictToString("DECLARATION", {
                    hd: [
                      "variableDeclaration",
                      variableDeclarationToString(s.variableDeclaration)
                    ],
                    tl: {
                      hd: [
                        "expression",
                        expressionToString(s.expression)
                      ],
                      tl: {
                        hd: [
                          "lexToken",
                          Lex$Poly.tokenToString(s.letToken)
                        ],
                        tl: {
                          hd: [
                            "assignToken",
                            Lex$Poly.tokenToString(s.assignToken)
                          ],
                          tl: {
                            hd: [
                              "semicolonToken",
                              Utils$Poly.optionToString(s.semicolonToken, Lex$Poly.tokenToString)
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  });
    case /* REASSIGN */1 :
        return Utils$Poly.variantDictToString("REASSIGN", {
                    hd: [
                      "variable",
                      variableToString(s.variable)
                    ],
                    tl: {
                      hd: [
                        "assignToken",
                        Lex$Poly.tokenToString(s.assignToken)
                      ],
                      tl: {
                        hd: [
                          "expression",
                          expressionToString(s.expression)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* EXPRESSION */2 :
        return Utils$Poly.variantDictToString("EXPRESSION", {
                    hd: [
                      "expression",
                      expressionToString(s.expression)
                    ],
                    tl: {
                      hd: [
                        "semicolonToken",
                        Utils$Poly.optionToString(s.semicolonToken, Lex$Poly.tokenToString)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* IF */3 :
        return Utils$Poly.variantToString("IF", {
                    hd: ifStatementToString(s._0),
                    tl: /* [] */0
                  });
    case /* FOR_IN */4 :
        return Utils$Poly.variantDictToString("FOR_IN", {
                    hd: [
                      "forToken",
                      Lex$Poly.tokenToString(s.forToken)
                    ],
                    tl: {
                      hd: [
                        "identifier",
                        variableDeclarationToString(s.identifier)
                      ],
                      tl: {
                        hd: [
                          "inToken",
                          Lex$Poly.tokenToString(s.inToken)
                        ],
                        tl: {
                          hd: [
                            "expression",
                            expressionToString(s.expression)
                          ],
                          tl: {
                            hd: [
                              "block",
                              blockToString(s.block)
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  });
    case /* BLOCK */5 :
        return Utils$Poly.variantToString("BLOCK", {
                    hd: blockToString(s._0),
                    tl: /* [] */0
                  });
    
  }
}

function blockToString(block) {
  return Utils$Poly.dictToString({
              hd: [
                "lbraceToken",
                Lex$Poly.tokenToString(block.lbraceToken)
              ],
              tl: {
                hd: [
                  "rbraceToken",
                  Lex$Poly.tokenToString(block.rbraceToken)
                ],
                tl: {
                  hd: [
                    "statements",
                    Utils$Poly.join(List.map(statementToString, block.statements))
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function ifStatementToString(ifStatement) {
  return Utils$Poly.dictToString({
              hd: [
                "ifToken",
                Lex$Poly.tokenToString(ifStatement.ifToken)
              ],
              tl: {
                hd: [
                  "expression",
                  expressionToString(ifStatement.expression)
                ],
                tl: {
                  hd: [
                    "block",
                    blockToString(ifStatement.block)
                  ],
                  tl: {
                    hd: [
                      "elseStatement",
                      Utils$Poly.optionToString(ifStatement.elseStatement, elseStatementToString)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function elseStatementToString(elseStatement) {
  if (elseStatement.TAG === /* ELSE_IF */0) {
    return Utils$Poly.variantDictToString("ELSE_IF", {
                hd: [
                  "elseToken",
                  Lex$Poly.tokenToString(elseStatement.elseToken)
                ],
                tl: {
                  hd: [
                    "ifStatement",
                    ifStatementToString(elseStatement.ifStatement)
                  ],
                  tl: /* [] */0
                }
              });
  } else {
    return Utils$Poly.variantDictToString("ELSE", {
                hd: [
                  "elseToken",
                  Lex$Poly.tokenToString(elseStatement.elseToken)
                ],
                tl: {
                  hd: [
                    "block",
                    blockToString(elseStatement.block)
                  ],
                  tl: /* [] */0
                }
              });
  }
}

function programToString(p) {
  if (p) {
    return statementToString(p.hd) + ("\n" + programToString(p.tl));
  } else {
    return "";
  }
}

exports.tupleModeToString = tupleModeToString;
exports.expressionErrorToString = expressionErrorToString;
exports.textToString = textToString;
exports.tupleTypeToString = tupleTypeToString;
exports.literalToString = literalToString;
exports.variableDeclarationToString = variableDeclarationToString;
exports.variableToString = variableToString;
exports.variableTypeToString = variableTypeToString;
exports.typeExpressionToString = typeExpressionToString;
exports.expressionToString = expressionToString;
exports.binExpressionToString = binExpressionToString;
exports.unaExpressionToString = unaExpressionToString;
exports.statementErrorToString = statementErrorToString;
exports.statementToString = statementToString;
exports.blockToString = blockToString;
exports.ifStatementToString = ifStatementToString;
exports.elseStatementToString = elseStatementToString;
exports.programToString = programToString;
/* No side effect */
