'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var React = require("react");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Utils$Poly = require("../utils/Utils.bs.js");

function styledTextToString(styledText) {
  return styledText[0];
}

function styledTextToHtml(styledText, key) {
  var s = styledText[0];
  switch (styledText[1]) {
    case /* NORMAL */0 :
        return React.createElement("span", {
                    key: String(key)
                  }, s);
    case /* RED */1 :
        return React.createElement("span", {
                    key: String(key),
                    className: "red"
                  }, s);
    case /* YELLOW */2 :
        return React.createElement("span", {
                    key: String(key),
                    className: "yellow"
                  }, s);
    
  }
}

function messageToString(message) {
  return Utils$Poly.emptyJoin(List.map(styledTextToString, message));
}

function messageToHtml(message, key) {
  return React.createElement("div", {
              className: "message"
            }, $$Array.of_list(List.mapi((function (i, x) {
                        return styledTextToHtml(x, key + i | 0);
                      }), message)));
}

function messagesToHtml(messages) {
  var getHtml = function (message, key) {
    return List.mapi((function (i, x) {
                  return styledTextToHtml(x, key + i | 0);
                }), message);
  };
  var accumulateMessages = function (messages, key) {
    if (!messages) {
      return /* [] */0;
    }
    var tl = messages.tl;
    var hd = messages.hd;
    if (!tl) {
      return getHtml(hd, key);
    }
    var output = getHtml(hd, key);
    var output$1 = Pervasives.$at(output, List.length(output) > 0 ? ({
              hd: styledTextToHtml([
                    "\n\n",
                    /* NORMAL */0
                  ], key + List.length(hd) | 0),
              tl: /* [] */0
            }) : /* [] */0);
    return Pervasives.$at(output$1, accumulateMessages(tl, (key + List.length(hd) | 0) + 1 | 0));
  };
  return React.createElement("div", {
              className: "message"
            }, $$Array.of_list(accumulateMessages(messages, 0)));
}

exports.styledTextToString = styledTextToString;
exports.styledTextToHtml = styledTextToHtml;
exports.messageToString = messageToString;
exports.messageToHtml = messageToHtml;
exports.messagesToHtml = messagesToHtml;
/* react Not a pure module */
