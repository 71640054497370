'use strict';

var List = require("rescript/lib/js/list.js");
var Lexer$Poly = require("../lexer/Lexer.bs.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Compile$Poly = require("../compiler/Compile.bs.js");
var Compiler$Poly = require("../compiler/Compiler.bs.js");
var AstSimple$Poly = require("./AstSimple.bs.js");
var Statement$Poly = require("./Statement.bs.js");
var ParseError$Poly = require("./ParseError.bs.js");
var ParseContext$Poly = require("./ParseContext.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

function parse(ctx) {
  var _ctx = ctx;
  var _statements = /* [] */0;
  while(true) {
    var statements = _statements;
    var ctx$1 = _ctx;
    if (ParseContext$Poly.atEnd(ctx$1)) {
      return [
              ctx$1,
              statements
            ];
    }
    var val;
    try {
      val = Statement$Poly.statement(ctx$1);
    }
    catch (raw_ctx){
      var ctx$2 = Caml_js_exceptions.internalToOCamlException(raw_ctx);
      if (ctx$2.RE_EXN_ID === ParseError$Poly.ParseError) {
        _ctx = ctx$2._1;
        continue ;
      }
      throw ctx$2;
    }
    _statements = Pervasives.$at(statements, {
          hd: val[1],
          tl: /* [] */0
        });
    _ctx = val[0];
    continue ;
  };
}

function parseFromLex(lexContext) {
  return {
          source: lexContext.source,
          position: 0,
          tokens: lexContext.tokens,
          diagnostics: /* [] */0,
          fixes: /* [] */0,
          lexErrors: lexContext.errors,
          parseTree: undefined
        };
}

function parseSimpleProgram(s) {
  var lexContext = Lexer$Poly.lexSimpleProgram(s);
  var parseContext = parseFromLex(lexContext);
  var match = parse(parseContext);
  var compileDiagnostics = Compiler$Poly.compileDiagnosticsFromContexts(match[0]);
  return [
          compileDiagnostics,
          match[1]
        ];
}

function parseSimpleProgramSimple(s) {
  var match = parseSimpleProgram(s);
  var diagnostics = List.map((function (param) {
          return Compile$Poly.compileErrorToSimple(param[0]);
        }), match[0]);
  return [
          diagnostics,
          AstSimple$Poly.programToSimple(match[1])
        ];
}

exports.parse = parse;
exports.parseFromLex = parseFromLex;
exports.parseSimpleProgram = parseSimpleProgram;
exports.parseSimpleProgramSimple = parseSimpleProgramSimple;
/* Lexer-Poly Not a pure module */
