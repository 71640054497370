'use strict';

var Pervasives = require("rescript/lib/js/pervasives.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

var ParseError = /* @__PURE__ */Caml_exceptions.create("ParseError-Poly.ParseError");

function pushError(ctx, error) {
  var ctx_source = ctx.source;
  var ctx_position = ctx.position;
  var ctx_tokens = ctx.tokens;
  var ctx_diagnostics = Pervasives.$at(ctx.diagnostics, {
        hd: [
          error,
          /* ERROR */0,
          /* [] */0
        ],
        tl: /* [] */0
      });
  var ctx_fixes = ctx.fixes;
  var ctx_lexErrors = ctx.lexErrors;
  var ctx_parseTree = ctx.parseTree;
  var ctx$1 = {
    source: ctx_source,
    position: ctx_position,
    tokens: ctx_tokens,
    diagnostics: ctx_diagnostics,
    fixes: ctx_fixes,
    lexErrors: ctx_lexErrors,
    parseTree: ctx_parseTree
  };
  throw {
        RE_EXN_ID: ParseError,
        _1: ctx$1,
        Error: new Error()
      };
}

function pushErrorFixes(ctx, error, fixes) {
  var ctx_source = ctx.source;
  var ctx_position = ctx.position;
  var ctx_tokens = ctx.tokens;
  var ctx_diagnostics = Pervasives.$at(ctx.diagnostics, {
        hd: [
          error,
          /* ERROR */0,
          fixes
        ],
        tl: /* [] */0
      });
  var ctx_fixes = Pervasives.$at(ctx.fixes, {
        hd: fixes,
        tl: /* [] */0
      });
  var ctx_lexErrors = ctx.lexErrors;
  var ctx_parseTree = ctx.parseTree;
  var ctx$1 = {
    source: ctx_source,
    position: ctx_position,
    tokens: ctx_tokens,
    diagnostics: ctx_diagnostics,
    fixes: ctx_fixes,
    lexErrors: ctx_lexErrors,
    parseTree: ctx_parseTree
  };
  throw {
        RE_EXN_ID: ParseError,
        _1: ctx$1,
        Error: new Error()
      };
}

function pushWarning(ctx, warning) {
  return {
          source: ctx.source,
          position: ctx.position,
          tokens: ctx.tokens,
          diagnostics: Pervasives.$at(ctx.diagnostics, {
                hd: [
                  warning,
                  /* WARNING */1,
                  /* [] */0
                ],
                tl: /* [] */0
              }),
          fixes: ctx.fixes,
          lexErrors: ctx.lexErrors,
          parseTree: ctx.parseTree
        };
}

function pushWarningFixes(ctx, warning, fixes) {
  return {
          source: ctx.source,
          position: ctx.position,
          tokens: ctx.tokens,
          diagnostics: Pervasives.$at(ctx.diagnostics, {
                hd: [
                  warning,
                  /* WARNING */1,
                  fixes
                ],
                tl: /* [] */0
              }),
          fixes: ctx.fixes,
          lexErrors: ctx.lexErrors,
          parseTree: ctx.parseTree
        };
}

exports.ParseError = ParseError;
exports.pushError = pushError;
exports.pushErrorFixes = pushErrorFixes;
exports.pushWarning = pushWarning;
exports.pushWarningFixes = pushWarningFixes;
/* No side effect */
