'use strict';

var $$String = require("rescript/lib/js/string.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Token$Poly = require("./Token.bs.js");
var Position$Poly = require("../position/Position.bs.js");

function getTokenText(ctx, token) {
  var start = Position$Poly.startChar(token.range);
  var finish = Position$Poly.endChar(token.range);
  return $$String.sub(ctx.source, start, finish - start | 0);
}

function syntaxHighlight(ctx) {
  var pushNormal = function (start, finish) {
    if (start === finish) {
      return /* [] */0;
    } else {
      return {
              hd: {
                tokenType: /* NORMAL */0,
                text: $$String.sub(ctx.source, start, finish - start | 0)
              },
              tl: /* [] */0
            };
    }
  };
  var pushToken = function (ctx, token) {
    var text = getTokenText(ctx, token);
    if (text.length === 0) {
      return /* [] */0;
    } else {
      return {
              hd: {
                tokenType: Token$Poly.getType(token.token),
                text: text
              },
              tl: /* [] */0
            };
    }
  };
  var _highlight = function (position, tokens) {
    if (!tokens) {
      return pushNormal(position, ctx.source.length);
    }
    var token = tokens.hd;
    return Pervasives.$at(pushNormal(position, Position$Poly.startChar(token.range)), Pervasives.$at(pushToken(ctx, token), _highlight(Position$Poly.endChar(token.range), tokens.tl)));
  };
  return _highlight(0, ctx.tokens);
}

exports.getTokenText = getTokenText;
exports.syntaxHighlight = syntaxHighlight;
/* No side effect */
