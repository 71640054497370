'use strict';

var ParseContext$Poly = require("./ParseContext.bs.js");

function advance(ctx, ast) {
  return [
          ParseContext$Poly.incrementPosition(ctx),
          ast
        ];
}

exports.advance = advance;
/* No side effect */
