'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var ParseContext$Poly = require("./ParseContext.bs.js");

function parseList(ctx, endToken, initialElements, elementsHandler, expressionHandler, returnHandler, errorHandler) {
  var _ctx = ctx;
  var _elements = initialElements;
  var _commas = /* [] */0;
  while(true) {
    var commas = _commas;
    var elements = _elements;
    var ctx$1 = _ctx;
    var closingToken = ParseContext$Poly.currentToken(ctx$1);
    if (closingToken !== undefined && Caml_obj.caml_equal(closingToken.token, endToken)) {
      return [
              ParseContext$Poly.incrementPosition(ctx$1),
              Curry._1(returnHandler, {
                    closingToken: closingToken,
                    commas: commas,
                    elements: elements
                  })
            ];
    }
    var match = Curry._1(expressionHandler, {
          ctx: ctx$1,
          commas: commas,
          elements: elements
        });
    var ctx$2 = match[0];
    var elements$1 = Curry._2(elementsHandler, elements, match[1]);
    var comma = ParseContext$Poly.currentToken(ctx$2);
    if (comma === undefined) {
      return Curry._1(errorHandler, {
                  ctx: ctx$2,
                  errorToken: comma,
                  elements: elements$1,
                  commas: commas
                });
    }
    var t = comma.token;
    if (t !== 2) {
      if (Caml_obj.caml_equal(t, endToken)) {
        return [
                ParseContext$Poly.incrementPosition(ctx$2),
                Curry._1(returnHandler, {
                      closingToken: comma,
                      commas: commas,
                      elements: elements$1
                    })
              ];
      } else {
        return Curry._1(errorHandler, {
                    ctx: ctx$2,
                    errorToken: comma,
                    elements: elements$1,
                    commas: commas
                  });
      }
    }
    _commas = Pervasives.$at(commas, {
          hd: comma,
          tl: /* [] */0
        });
    _elements = elements$1;
    _ctx = ParseContext$Poly.incrementPosition(ctx$2);
    continue ;
  };
}

exports.parseList = parseList;
/* No side effect */
