'use strict';

var Parse$Poly = require("../parser/Parse.bs.js");
var Utils$Poly = require("../utils/Utils.bs.js");

function compileErrorToString(compileError) {
  return Utils$Poly.variantToString("PARSE_ERROR", {
              hd: Parse$Poly.parseErrorToString(compileError._0),
              tl: /* [] */0
            });
}

function compileErrorToSimple(compileError) {
  return /* PARSE_ERROR */{
          _0: Parse$Poly.parseErrorToSimple(compileError._0)
        };
}

function compileErrorSimpleToString(compileErrorSimple) {
  return Utils$Poly.variantToString("PARSE_ERROR", {
              hd: Parse$Poly.parseErrorSimpleToString(compileErrorSimple._0),
              tl: /* [] */0
            });
}

function compileErrorToSimpleString(compileError) {
  return compileErrorSimpleToString(compileErrorToSimple(compileError));
}

function getDiagnosticLevel(compileDiagnostic) {
  return compileDiagnostic[1];
}

function parseToCompilerDiagnostic(diagnostic) {
  return [
          /* PARSE_ERROR */{
            _0: diagnostic[0]
          },
          diagnostic[1],
          diagnostic[2]
        ];
}

exports.compileErrorToString = compileErrorToString;
exports.compileErrorToSimple = compileErrorToSimple;
exports.compileErrorSimpleToString = compileErrorSimpleToString;
exports.compileErrorToSimpleString = compileErrorToSimpleString;
exports.getDiagnosticLevel = getDiagnosticLevel;
exports.parseToCompilerDiagnostic = parseToCompilerDiagnostic;
/* No side effect */
