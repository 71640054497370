'use strict';

var $$String = require("rescript/lib/js/string.js");

function addString(ctx, s) {
  return {
          result: ctx.result + s,
          nbSpaces: ctx.nbSpaces
        };
}

function forgetSpaces(ctx) {
  return {
          result: ctx.result,
          nbSpaces: 0
        };
}

function flushSpaces(ctx) {
  return forgetSpaces(addString(ctx, $$String.make(ctx.nbSpaces, /* ' ' */32)));
}

function indent(ctx, i) {
  var ctx$1 = forgetSpaces(ctx);
  return addString(ctx$1, $$String.make(i, /* ' ' */32));
}

function substring(ctx, s, o, l) {
  var ctx$1 = flushSpaces(ctx);
  return addString(ctx$1, $$String.sub(s, o, l));
}

function space(ctx) {
  return {
          result: ctx.result,
          nbSpaces: ctx.nbSpaces + 1 | 0
        };
}

function newline(ctx) {
  var ctx$1 = forgetSpaces(ctx);
  return addString(ctx$1, "\n");
}

exports.addString = addString;
exports.forgetSpaces = forgetSpaces;
exports.flushSpaces = flushSpaces;
exports.indent = indent;
exports.substring = substring;
exports.space = space;
exports.newline = newline;
/* No side effect */
