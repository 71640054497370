'use strict';

var Pervasives = require("rescript/lib/js/pervasives.js");
var Position$Poly = require("../position/Position.bs.js");
var Expression$Poly = require("./Expression.bs.js");
var ParseError$Poly = require("./ParseError.bs.js");
var ParseContext$Poly = require("./ParseContext.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var StatementUtils$Poly = require("./StatementUtils.bs.js");

function declareStatement(ctx, letToken) {
  var match = Expression$Poly.variableDeclaration(ctx);
  var variableDeclaration = match[1];
  var ctx$1 = match[0];
  var assignToken = ParseContext$Poly.currentToken(ctx$1);
  if (assignToken !== undefined && assignToken.token === 16) {
    var ctx$2 = ParseContext$Poly.incrementPosition(ctx$1);
    var match$1 = Expression$Poly.expression(ctx$2);
    var e = match$1[1];
    var ctx$3 = match$1[0];
    var semicolonToken = ParseContext$Poly.currentToken(ctx$3);
    var exit = 0;
    if (semicolonToken !== undefined) {
      if (semicolonToken.token === 0) {
        return StatementUtils$Poly.advance(ctx$3, {
                    TAG: /* DECLARATION */0,
                    variableDeclaration: variableDeclaration,
                    expression: e,
                    letToken: letToken,
                    assignToken: assignToken,
                    semicolonToken: semicolonToken
                  });
      }
      exit = 2;
    } else {
      exit = 2;
    }
    if (exit === 2) {
      var ctx$4 = ParseContext$Poly.decrementPosition(ctx$3);
      var endPosition = ParseContext$Poly.currentEndPosition(ctx$4);
      var fixes_0 = {
        TAG: /* INSERT */0,
        position: endPosition,
        text: ";"
      };
      var fixes = {
        hd: fixes_0,
        tl: /* [] */0
      };
      var error = {
        TAG: /* STATEMENT_ERROR */2,
        _0: {
          TAG: /* EXPECTING_SEMICOLON_AFTER_DECLARATION_STATEMENT */0,
          errorToken: semicolonToken,
          range: {
            TAG: /* RANGE */1,
            _0: Position$Poly.startPos(letToken.range),
            _1: endPosition
          }
        }
      };
      if (!ParseContext$Poly.nextTokenOnNewline(ctx$4)) {
        return ParseError$Poly.pushErrorFixes(ctx$4, error, fixes);
      }
      var ctx$5 = ParseError$Poly.pushWarningFixes(ctx$4, error, fixes);
      return StatementUtils$Poly.advance(ctx$5, {
                  TAG: /* DECLARATION */0,
                  variableDeclaration: variableDeclaration,
                  expression: e,
                  letToken: letToken,
                  assignToken: assignToken,
                  semicolonToken: undefined
                });
    }
    
  }
  return ParseError$Poly.pushError(ctx$1, {
              TAG: /* STATEMENT_ERROR */2,
              _0: {
                TAG: /* EXPECTING_EQUALS_AFTER_VARIABLE_DECLARATION_STATEMENT */1,
                letToken: letToken,
                errorToken: assignToken,
                position: ParseContext$Poly.currentPosition(ctx$1)
              }
            });
}

function reassignStatement(ctx, variable) {
  var assignToken = ParseContext$Poly.currentToken(ctx);
  if (assignToken !== undefined && assignToken.token === 16) {
    var ctx$1 = ParseContext$Poly.incrementPosition(ctx);
    var match = Expression$Poly.expression(ctx$1);
    var e = match[1];
    var ctx$2 = match[0];
    var semicolonToken = ParseContext$Poly.currentToken(ctx$2);
    var exit = 0;
    if (semicolonToken !== undefined) {
      if (semicolonToken.token === 0) {
        return StatementUtils$Poly.advance(ctx$2, {
                    TAG: /* REASSIGN */1,
                    variable: variable,
                    expression: e,
                    assignToken: assignToken,
                    semicolonToken: semicolonToken
                  });
      }
      exit = 2;
    } else {
      exit = 2;
    }
    if (exit === 2) {
      var ctx$3 = ParseContext$Poly.decrementPosition(ctx$2);
      var endPosition = ParseContext$Poly.currentEndPosition(ctx$3);
      var fixes_0 = {
        TAG: /* INSERT */0,
        position: endPosition,
        text: ";"
      };
      var fixes = {
        hd: fixes_0,
        tl: /* [] */0
      };
      var error = {
        TAG: /* STATEMENT_ERROR */2,
        _0: {
          TAG: /* EXPECTING_SEMICOLON_AFTER_REASSIGN_STATEMENT */3,
          errorToken: semicolonToken,
          range: {
            TAG: /* RANGE */1,
            _0: Position$Poly.startPos(variable.variableToken.range),
            _1: endPosition
          }
        }
      };
      if (!ParseContext$Poly.nextTokenOnNewline(ctx$3)) {
        return ParseError$Poly.pushErrorFixes(ctx$3, error, fixes);
      }
      var ctx$4 = ParseError$Poly.pushWarningFixes(ctx$3, error, fixes);
      return StatementUtils$Poly.advance(ctx$4, {
                  TAG: /* REASSIGN */1,
                  variable: variable,
                  expression: e,
                  assignToken: assignToken,
                  semicolonToken: undefined
                });
    }
    
  }
  return ParseError$Poly.pushError(ctx, {
              TAG: /* STATEMENT_ERROR */2,
              _0: {
                TAG: /* EXPECTING_EQUALS_AFTER_REASSIGN_STATEMENT */4,
                variableToken: variable.variableToken,
                errorToken: assignToken,
                position: ParseContext$Poly.currentPosition(ctx)
              }
            });
}

function expressionStatement(ctx) {
  var startPosition = ParseContext$Poly.currentPosition(ctx);
  var match = Expression$Poly.expression(ctx);
  var e = match[1];
  var ctx$1 = match[0];
  var semicolonToken = ParseContext$Poly.currentToken(ctx$1);
  if (semicolonToken !== undefined && semicolonToken.token === 0) {
    return StatementUtils$Poly.advance(ctx$1, {
                TAG: /* EXPRESSION */2,
                expression: e,
                semicolonToken: semicolonToken
              });
  }
  var ctx$2 = ParseContext$Poly.decrementPosition(ctx$1);
  var endPosition = ParseContext$Poly.currentEndPosition(ctx$2);
  var fixes_0 = {
    TAG: /* INSERT */0,
    position: endPosition,
    text: ";"
  };
  var fixes = {
    hd: fixes_0,
    tl: /* [] */0
  };
  var error = {
    TAG: /* STATEMENT_ERROR */2,
    _0: {
      TAG: /* EXPECTING_SEMICOLON_AFTER_EXPRESSION_STATEMENT */5,
      errorToken: semicolonToken,
      range: {
        TAG: /* RANGE */1,
        _0: startPosition,
        _1: endPosition
      }
    }
  };
  if (!ParseContext$Poly.nextTokenOnNewline(ctx$2)) {
    return ParseError$Poly.pushErrorFixes(ctx$2, error, fixes);
  }
  var ctx$3 = ParseError$Poly.pushWarningFixes(ctx$2, error, fixes);
  return StatementUtils$Poly.advance(ctx$3, {
              TAG: /* EXPRESSION */2,
              expression: e,
              semicolonToken: undefined
            });
}

function synchronize(_ctx) {
  while(true) {
    var ctx = _ctx;
    var ctx$1 = ParseContext$Poly.incrementPosition(ctx);
    var val;
    try {
      val = ParseContext$Poly.currentToken(ctx$1);
    }
    catch (raw_exn){
      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
      if (exn.RE_EXN_ID === ParseError$Poly.ParseError) {
        _ctx = ctx$1;
        continue ;
      }
      throw exn;
    }
    if (val === undefined) {
      return ctx$1;
    }
    var match = val.token;
    if (typeof match === "number") {
      if (match === 49) {
        return ctx$1;
      }
      if (match === 0) {
        return ParseContext$Poly.incrementPosition(ctx$1);
      }
      _ctx = ctx$1;
      continue ;
    }
    _ctx = ctx$1;
    continue ;
  };
}

function block(ctx, lbraceToken) {
  var _ctx = ctx;
  var _statements = /* [] */0;
  while(true) {
    var statements = _statements;
    var ctx$1 = _ctx;
    var rbraceToken = ParseContext$Poly.currentToken(ctx$1);
    if (rbraceToken === undefined) {
      return ParseError$Poly.pushError(ctx$1, {
                  TAG: /* STATEMENT_ERROR */2,
                  _0: {
                    TAG: /* EXPECTING_RBRACE */6,
                    lbraceToken: lbraceToken,
                    errorToken: undefined,
                    position: ParseContext$Poly.currentPosition(ctx$1)
                  }
                });
    }
    if (rbraceToken.token === 13) {
      return [
              ParseContext$Poly.incrementPosition(ctx$1),
              {
                lbraceToken: lbraceToken,
                rbraceToken: rbraceToken,
                statements: statements
              }
            ];
    }
    var match = statement(ctx$1);
    _statements = Pervasives.$at(statements, {
          hd: match[1],
          tl: /* [] */0
        });
    _ctx = match[0];
    continue ;
  };
}

function ifStatement(ctx, ifToken) {
  var match = Expression$Poly.expression(ctx);
  var expression = match[1];
  var ctx$1 = match[0];
  var lbraceToken = ParseContext$Poly.currentToken(ctx$1);
  if (lbraceToken !== undefined && lbraceToken.token === 12) {
    var match$1 = block(ParseContext$Poly.incrementPosition(ctx$1), lbraceToken);
    var b = match$1[1];
    var ctx$2 = match$1[0];
    var elseToken = ParseContext$Poly.currentToken(ctx$2);
    var exit = 0;
    if (elseToken !== undefined && elseToken.token === 45) {
      var ctx$3 = ParseContext$Poly.incrementPosition(ctx$2);
      var nestedIfToken = ParseContext$Poly.currentToken(ctx$3);
      var exit$1 = 0;
      if (nestedIfToken !== undefined) {
        var match$2 = nestedIfToken.token;
        if (typeof match$2 === "number") {
          if (match$2 !== 12) {
            if (match$2 !== 44) {
              exit$1 = 3;
            } else {
              var match$3 = ifStatement(ParseContext$Poly.incrementPosition(ctx$3), nestedIfToken);
              var elseStatement_1 = match$3[1];
              var elseStatement = {
                TAG: /* ELSE_IF */0,
                elseToken: elseToken,
                ifStatement: elseStatement_1
              };
              return [
                      match$3[0],
                      {
                        ifToken: ifToken,
                        expression: expression,
                        block: b,
                        elseStatement: elseStatement
                      }
                    ];
            }
          } else {
            var match$4 = block(ParseContext$Poly.incrementPosition(ctx$3), nestedIfToken);
            var elseStatement_1$1 = match$4[1];
            var elseStatement$1 = {
              TAG: /* ELSE */1,
              elseToken: elseToken,
              block: elseStatement_1$1
            };
            return [
                    match$4[0],
                    {
                      ifToken: ifToken,
                      expression: expression,
                      block: b,
                      elseStatement: elseStatement$1
                    }
                  ];
          }
        } else {
          exit$1 = 3;
        }
      } else {
        exit$1 = 3;
      }
      if (exit$1 === 3) {
        return ParseError$Poly.pushError(ctx$3, {
                    TAG: /* STATEMENT_ERROR */2,
                    _0: {
                      TAG: /* EXPECTING_IF_OR_BLOCK_AFTER_ELSE */8,
                      ifToken: ifToken,
                      elseToken: elseToken,
                      errorToken: nestedIfToken,
                      position: ParseContext$Poly.currentPosition(ctx$3)
                    }
                  });
      }
      
    } else {
      exit = 2;
    }
    if (exit === 2) {
      return [
              ctx$2,
              {
                ifToken: ifToken,
                expression: expression,
                block: b,
                elseStatement: undefined
              }
            ];
    }
    
  }
  return ParseError$Poly.pushError(ctx$1, {
              TAG: /* STATEMENT_ERROR */2,
              _0: {
                TAG: /* EXPECTING_BRACE_AFTER_IF */7,
                ifToken: ifToken,
                errorToken: lbraceToken,
                position: ParseContext$Poly.currentPosition(ctx$1)
              }
            });
}

function forInStatement(ctx, forToken) {
  var match = Expression$Poly.variableDeclaration(ctx);
  var ctx$1 = match[0];
  var inToken = ParseContext$Poly.currentToken(ctx$1);
  if (inToken !== undefined) {
    var match$1 = inToken.token;
    if (typeof match$1 === "number" && match$1 >= 52) {
      var ctx$2 = ParseContext$Poly.incrementPosition(ctx$1);
      var match$2 = Expression$Poly.expression(ctx$2);
      var ctx$3 = match$2[0];
      var lbraceToken = ParseContext$Poly.currentToken(ctx$3);
      var exit = 0;
      if (lbraceToken !== undefined) {
        if (lbraceToken.token === 12) {
          var match$3 = block(ParseContext$Poly.incrementPosition(ctx$3), lbraceToken);
          return [
                  match$3[0],
                  {
                    TAG: /* FOR_IN */4,
                    forToken: forToken,
                    identifier: match[1],
                    inToken: inToken,
                    expression: match$2[1],
                    block: match$3[1]
                  }
                ];
        }
        exit = 2;
      } else {
        exit = 2;
      }
      if (exit === 2) {
        return ParseError$Poly.pushError(ctx$3, {
                    TAG: /* STATEMENT_ERROR */2,
                    _0: {
                      TAG: /* EXPECTING_BRACE_AFTER_FOR_IN */10,
                      forToken: forToken,
                      inToken: inToken,
                      errorToken: lbraceToken
                    }
                  });
      }
      
    }
    
  }
  return ParseError$Poly.pushError(ctx$1, {
              TAG: /* STATEMENT_ERROR */2,
              _0: {
                TAG: /* EXPECTING_IN_TOKEN_FOR_IN */9,
                forToken: forToken,
                errorToken: inToken
              }
            });
}

function statement(ctx) {
  var exit = 0;
  var val;
  var val$1;
  try {
    val = ParseContext$Poly.currentToken(ctx);
    val$1 = ParseContext$Poly.nextToken(ctx);
    exit = 1;
  }
  catch (raw_ctx){
    var ctx$1 = Caml_js_exceptions.internalToOCamlException(raw_ctx);
    if (ctx$1.RE_EXN_ID === ParseError$Poly.ParseError) {
      return [
              synchronize(ctx$1._1),
              /* STATEMENT_ERROR */0
            ];
    }
    throw ctx$1;
  }
  if (exit === 1) {
    var exit$1 = 0;
    if (val !== undefined) {
      var exit$2 = 0;
      var tmp = val.token;
      if (typeof tmp === "number") {
        switch (tmp) {
          case /* LBRACE */12 :
              var exit$3 = 0;
              var val$2;
              try {
                val$2 = block(ParseContext$Poly.incrementPosition(ctx), val);
                exit$3 = 4;
              }
              catch (raw_ctx$1){
                var ctx$2 = Caml_js_exceptions.internalToOCamlException(raw_ctx$1);
                if (ctx$2.RE_EXN_ID === ParseError$Poly.ParseError) {
                  return [
                          synchronize(ctx$2._1),
                          /* STATEMENT_ERROR */0
                        ];
                }
                throw ctx$2;
              }
              if (exit$3 === 4) {
                return [
                        val$2[0],
                        {
                          TAG: /* BLOCK */5,
                          _0: val$2[1]
                        }
                      ];
              }
              break;
          case /* IF */44 :
              var exit$4 = 0;
              var val$3;
              try {
                val$3 = ifStatement(ParseContext$Poly.incrementPosition(ctx), val);
                exit$4 = 4;
              }
              catch (raw_ctx$2){
                var ctx$3 = Caml_js_exceptions.internalToOCamlException(raw_ctx$2);
                if (ctx$3.RE_EXN_ID === ParseError$Poly.ParseError) {
                  return [
                          synchronize(ctx$3._1),
                          /* STATEMENT_ERROR */0
                        ];
                }
                throw ctx$3;
              }
              if (exit$4 === 4) {
                return [
                        val$3[0],
                        {
                          TAG: /* IF */3,
                          _0: val$3[1]
                        }
                      ];
              }
              break;
          case /* FOR */46 :
              try {
                return forInStatement(ParseContext$Poly.incrementPosition(ctx), val);
              }
              catch (raw_ctx$3){
                var ctx$4 = Caml_js_exceptions.internalToOCamlException(raw_ctx$3);
                if (ctx$4.RE_EXN_ID === ParseError$Poly.ParseError) {
                  return [
                          synchronize(ctx$4._1),
                          /* STATEMENT_ERROR */0
                        ];
                }
                throw ctx$4;
              }
          case /* LET */49 :
              try {
                return declareStatement(ParseContext$Poly.incrementPosition(ctx), val);
              }
              catch (raw_ctx$4){
                var ctx$5 = Caml_js_exceptions.internalToOCamlException(raw_ctx$4);
                if (ctx$5.RE_EXN_ID === ParseError$Poly.ParseError) {
                  return [
                          synchronize(ctx$5._1),
                          /* STATEMENT_ERROR */0
                        ];
                }
                throw ctx$5;
              }
          default:
            exit$1 = 2;
        }
      } else {
        switch (tmp.TAG | 0) {
          case /* IDENTIFIER */0 :
          case /* TYPECLASS */2 :
              exit$2 = 3;
              break;
          default:
            exit$1 = 2;
        }
      }
      if (exit$2 === 3) {
        if (val$1 !== undefined && val$1.token === 16) {
          var match = Expression$Poly.variable(ctx);
          try {
            return reassignStatement(match[0], match[1]);
          }
          catch (raw_ctx$5){
            var ctx$6 = Caml_js_exceptions.internalToOCamlException(raw_ctx$5);
            if (ctx$6.RE_EXN_ID === ParseError$Poly.ParseError) {
              return [
                      synchronize(ctx$6._1),
                      /* STATEMENT_ERROR */0
                    ];
            }
            throw ctx$6;
          }
        } else {
          exit$1 = 2;
        }
      }
      
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      try {
        return expressionStatement(ctx);
      }
      catch (raw_ctx$6){
        var ctx$7 = Caml_js_exceptions.internalToOCamlException(raw_ctx$6);
        if (ctx$7.RE_EXN_ID === ParseError$Poly.ParseError) {
          return [
                  synchronize(ctx$7._1),
                  /* STATEMENT_ERROR */0
                ];
        }
        throw ctx$7;
      }
    }
    
  }
  
}

exports.declareStatement = declareStatement;
exports.reassignStatement = reassignStatement;
exports.expressionStatement = expressionStatement;
exports.synchronize = synchronize;
exports.block = block;
exports.ifStatement = ifStatement;
exports.forInStatement = forInStatement;
exports.statement = statement;
/* No side effect */
