'use strict';

var List = require("rescript/lib/js/list.js");
var Token$Poly = require("./Token.bs.js");
var Utils$Poly = require("../utils/Utils.bs.js");
var Position$Poly = require("../position/Position.bs.js");

function tokenToString(token) {
  return Utils$Poly.dictToString({
              hd: [
                "token",
                Token$Poly.toString(token.token)
              ],
              tl: {
                hd: [
                  "range",
                  Position$Poly.rangeToString(token.range)
                ],
                tl: /* [] */0
              }
            });
}

function tokenListToString(tokens) {
  return Utils$Poly.listToString(List.map(tokenToString, tokens));
}

function optionTokenToString(token) {
  return Utils$Poly.optionToString(token, tokenToString);
}

exports.tokenToString = tokenToString;
exports.tokenListToString = tokenListToString;
exports.optionTokenToString = optionTokenToString;
/* No side effect */
