'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Operator$Poly = require("./Operator.bs.js");
var ParseError$Poly = require("./ParseError.bs.js");
var ParseConsume$Poly = require("./ParseConsume.bs.js");
var ParseContext$Poly = require("./ParseContext.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

function advance(ctx, ast) {
  return [
          ParseContext$Poly.incrementPosition(ctx),
          ast
        ];
}

function leftAssociativeBinary(ctx, tokenOpMapping, higherPrecedenceFn) {
  var matchTokens = List.map((function (t) {
          return t[0];
        }), tokenOpMapping);
  var match = Curry._1(higherPrecedenceFn, ctx);
  var _ctx = match[0];
  var _left = match[1];
  while(true) {
    var left = _left;
    var ctx$1 = _ctx;
    var match$1 = ParseConsume$Poly.match_(ctx$1, matchTokens);
    if (typeof match$1 === "number") {
      return [
              ctx$1,
              left
            ];
    }
    var token = match$1._1;
    var match$2 = Curry._1(higherPrecedenceFn, match$1._0);
    var ctx$2 = match$2[0];
    var val;
    try {
      val = List.find((function(token){
          return function (param) {
            return Caml_obj.caml_equal(token.token, param[0]);
          }
          }(token)), tokenOpMapping);
    }
    catch (raw_exn){
      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
      if (exn.RE_EXN_ID === "Not_found") {
        return ParseError$Poly.pushError(ctx$2, {
                    TAG: /* EXPRESSION_ERROR */1,
                    _0: {
                      TAG: /* SHOULD_NOT_HAPPEN */21,
                      tokens: {
                        hd: token,
                        tl: /* [] */0
                      }
                    }
                  });
      }
      throw exn;
    }
    var expr = Operator$Poly.expressionForBinaryOp(val[1], left, match$2[1], token);
    _left = expr;
    _ctx = ctx$2;
    continue ;
  };
}

function rightAssociativeBinary(ctx, tokenOpMapping, higherPrecedenceFn) {
  var matchTokens = List.map((function (t) {
          return t[0];
        }), tokenOpMapping);
  var match = Curry._1(higherPrecedenceFn, ctx);
  var _samePrecedenceFn = function (ctx, left) {
    var match = ParseConsume$Poly.match_(ctx, matchTokens);
    if (typeof match === "number") {
      return [
              ctx,
              left
            ];
    }
    var token = match._1;
    var match$1 = Curry._1(higherPrecedenceFn, match._0);
    var ctx$1 = match$1[0];
    var val;
    try {
      val = List.find((function (param) {
              return Caml_obj.caml_equal(token.token, param[0]);
            }), tokenOpMapping);
    }
    catch (raw_exn){
      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
      if (exn.RE_EXN_ID === "Not_found") {
        return ParseError$Poly.pushError(ctx$1, {
                    TAG: /* EXPRESSION_ERROR */1,
                    _0: {
                      TAG: /* SHOULD_NOT_HAPPEN */21,
                      tokens: {
                        hd: token,
                        tl: /* [] */0
                      }
                    }
                  });
      }
      throw exn;
    }
    var match$2 = _samePrecedenceFn(ctx$1, match$1[1]);
    return [
            match$2[0],
            Operator$Poly.expressionForBinaryOp(val[1], left, match$2[1], token)
          ];
  };
  return _samePrecedenceFn(match[0], match[1]);
}

function rightAssociativeUnary(ctx, tokenOpMapping, higherPrecedenceFn) {
  var matchTokens = List.map((function (t) {
          return t[0];
        }), tokenOpMapping);
  var _samePrecedenceFn = function (ctx) {
    var match = ParseConsume$Poly.match_(ctx, matchTokens);
    if (typeof match === "number") {
      return Curry._1(higherPrecedenceFn, ctx);
    }
    var token = match._1;
    var match$1 = _samePrecedenceFn(match._0);
    var ctx$1 = match$1[0];
    var val;
    try {
      val = List.find((function (param) {
              return Caml_obj.caml_equal(token.token, param[0]);
            }), tokenOpMapping);
    }
    catch (raw_exn){
      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
      if (exn.RE_EXN_ID === "Not_found") {
        return ParseError$Poly.pushError(ctx$1, {
                    TAG: /* EXPRESSION_ERROR */1,
                    _0: {
                      TAG: /* SHOULD_NOT_HAPPEN */21,
                      tokens: {
                        hd: token,
                        tl: /* [] */0
                      }
                    }
                  });
      }
      throw exn;
    }
    return [
            ctx$1,
            Operator$Poly.expressionForUnaryOp(val[1], match$1[1], token)
          ];
  };
  return _samePrecedenceFn(ctx);
}

exports.advance = advance;
exports.leftAssociativeBinary = leftAssociativeBinary;
exports.rightAssociativeBinary = rightAssociativeBinary;
exports.rightAssociativeUnary = rightAssociativeUnary;
/* No side effect */
