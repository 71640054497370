'use strict';

var Utils$Poly = require("../utils/Utils.bs.js");

function positionToString(position) {
  return Utils$Poly.dictToString({
              hd: [
                "line",
                String(position.line)
              ],
              tl: {
                hd: [
                  "col",
                  String(position.col)
                ],
                tl: {
                  hd: [
                    "char",
                    String(position.char)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function rangeToString(range) {
  if (range.TAG === /* POINT */0) {
    return Utils$Poly.variantToString("POINT", {
                hd: positionToString(range._0),
                tl: /* [] */0
              });
  } else {
    return Utils$Poly.variantToString("RANGE", {
                hd: positionToString(range._0),
                tl: {
                  hd: positionToString(range._1),
                  tl: /* [] */0
                }
              });
  }
}

function startPos(range) {
  return range._0;
}

function endPos(range) {
  if (range.TAG === /* POINT */0) {
    return range._0;
  } else {
    return range._1;
  }
}

function startChar(range) {
  return range._0.char;
}

function endChar(range) {
  if (range.TAG === /* POINT */0) {
    return range._0.char;
  } else {
    return range._1.char;
  }
}

var beginningPosition = {
  line: 0,
  col: 0,
  char: 0
};

var zero = {
  TAG: /* POINT */0,
  _0: beginningPosition
};

function advanceLine(position, advance) {
  return {
          line: position.line + advance | 0,
          col: 0,
          char: position.char + advance | 0
        };
}

function advanceCol(position, advance) {
  return {
          line: position.line,
          col: position.col + advance | 0,
          char: position.char + advance | 0
        };
}

function advanceText(position, text) {
  var _position = position;
  var _i = 0;
  while(true) {
    var i = _i;
    var position$1 = _position;
    var match = Utils$Poly.getChar(text, i);
    if (match === undefined) {
      return position$1;
    }
    if (match !== 10) {
      _i = i + 1 | 0;
      _position = advanceCol(position$1, 1);
      continue ;
    }
    _i = i + 1 | 0;
    _position = advanceLine(position$1, 1);
    continue ;
  };
}

exports.positionToString = positionToString;
exports.rangeToString = rangeToString;
exports.startPos = startPos;
exports.endPos = endPos;
exports.startChar = startChar;
exports.endChar = endChar;
exports.beginningPosition = beginningPosition;
exports.zero = zero;
exports.advanceLine = advanceLine;
exports.advanceCol = advanceCol;
exports.advanceText = advanceText;
/* No side effect */
