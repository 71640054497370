'use strict';

var List = require("rescript/lib/js/list.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var ParseContext$Poly = require("./ParseContext.bs.js");

function advance(ctx) {
  var token = ParseContext$Poly.currentToken(ctx);
  if (token !== undefined) {
    return /* SUCCESS */{
            _0: token,
            _1: ParseContext$Poly.incrementPosition(ctx)
          };
  } else {
    return /* END_OF_FILE */0;
  }
}

function expect(ctx, token) {
  var t = ParseContext$Poly.currentToken(ctx);
  if (t !== undefined) {
    if (Caml_obj.caml_equal(t.token, token)) {
      return /* AS_EXPECTED */{
              _0: t
            };
    } else {
      return /* AS_UNEXPECTED */0;
    }
  } else {
    return /* END_OF_FILE */1;
  }
}

function match_(ctx, expectedTokens) {
  var token = ParseContext$Poly.currentToken(ctx);
  if (token !== undefined) {
    if (List.mem(token.token, expectedTokens)) {
      return /* MATCH */{
              _0: ParseContext$Poly.incrementPosition(ctx),
              _1: token
            };
    } else {
      return /* NO_MATCH */0;
    }
  } else {
    return /* END_OF_FILE */1;
  }
}

exports.advance = advance;
exports.expect = expect;
exports.match_ = match_;
/* No side effect */
