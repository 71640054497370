'use strict';


function diagnosticLevelToString(diagnosticLevel) {
  if (diagnosticLevel) {
    return "WARNING";
  } else {
    return "ERROR";
  }
}

exports.diagnosticLevelToString = diagnosticLevelToString;
/* No side effect */
