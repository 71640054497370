'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var $$String = require("rescript/lib/js/string.js");
var Char$Poly = require("../utils/Char.bs.js");
var Code$Poly = require("../code/Code.bs.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Position$Poly = require("../position/Position.bs.js");
var LexConsume$Poly = require("./LexConsume.bs.js");
var LexContext$Poly = require("./LexContext.bs.js");

function textLex(ctx) {
  var _ctx = ctx;
  var _consumed = "";
  var originalPosition = ctx.position;
  while(true) {
    var consumed = _consumed;
    var ctx$1 = _ctx;
    var pushConsumed = (function(ctx$1,consumed){
    return function pushConsumed(param) {
      var match = consumed.length;
      if (match !== 0) {
        return {
                source: ctx$1.source,
                indexedText: ctx$1.indexedText,
                position: ctx$1.position,
                lexMode: ctx$1.lexMode,
                errors: ctx$1.errors,
                tokens: Pervasives.$at(ctx$1.tokens, {
                      hd: {
                        token: {
                          TAG: /* TEXT */3,
                          _0: consumed
                        },
                        text: consumed,
                        range: {
                          TAG: /* RANGE */1,
                          _0: originalPosition,
                          _1: ctx$1.position
                        }
                      },
                      tl: /* [] */0
                    }),
                quoteStack: ctx$1.quoteStack
              };
      } else {
        return ctx$1;
      }
    }
    }(ctx$1,consumed));
    var match = LexContext$Poly.currentChar(ctx$1);
    var match$1 = LexContext$Poly.currentQuote(ctx$1);
    if (match !== undefined) {
      if (match >= 35) {
        if (match !== 92) {
          if (match === 96 && match$1) {
            var ctx$2 = pushConsumed(undefined);
            return regularLex(LexContext$Poly.popQuoteStack(LexContext$Poly.pushSimpleToken(ctx$2, /* TICK */7, "`")), 0);
          }
          
        } else {
          var c = LexContext$Poly.nextChar(ctx$1);
          if (c === undefined) {
            return textLex(LexContext$Poly.pushError(ctx$1, /* UNTERMINATED_ESCAPE */1, LexContext$Poly.endPosition(ctx$1)));
          }
          var exit = 0;
          if (c !== 34) {
            if (c >= 91) {
              if (c >= 117) {
                exit = 2;
              } else {
                switch (c) {
                  case 91 :
                      var ctx$3 = pushConsumed(undefined);
                      return regularLex(LexContext$Poly.pushSimpleToken(ctx$3, /* INTERP_START */8, "\\["), 0);
                  case 92 :
                      _consumed = consumed + "\\";
                      _ctx = LexContext$Poly.advanceCol(ctx$1, 2);
                      continue ;
                  case 96 :
                      _consumed = consumed + "`";
                      _ctx = LexContext$Poly.advanceCol(ctx$1, 2);
                      continue ;
                  case 98 :
                      _consumed = consumed + "\b";
                      _ctx = LexContext$Poly.advanceCol(ctx$1, 2);
                      continue ;
                  case 110 :
                      _consumed = consumed + "\n";
                      _ctx = LexContext$Poly.advanceCol(ctx$1, 2);
                      continue ;
                  case 114 :
                      _consumed = consumed + "\r";
                      _ctx = LexContext$Poly.advanceCol(ctx$1, 2);
                      continue ;
                  case 93 :
                  case 94 :
                  case 95 :
                  case 97 :
                  case 99 :
                  case 100 :
                  case 101 :
                  case 102 :
                  case 103 :
                  case 104 :
                  case 105 :
                  case 106 :
                  case 107 :
                  case 108 :
                  case 109 :
                  case 111 :
                  case 112 :
                  case 113 :
                  case 115 :
                      exit = 2;
                      break;
                  case 116 :
                      _consumed = consumed + "\t";
                      _ctx = LexContext$Poly.advanceCol(ctx$1, 2);
                      continue ;
                  
                }
              }
            } else if (c !== 39) {
              exit = 2;
            } else {
              _consumed = consumed + "'";
              _ctx = LexContext$Poly.advanceCol(ctx$1, 2);
              continue ;
            }
          } else {
            _consumed = consumed + "\"";
            _ctx = LexContext$Poly.advanceCol(ctx$1, 2);
            continue ;
          }
          if (exit === 2) {
            return textLex(LexContext$Poly.pushError(ctx$1, {
                            TAG: /* ILLEGAL_ESCAPE */1,
                            _0: c
                          }, Position$Poly.advanceText(ctx$1.position, "\\" + $$String.make(1, c))));
          }
          
        }
      } else if (match !== 10) {
        if (match >= 34 && !match$1) {
          var ctx$4 = pushConsumed(undefined);
          return regularLex(LexContext$Poly.popQuoteStack(LexContext$Poly.pushSimpleToken(ctx$4, /* QUOTE */6, "\"")), 0);
        }
        
      } else if (!match$1) {
        var ctx$5 = pushConsumed(undefined);
        var ctx$6 = LexContext$Poly.pushError(ctx$5, {
              TAG: /* NEWLINE_IN_QUOTE */0,
              _0: {
                TAG: /* POINT */0,
                _0: ctx$5.position
              }
            }, ctx$5.position);
        return regularLex(ctx$6, 0);
      }
      var s = $$String.make(1, match);
      _consumed = consumed + s;
      _ctx = LexContext$Poly.advanceText(ctx$1, s);
      continue ;
    }
    var ctx$7 = pushConsumed(undefined);
    return regularLex(ctx$7, 0);
  };
}

function regularLex(_ctx, interpBracketCount) {
  while(true) {
    var ctx = _ctx;
    var ctx$1 = LexConsume$Poly.whitespace(ctx);
    var _lex = (function(ctx$1){
    return function _lex(token, text) {
      return regularLex(LexContext$Poly.pushSimpleToken(ctx$1, token, text), interpBracketCount);
    }
    }(ctx$1));
    var _error = function (ctx, error, endPosition) {
      return regularLex(LexContext$Poly.pushError(ctx, error, endPosition), interpBracketCount);
    };
    var _lexBracket = (function(ctx$1){
    return function _lexBracket(token, text, interpBracketOffset) {
      return regularLex(LexContext$Poly.pushSimpleToken(ctx$1, token, text), interpBracketCount + interpBracketOffset | 0);
    }
    }(ctx$1));
    var c = LexContext$Poly.currentChar(ctx$1);
    if (c === undefined) {
      return ctx$1;
    }
    var exit = 0;
    if (c >= 97) {
      switch (c) {
        case 123 :
            return _lex(/* LBRACE */12, "{");
        case 124 :
            var match = LexContext$Poly.nextChar(ctx$1);
            if (match !== undefined) {
              if (match !== 61) {
                if (match !== 124) {
                  return _lex(/* BITOR */36, "|");
                } else {
                  return _lex(/* OR */37, "||");
                }
              } else {
                return _lex(/* OR_EQUALS */38, "|=");
              }
            } else {
              return _lex(/* BITOR */36, "|");
            }
        case 125 :
            return _lex(/* RBRACE */13, "}");
        default:
          exit = 1;
      }
    } else if (c >= 33) {
      switch (c) {
        case 33 :
            var match$1 = LexContext$Poly.nextChar(ctx$1);
            if (match$1 === 61) {
              return _lex(/* UNEQUALS */26, "!=");
            } else {
              return _lex(/* NOT */25, "!");
            }
        case 34 :
            return textLex(LexContext$Poly.pushQuoteStack(LexContext$Poly.pushSimpleToken(ctx$1, /* QUOTE */6, "\""), /* QUOTE */0));
        case 38 :
            var match$2 = LexContext$Poly.nextChar(ctx$1);
            if (match$2 !== undefined) {
              if (match$2 !== 38) {
                if (match$2 !== 61) {
                  return _lex(/* BITAND */39, "&");
                } else {
                  return _lex(/* AND_EQUALS */41, "&=");
                }
              } else {
                return _lex(/* AND */40, "&&");
              }
            } else {
              return _lex(/* BITAND */39, "&");
            }
        case 40 :
            return _lex(/* LPAREN */10, "(");
        case 41 :
            return _lex(/* RPAREN */11, ")");
        case 42 :
            var match$3 = LexContext$Poly.nextChar(ctx$1);
            if (match$3 !== undefined) {
              if (match$3 !== 42) {
                if (match$3 !== 61) {
                  return _lex(/* MULTIPLY */19, "*");
                } else {
                  return _lex(/* MULTIPLY_EQUALS */31, "*=");
                }
              } else {
                return _lex(/* POWER */23, "**");
              }
            } else {
              return _lex(/* MULTIPLY */19, "*");
            }
        case 43 :
            var match$4 = LexContext$Poly.nextChar(ctx$1);
            if (match$4 !== undefined) {
              if (match$4 !== 43) {
                if (match$4 !== 61) {
                  return _lex(/* PLUS */17, "+");
                } else {
                  return _lex(/* PLUS_EQUALS */29, "+=");
                }
              } else {
                return _lex(/* INCREMENT */27, "++");
              }
            } else {
              return _lex(/* PLUS */17, "+");
            }
        case 44 :
            return _lex(/* COMMA */2, ",");
        case 45 :
            var match$5 = LexContext$Poly.nextChar(ctx$1);
            if (match$5 !== undefined) {
              if (match$5 !== 45) {
                if (match$5 !== 61) {
                  return _lex(/* MINUS */18, "-");
                } else {
                  return _lex(/* MINUS_EQUALS */30, "-=");
                }
              } else {
                return _lex(/* DECREMENT */28, "--");
              }
            } else {
              return _lex(/* MINUS */18, "-");
            }
        case 46 :
            var c$1 = LexContext$Poly.nextChar(ctx$1);
            if (c$1 === undefined) {
              return _lex(/* DOT */4, ".");
            }
            if (c$1 !== 46) {
              if (!Char$Poly.isNumeric(c$1)) {
                return _lex(/* DOT */4, ".");
              }
              var s = LexConsume$Poly.peakNumber(ctx$1);
              if (!s) {
                return _lex(/* DOT */4, ".");
              }
              var s$1 = s._0;
              return _lex({
                          TAG: /* NUMBER */1,
                          _0: s$1
                        }, s$1);
            }
            var match$6 = LexContext$Poly.getCharOffset(ctx$1, 2);
            if (match$6 === 46) {
              return _error(ctx$1, /* THREE_DOTS */0, Position$Poly.advanceText(ctx$1.position, "..."));
            } else {
              return _lex(/* RANGE */5, "..");
            }
        case 47 :
            var match$7 = LexContext$Poly.nextChar(ctx$1);
            if (match$7 === undefined) {
              return _lex(/* DIVIDE */20, "/");
            }
            if (match$7 !== 47) {
              if (match$7 !== 61) {
                return _lex(/* DIVIDE */20, "/");
              } else {
                return _lex(/* DIVIDE_EQUALS */32, "/=");
              }
            }
            _ctx = LexConsume$Poly.line(ctx$1);
            continue ;
        case 58 :
            return _lex(/* COLON */3, ":");
        case 59 :
            return _lex(/* SEMICOLON */0, ";");
        case 60 :
            var match$8 = LexContext$Poly.nextChar(ctx$1);
            if (match$8 === 61) {
              return _lex(/* LESSER_EQUALS */34, "<=");
            } else {
              return _lex(/* LESSER */22, "<");
            }
        case 61 :
            var match$9 = LexContext$Poly.nextChar(ctx$1);
            if (match$9 !== undefined) {
              if (match$9 !== 61) {
                if (match$9 !== 62) {
                  return _lex(/* ASSIGN */16, "=");
                } else {
                  return _lex(/* ARROW */35, "=>");
                }
              } else {
                return _lex(/* EQUALS */24, "==");
              }
            } else {
              return _lex(/* ASSIGN */16, "=");
            }
        case 62 :
            var match$10 = LexContext$Poly.nextChar(ctx$1);
            if (match$10 === 61) {
              return _lex(/* GREATER_EQUALS */33, ">=");
            } else {
              return _lex(/* GREATER */21, ">");
            }
        case 91 :
            return _lexBracket(/* LBRACKET */14, "[", 1);
        case 93 :
            if (List.length(ctx$1.quoteStack) > 0 && interpBracketCount === 0) {
              return textLex(LexContext$Poly.pushSimpleToken(ctx$1, /* INTERP_END */9, "]"));
            } else {
              return _lexBracket(/* RBRACKET */15, "]", -1);
            }
        case 94 :
            var match$11 = LexContext$Poly.nextChar(ctx$1);
            if (match$11 === 61) {
              return _lex(/* XOR_EQUALS */43, "^=");
            } else {
              return _lex(/* XOR */42, "^");
            }
        case 35 :
        case 36 :
        case 37 :
        case 39 :
        case 48 :
        case 49 :
        case 50 :
        case 51 :
        case 52 :
        case 53 :
        case 54 :
        case 55 :
        case 56 :
        case 57 :
        case 63 :
        case 64 :
        case 65 :
        case 66 :
        case 67 :
        case 68 :
        case 69 :
        case 70 :
        case 71 :
        case 72 :
        case 73 :
        case 74 :
        case 75 :
        case 76 :
        case 77 :
        case 78 :
        case 79 :
        case 80 :
        case 81 :
        case 82 :
        case 83 :
        case 84 :
        case 85 :
        case 86 :
        case 87 :
        case 88 :
        case 89 :
        case 90 :
        case 92 :
        case 95 :
            exit = 1;
            break;
        case 96 :
            return textLex(LexContext$Poly.pushQuoteStack(LexContext$Poly.pushSimpleToken(ctx$1, /* TICK */7, "`"), /* TICK */1));
        
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      var match$12 = LexConsume$Poly.peakVariable(ctx$1);
      var exit$1 = 0;
      if (match$12 !== undefined && typeof match$12 !== "number") {
        switch (match$12.TAG | 0) {
          case /* IDENTIFIER */0 :
              var s$2 = match$12._0;
              switch (s$2) {
                case "class" :
                    return _lex(/* CLASS */51, "class");
                case "do" :
                    return _lex(/* DO */48, "do");
                case "else" :
                    return _lex(/* ELSE */45, "else");
                case "for" :
                    return _lex(/* FOR */46, "for");
                case "if" :
                    return _lex(/* IF */44, "if");
                case "in" :
                    return _lex(/* IN */52, "in");
                case "let" :
                    return _lex(/* LET */49, "let");
                case "type" :
                    return _lex(/* TYPE */50, "type");
                case "while" :
                    return _lex(/* WHILE */47, "while");
                default:
                  return _lex({
                              TAG: /* IDENTIFIER */0,
                              _0: s$2
                            }, s$2);
              }
          case /* TYPECLASS */2 :
              var s$3 = match$12._0;
              return _lex({
                          TAG: /* TYPECLASS */2,
                          _0: s$3
                        }, s$3);
          default:
            exit$1 = 2;
        }
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        var s$4 = LexConsume$Poly.peakNumber(ctx$1);
        if (!s$4) {
          return _error(ctx$1, {
                      TAG: /* UNKNOWN_CHARACTER */2,
                      _0: c
                    }, Position$Poly.advanceText(ctx$1.position, $$String.make(1, c)));
        }
        var s$5 = s$4._0;
        return _lex({
                    TAG: /* NUMBER */1,
                    _0: s$5
                  }, s$5);
      }
      
    }
    
  };
}

function lex(ctx) {
  return regularLex(ctx, 0);
}

function lexSimpleProgram(source) {
  return lex(LexContext$Poly.initContext(source));
}

function lexProgram(source) {
  if (source.TAG === /* FILES */0) {
    return {
            TAG: /* FILES */0,
            _0: Curry._2(Code$Poly.StringMap.map, (function (source) {
                    return lex(LexContext$Poly.initContext(source));
                  }), source._0)
          };
  } else {
    return {
            TAG: /* SIMPLE_LEX */1,
            _0: lex(LexContext$Poly.initContext(source._0))
          };
  }
}

exports.textLex = textLex;
exports.regularLex = regularLex;
exports.lex = lex;
exports.lexSimpleProgram = lexSimpleProgram;
exports.lexProgram = lexProgram;
/* Code-Poly Not a pure module */
