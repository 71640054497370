'use strict';

var $$String = require("rescript/lib/js/string.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Utils$Poly = require("../utils/Utils.bs.js");
var Position$Poly = require("../position/Position.bs.js");
var IndexedText$Poly = require("../code/IndexedText.bs.js");

function initContext(source) {
  return {
          source: source,
          indexedText: IndexedText$Poly.fromString(source),
          position: Position$Poly.beginningPosition,
          lexMode: /* REGULAR */0,
          errors: /* [] */0,
          tokens: /* [] */0,
          quoteStack: /* [] */0
        };
}

function currentChar(ctx) {
  return Utils$Poly.getChar(ctx.source, ctx.position.char);
}

function nextChar(ctx) {
  return Utils$Poly.getChar(ctx.source, ctx.position.char + 1 | 0);
}

function getCharOffset(ctx, offset) {
  return Utils$Poly.getChar(ctx.source, ctx.position.char + offset | 0);
}

function currentString(ctx) {
  return $$String.sub(ctx.source, ctx.position.char, ctx.source.length - ctx.position.char | 0);
}

function advanceLine(ctx, advance) {
  return {
          source: ctx.source,
          indexedText: ctx.indexedText,
          position: Position$Poly.advanceLine(ctx.position, advance),
          lexMode: ctx.lexMode,
          errors: ctx.errors,
          tokens: ctx.tokens,
          quoteStack: ctx.quoteStack
        };
}

function advanceCol(ctx, advance) {
  return {
          source: ctx.source,
          indexedText: ctx.indexedText,
          position: Position$Poly.advanceCol(ctx.position, advance),
          lexMode: ctx.lexMode,
          errors: ctx.errors,
          tokens: ctx.tokens,
          quoteStack: ctx.quoteStack
        };
}

function endPosition(ctx) {
  return Position$Poly.advanceText(ctx.position, Utils$Poly.substrToEnd(ctx.source, ctx.position.char));
}

function advanceText(ctx, text) {
  var _ctx = ctx;
  var _i = 0;
  while(true) {
    var i = _i;
    var ctx$1 = _ctx;
    var match = Utils$Poly.getChar(text, i);
    if (match === undefined) {
      return ctx$1;
    }
    if (match !== 10) {
      _i = i + 1 | 0;
      _ctx = advanceCol(ctx$1, 1);
      continue ;
    }
    _i = i + 1 | 0;
    _ctx = advanceLine(ctx$1, 1);
    continue ;
  };
}

function pushSimpleToken(ctx, token, text) {
  var startPosition = ctx.position;
  var endPosition = Position$Poly.advanceCol(ctx.position, text.length);
  var newToken_range = {
    TAG: /* RANGE */1,
    _0: startPosition,
    _1: endPosition
  };
  var newToken = {
    token: token,
    text: text,
    range: newToken_range
  };
  return {
          source: ctx.source,
          indexedText: ctx.indexedText,
          position: endPosition,
          lexMode: ctx.lexMode,
          errors: ctx.errors,
          tokens: Pervasives.$at(ctx.tokens, {
                hd: newToken,
                tl: /* [] */0
              }),
          quoteStack: ctx.quoteStack
        };
}

function pushComplexToken(ctx, token, text) {
  var startPosition = ctx.position;
  var endPosition = Position$Poly.advanceText(ctx.position, text);
  var newToken_range = {
    TAG: /* RANGE */1,
    _0: startPosition,
    _1: endPosition
  };
  var newToken = {
    token: token,
    text: text,
    range: newToken_range
  };
  return {
          source: ctx.source,
          indexedText: ctx.indexedText,
          position: endPosition,
          lexMode: ctx.lexMode,
          errors: ctx.errors,
          tokens: Pervasives.$at(ctx.tokens, {
                hd: newToken,
                tl: /* [] */0
              }),
          quoteStack: ctx.quoteStack
        };
}

function pushErrorRange(ctx, error, range, endPosition) {
  var startRangePosition = Position$Poly.startPos(range);
  var endRangePosition = Position$Poly.endPos(range);
  var error$1 = {
    error: error,
    range: range
  };
  var errorToken_token = {
    TAG: /* ERROR */4,
    _0: error$1
  };
  var errorToken_text = $$String.sub(ctx.source, startRangePosition.char, endRangePosition.char - startRangePosition.char | 0);
  var errorToken = {
    token: errorToken_token,
    text: errorToken_text,
    range: range
  };
  return {
          source: ctx.source,
          indexedText: ctx.indexedText,
          position: endPosition,
          lexMode: ctx.lexMode,
          errors: Pervasives.$at(ctx.errors, {
                hd: error$1,
                tl: /* [] */0
              }),
          tokens: Pervasives.$at(ctx.tokens, {
                hd: errorToken,
                tl: /* [] */0
              }),
          quoteStack: ctx.quoteStack
        };
}

function pushError(ctx, error, endPosition) {
  var startPosition = ctx.position;
  var range = {
    TAG: /* RANGE */1,
    _0: startPosition,
    _1: endPosition
  };
  return pushErrorRange(ctx, error, range, endPosition);
}

function pushQuoteStack(ctx, quote) {
  return {
          source: ctx.source,
          indexedText: ctx.indexedText,
          position: ctx.position,
          lexMode: ctx.lexMode,
          errors: ctx.errors,
          tokens: ctx.tokens,
          quoteStack: Pervasives.$at(ctx.quoteStack, {
                hd: quote,
                tl: /* [] */0
              })
        };
}

function popQuoteStack(ctx) {
  return {
          source: ctx.source,
          indexedText: ctx.indexedText,
          position: ctx.position,
          lexMode: ctx.lexMode,
          errors: ctx.errors,
          tokens: ctx.tokens,
          quoteStack: Utils$Poly.pop(ctx.quoteStack)
        };
}

function currentQuote(ctx) {
  var c = Utils$Poly.last(ctx.quoteStack);
  if (c !== undefined) {
    return c;
  } else {
    return /* QUOTE */0;
  }
}

exports.initContext = initContext;
exports.currentChar = currentChar;
exports.nextChar = nextChar;
exports.getCharOffset = getCharOffset;
exports.currentString = currentString;
exports.advanceLine = advanceLine;
exports.advanceCol = advanceCol;
exports.endPosition = endPosition;
exports.advanceText = advanceText;
exports.pushSimpleToken = pushSimpleToken;
exports.pushComplexToken = pushComplexToken;
exports.pushErrorRange = pushErrorRange;
exports.pushError = pushError;
exports.pushQuoteStack = pushQuoteStack;
exports.popQuoteStack = popQuoteStack;
exports.currentQuote = currentQuote;
/* No side effect */
