'use strict';

var $$String = require("rescript/lib/js/string.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Utils$Poly = require("../utils/Utils.bs.js");
var Position$Poly = require("../position/Position.bs.js");
var Diagnostic$Poly = require("./Diagnostic.bs.js");
var IndexedText$Poly = require("../code/IndexedText.bs.js");

function getLine(level, code, startPos, endPos) {
  var accentColor = level ? /* YELLOW */2 : /* RED */1;
  var firstLine = startPos.line;
  var lastLine = endPos.line;
  var numLines = (lastLine - firstLine | 0) + 1 | 0;
  var maxLineColWidth = Utils$Poly.intLength(lastLine + 1 | 0);
  var getLines = function (_line, _blocks) {
    while(true) {
      var blocks = _blocks;
      var line = _line;
      if (numLines > 4 && line >= (firstLine + 2 | 0) && line <= (lastLine - 2 | 0)) {
        _blocks = Pervasives.$at(blocks, {
              hd: [
                line === (firstLine + 2 | 0) ? "\n" + $$String.make(maxLineColWidth + 1 | 0, /* '.' */46) : "",
                /* NORMAL */0
              ],
              tl: /* [] */0
            });
        _line = line + 1 | 0;
        continue ;
      }
      var newlineBlock = line === firstLine ? /* [] */0 : ({
            hd: [
              "\n",
              /* NORMAL */0
            ],
            tl: /* [] */0
          });
      var lineColWidth = Utils$Poly.intLength(line + 1 | 0);
      var colContents_0 = [
        String(line + 1 | 0) + ($$String.make(maxLineColWidth - lineColWidth | 0, /* ' ' */32) + "| "),
        /* NORMAL */0
      ];
      var colContents = {
        hd: colContents_0,
        tl: /* [] */0
      };
      var x = Utils$Poly.index(code.lines, line);
      var fullLineContents = x !== undefined ? x : "";
      var preline = line === firstLine ? ({
            hd: [
              Utils$Poly.preline(fullLineContents, startPos.col - 1 | 0),
              /* NORMAL */0
            ],
            tl: /* [] */0
          }) : /* [] */0;
      var postline = line === lastLine ? ({
            hd: [
              Utils$Poly.postline(fullLineContents, endPos.col),
              /* NORMAL */0
            ],
            tl: /* [] */0
          }) : /* [] */0;
      var contents = line === firstLine && line === lastLine ? $$String.sub(fullLineContents, startPos.col, endPos.col - startPos.col | 0) : (
          line === firstLine ? $$String.sub(fullLineContents, startPos.col, fullLineContents.length - startPos.col | 0) : (
              line === lastLine ? $$String.sub(fullLineContents, 0, endPos.col) : fullLineContents
            )
        );
      var contentsBlock = contents === "" ? /* [] */0 : ({
            hd: [
              contents,
              accentColor
            ],
            tl: /* [] */0
          });
      var blocks$1 = Pervasives.$at(blocks, Pervasives.$at(newlineBlock, Pervasives.$at(colContents, Pervasives.$at(preline, Pervasives.$at(contentsBlock, postline)))));
      if (line === lastLine) {
        return blocks$1;
      }
      _blocks = blocks$1;
      _line = line + 1 | 0;
      continue ;
    };
  };
  var downArrows;
  if (startPos.line === endPos.line) {
    downArrows = /* [] */0;
  } else {
    var startDownArrows = startPos.col;
    var endDownArrows = IndexedText$Poly.lineLength(code, startPos.line);
    var spacingDownBlock_0 = $$String.make((maxLineColWidth + 2 | 0) + startDownArrows | 0, /* ' ' */32);
    var spacingDownBlock = [
      spacingDownBlock_0,
      /* NORMAL */0
    ];
    var downArrows_0 = $$String.make(endDownArrows - startDownArrows | 0, /* '~' */126);
    var downArrows$1 = [
      downArrows_0,
      accentColor
    ];
    downArrows = {
      hd: spacingDownBlock,
      tl: {
        hd: downArrows$1,
        tl: {
          hd: [
            "\n",
            /* NORMAL */0
          ],
          tl: /* [] */0
        }
      }
    };
  }
  var blocks = getLines(firstLine, /* [] */0);
  var startUpArrows = startPos.line === endPos.line ? startPos.col : 0;
  var endUpArrows = endPos.col === startUpArrows ? endPos.col + 1 | 0 : endPos.col;
  var spacingUpBlock_0 = [
    "\n" + $$String.make((maxLineColWidth + 2 | 0) + startUpArrows | 0, /* ' ' */32),
    /* NORMAL */0
  ];
  var spacingUpBlock = {
    hd: spacingUpBlock_0,
    tl: /* [] */0
  };
  var upArrows_0 = [
    $$String.make(endUpArrows - startUpArrows | 0, /* '^' */94),
    accentColor
  ];
  var upArrows = {
    hd: upArrows_0,
    tl: /* [] */0
  };
  return Pervasives.$at(downArrows, Pervasives.$at(blocks, Pervasives.$at(spacingUpBlock, upArrows)));
}

function showAtRange(level, code, range, message) {
  var startPos = Position$Poly.startPos(range);
  var endPos = Position$Poly.endPos(range);
  var lineMessage = getLine(level, code, startPos, endPos);
  return Pervasives.$at(lineMessage, {
              hd: [
                "\n\n" + (Diagnostic$Poly.diagnosticLevelToString(level) + (": " + message)),
                /* NORMAL */0
              ],
              tl: /* [] */0
            });
}

var downArrowChar = /* '~' */126;

var downUpArrowChar = /* '^' */94;

var upArrowChar = /* '^' */94;

var maxLines = 4;

exports.downArrowChar = downArrowChar;
exports.downUpArrowChar = downUpArrowChar;
exports.upArrowChar = upArrowChar;
exports.maxLines = maxLines;
exports.getLine = getLine;
exports.showAtRange = showAtRange;
/* No side effect */
