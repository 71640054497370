'use strict';

var List = require("rescript/lib/js/list.js");
var Compile$Poly = require("./Compile.bs.js");

function getCompileDiagnostics(parseDiagnostics) {
  return List.map(Compile$Poly.parseToCompilerDiagnostic, parseDiagnostics);
}

function compileDiagnosticsFromContexts(parseContext) {
  return List.map(Compile$Poly.parseToCompilerDiagnostic, parseContext.diagnostics);
}

exports.getCompileDiagnostics = getCompileDiagnostics;
exports.compileDiagnosticsFromContexts = compileDiagnosticsFromContexts;
/* No side effect */
