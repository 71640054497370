'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var React = require("react");
var Pervasives = require("rescript/lib/js/pervasives.js");
var AstSimple$Poly = require("./AstSimple.bs.js");

function treeHtml(title, elements) {
  return React.createElement("details", {
              open: true
            }, React.createElement("summary", undefined, title), $$Array.of_list(List.mapi((function (i, element) {
                        return React.createElement("li", {
                                    key: String(i)
                                  }, element);
                      }), elements)));
}

function literalToHtml(l) {
  switch (l.TAG | 0) {
    case /* NUMBER */0 :
        return AstSimple$Poly.literalToSimpleString(l);
    case /* TEXT */1 :
        return treeHtml("TEXT", List.map((function (x) {
                          if (x.TAG === /* CONTENTS */0) {
                            return x.text;
                          } else {
                            return treeHtml("INTERPOLATE", {
                                        hd: expressionToHtml(x.expression),
                                        tl: /* [] */0
                                      });
                          }
                        }), l.text));
    case /* DICTIONARY_ITEM_TEXT */2 :
        return treeHtml("DICTIONARY_ITEM_TEXT", {
                    hd: l.contents,
                    tl: /* [] */0
                  });
    case /* ARRAY */3 :
        return treeHtml("ARRAY", List.map(expressionToHtml, l._0.elements));
    case /* TUPLE */4 :
        var match = l._0;
        return treeHtml("TUPLE", Pervasives.$at(List.map(expressionToHtml, match.unnamedTerms), List.map((function (param) {
                              return React.createElement("span", undefined, param[0] + ": ", expressionToHtml(param[2]));
                            }), match.namedTerms)));
    case /* DICTIONARY */5 :
        return treeHtml("DICTIONARY", List.map((function (item) {
                          if (item.TAG === /* REGULAR */0) {
                            return treeHtml("ITEM", {
                                        hd: expressionToHtml(item.key),
                                        tl: {
                                          hd: expressionToHtml(item.value),
                                          tl: /* [] */0
                                        }
                                      });
                          } else {
                            return treeHtml("ITEM", {
                                        hd: variableToHtml(item.key),
                                        tl: /* [] */0
                                      });
                          }
                        }), l._0.items));
    
  }
}

function typeExpressionToHtml(typeExpression) {
  switch (typeExpression.TAG | 0) {
    case /* TYPE */0 :
        return treeHtml("TYPE", {
                    hd: typeExpression.name,
                    tl: /* [] */0
                  });
    case /* ARRAY_TYPE */1 :
        return treeHtml("ARRAY_TYPE", {
                    hd: typeExpressionToHtml(typeExpression.typeExpression),
                    tl: /* [] */0
                  });
    case /* TUPLE_TYPE */2 :
        return treeHtml("TUPLE_TYPE", List.map(typeExpressionToHtml, typeExpression.types));
    case /* NAMED_TUPLE_TYPE */3 :
        return treeHtml("NAMED_TUPLE_TYPE", Pervasives.$at(List.map((function (param) {
                              return treeHtml(param[0], {
                                          hd: typeExpressionToHtml(param[2]),
                                          tl: /* [] */0
                                        });
                            }), typeExpression.namedTypes), List.map((function (param) {
                              return treeHtml(param[0] + "=", {
                                          hd: typeExpressionToHtml(param[2]),
                                          tl: {
                                            hd: expressionToHtml(param[4]),
                                            tl: /* [] */0
                                          }
                                        });
                            }), typeExpression.defaultTypes)));
    case /* DICTIONARY_TYPE */4 :
        return treeHtml("DICTIONARY_TYPE", {
                    hd: typeExpressionToHtml(typeExpression.keyType),
                    tl: {
                      hd: typeExpressionToHtml(typeExpression.valueType),
                      tl: /* [] */0
                    }
                  });
    
  }
}

function expressionToHtml(e) {
  switch (e.TAG | 0) {
    case /* GROUPING */0 :
        return treeHtml("GROUPING", {
                    hd: expressionToHtml(e._0.expression),
                    tl: /* [] */0
                  });
    case /* EQUALS */1 :
        var match = e._0;
        return treeHtml("EQUALS", {
                    hd: expressionToHtml(match.left),
                    tl: {
                      hd: expressionToHtml(match.right),
                      tl: /* [] */0
                    }
                  });
    case /* UNEQUALS */2 :
        var match$1 = e._0;
        return treeHtml("UNEQUALS", {
                    hd: expressionToHtml(match$1.left),
                    tl: {
                      hd: expressionToHtml(match$1.right),
                      tl: /* [] */0
                    }
                  });
    case /* GREATER */3 :
        var match$2 = e._0;
        return treeHtml("GREATER", {
                    hd: expressionToHtml(match$2.left),
                    tl: {
                      hd: expressionToHtml(match$2.right),
                      tl: /* [] */0
                    }
                  });
    case /* LESSER */4 :
        var match$3 = e._0;
        return treeHtml("LESSER", {
                    hd: expressionToHtml(match$3.left),
                    tl: {
                      hd: expressionToHtml(match$3.right),
                      tl: /* [] */0
                    }
                  });
    case /* GREATER_EQUALS */5 :
        var match$4 = e._0;
        return treeHtml("GREATER_EQUALS", {
                    hd: expressionToHtml(match$4.left),
                    tl: {
                      hd: expressionToHtml(match$4.right),
                      tl: /* [] */0
                    }
                  });
    case /* LESSER_EQUALS */6 :
        var match$5 = e._0;
        return treeHtml("LESSER_EQUALS", {
                    hd: expressionToHtml(match$5.left),
                    tl: {
                      hd: expressionToHtml(match$5.right),
                      tl: /* [] */0
                    }
                  });
    case /* PLUS */7 :
        var match$6 = e._0;
        return treeHtml("PLUS", {
                    hd: expressionToHtml(match$6.left),
                    tl: {
                      hd: expressionToHtml(match$6.right),
                      tl: /* [] */0
                    }
                  });
    case /* MINUS */8 :
        var match$7 = e._0;
        return treeHtml("MINUS", {
                    hd: expressionToHtml(match$7.left),
                    tl: {
                      hd: expressionToHtml(match$7.right),
                      tl: /* [] */0
                    }
                  });
    case /* DIVIDE */9 :
        var match$8 = e._0;
        return treeHtml("DIVIDE", {
                    hd: expressionToHtml(match$8.left),
                    tl: {
                      hd: expressionToHtml(match$8.right),
                      tl: /* [] */0
                    }
                  });
    case /* MULTIPLY */10 :
        var match$9 = e._0;
        return treeHtml("MULTIPLY", {
                    hd: expressionToHtml(match$9.left),
                    tl: {
                      hd: expressionToHtml(match$9.right),
                      tl: /* [] */0
                    }
                  });
    case /* POWER */11 :
        var match$10 = e._0;
        return treeHtml("POWER", {
                    hd: expressionToHtml(match$10.left),
                    tl: {
                      hd: expressionToHtml(match$10.right),
                      tl: /* [] */0
                    }
                  });
    case /* RANGE */12 :
        var match$11 = e._0;
        var start = match$11.startExpression;
        if (start !== undefined) {
          return treeHtml("RANGE", {
                      hd: expressionToHtml(start),
                      tl: {
                        hd: expressionToHtml(match$11.endExpression),
                        tl: /* [] */0
                      }
                    });
        } else {
          return treeHtml("RANGE", {
                      hd: expressionToHtml(match$11.endExpression),
                      tl: /* [] */0
                    });
        }
    case /* NEGATE */13 :
        return treeHtml("NEGATE", {
                    hd: expressionToHtml(e._0.operand),
                    tl: /* [] */0
                  });
    case /* NOT */14 :
        return treeHtml("NOT", {
                    hd: expressionToHtml(e._0.operand),
                    tl: /* [] */0
                  });
    case /* VARIABLE */15 :
        return variableToHtml(e._0);
    case /* INDEX */16 :
        return treeHtml("INDEX", {
                    hd: expressionToHtml(e.expression),
                    tl: {
                      hd: expressionToHtml(e.index),
                      tl: /* [] */0
                    }
                  });
    case /* CALL */17 :
        return treeHtml("CALL", Pervasives.$at({
                        hd: expressionToHtml(e.expression),
                        tl: /* [] */0
                      }, Pervasives.$at(List.map(expressionToHtml, e.unnamedArgs), List.map((function (param) {
                                  return React.createElement("span", undefined, param[0] + ": ", expressionToHtml(param[2]));
                                }), e.namedArgs))));
    case /* LITERAL */18 :
        return literalToHtml(e._0);
    case /* EXPRESSION_ERROR */19 :
        return AstSimple$Poly.expressionErrorToSimpleString(e._0);
    
  }
}

function variableToHtml(v) {
  return treeHtml("VARIABLE", {
              hd: v.name,
              tl: /* [] */0
            });
}

function variableDeclarationToHtml(v) {
  var match = v.variableType;
  return treeHtml("VARIABLE_DECLARATION", {
              hd: variableToHtml(v.variable),
              tl: {
                hd: match !== undefined ? typeExpressionToHtml(match.typeExpression) : "auto-type",
                tl: /* [] */0
              }
            });
}

function statementToHtml(s) {
  if (typeof s === "number") {
    return "STATEMENT_ERROR";
  }
  switch (s.TAG | 0) {
    case /* DECLARATION */0 :
        return treeHtml("DECLARATION", {
                    hd: variableDeclarationToHtml(s.variableDeclaration),
                    tl: {
                      hd: expressionToHtml(s.expression),
                      tl: /* [] */0
                    }
                  });
    case /* REASSIGN */1 :
        return treeHtml("REASSIGN", {
                    hd: variableToHtml(s.variable),
                    tl: {
                      hd: expressionToHtml(s.expression),
                      tl: /* [] */0
                    }
                  });
    case /* EXPRESSION */2 :
        return treeHtml("EXPRESSION", {
                    hd: expressionToHtml(s.expression),
                    tl: /* [] */0
                  });
    case /* IF */3 :
        return treeHtml("IF", ifStatementToHtml(s._0));
    case /* FOR_IN */4 :
        return treeHtml("FOR_IN", {
                    hd: variableDeclarationToHtml(s.identifier),
                    tl: {
                      hd: expressionToHtml(s.expression),
                      tl: {
                        hd: statementToHtml({
                              TAG: /* BLOCK */5,
                              _0: s.block
                            }),
                        tl: /* [] */0
                      }
                    }
                  });
    case /* BLOCK */5 :
        return treeHtml("BLOCK", blockToHtml(s._0));
    
  }
}

function blockToHtml(block) {
  return List.map(statementToHtml, block.statements);
}

function ifStatementToHtml(ifStatement) {
  var elseStatement = ifStatement.elseStatement;
  return Pervasives.$at({
              hd: expressionToHtml(ifStatement.expression),
              tl: {
                hd: statementToHtml({
                      TAG: /* BLOCK */5,
                      _0: ifStatement.block
                    }),
                tl: /* [] */0
              }
            }, elseStatement !== undefined ? elseStatementToHtml(elseStatement) : /* [] */0);
}

function elseStatementToHtml(elseStatement) {
  if (elseStatement.TAG === /* ELSE_IF */0) {
    return {
            hd: treeHtml("ELSE_IF", ifStatementToHtml(elseStatement.ifStatement)),
            tl: /* [] */0
          };
  } else {
    return {
            hd: treeHtml("ELSE", blockToHtml(elseStatement.block)),
            tl: /* [] */0
          };
  }
}

function programToHtml(p) {
  return treeHtml("PROGRAM", List.map(statementToHtml, p));
}

exports.treeHtml = treeHtml;
exports.literalToHtml = literalToHtml;
exports.variableDeclarationToHtml = variableDeclarationToHtml;
exports.variableToHtml = variableToHtml;
exports.typeExpressionToHtml = typeExpressionToHtml;
exports.expressionToHtml = expressionToHtml;
exports.statementToHtml = statementToHtml;
exports.blockToHtml = blockToHtml;
exports.ifStatementToHtml = ifStatementToHtml;
exports.elseStatementToHtml = elseStatementToHtml;
exports.programToHtml = programToHtml;
/* react Not a pure module */
