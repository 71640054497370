'use strict';

var $$String = require("rescript/lib/js/string.js");
var Position$Poly = require("../position/Position.bs.js");
var IndexedText$Poly = require("../code/IndexedText.bs.js");
var MessageUtils$Poly = require("./MessageUtils.bs.js");

function optionPosition(code, token) {
  if (token !== undefined) {
    return Position$Poly.startPos(token.range);
  } else {
    return IndexedText$Poly.endPosition(code);
  }
}

function errorToMessage(level, error, code) {
  var showRange = function (range, message) {
    return MessageUtils$Poly.showAtRange(level, code, range, message);
  };
  var unexpectedToken = function (token) {
    return "Unexpected token (" + (token.text + ").");
  };
  var match = error._0;
  switch (match.TAG | 0) {
    case /* LEX_ERROR */0 :
        var match$1 = match._0;
        var c = match$1.error;
        if (typeof c === "number") {
          if (c === /* THREE_DOTS */0) {
            return showRange(match$1.range, "Three dots (...) not permitted; maybe you meant to use two dots (..)?");
          } else {
            return showRange(match$1.range, "Escape character at end of file is unterminated");
          }
        }
        switch (c.TAG | 0) {
          case /* NEWLINE_IN_QUOTE */0 :
              return showRange(c._0, "Cannot have newline in quoted text. Use the escape character (\\n) for a newline, or use a tick (`) to quote text with newlines");
          case /* ILLEGAL_ESCAPE */1 :
              return showRange(match$1.range, "Escape character (" + ($$String.make(1, c._0) + ") is invalid"));
          case /* UNKNOWN_CHARACTER */2 :
              return showRange(match$1.range, "Encountered an unknown character (" + ($$String.make(1, c._0) + ")"));
          
        }
    case /* EXPRESSION_ERROR */1 :
        var match$2 = match._0;
        switch (match$2.TAG | 0) {
          case /* UNEXPECTED_PRIMARY_TOKEN */0 :
              var token = match$2.errorToken;
              if (token !== undefined) {
                return showRange(token.range, "Unexpected token (" + (token.text + "); was expecting a literal"));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Expecting a literal at end of code");
              }
          case /* EXPECTING_LOWERCASE_VARIABLE */1 :
              return showRange(match$2.errorToken.range, "Variables should start with a lowercase letter");
          case /* UNEXPECTED_ARRAY_TOKEN */2 :
              var token$1 = match$2.errorToken;
              if (token$1 !== undefined) {
                return showRange(token$1.range, "Unexpected token in array (" + (token$1.text + "); was expecting a comma or right bracket ']'"));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Expecting an array token at end of code");
              }
          case /* EXPECTING_INDEX_RBRACKET */3 :
              return showRange({
                          TAG: /* RANGE */1,
                          _0: Position$Poly.startPos(match$2.lbracket.range),
                          _1: optionPosition(code, match$2.errorToken)
                        }, "Unbalanced brackets in index; expecting an additional right bracket");
          case /* EXPECTING_INTERP_END */4 :
              var token$2 = match$2.errorToken;
              if (token$2 !== undefined) {
                return showRange(token$2.range, "Unexpected token in text interpolate (" + (token$2.text + "); was expecting the interpolation end token: ]"));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Expecting text interpolation end token at end of code");
              }
          case /* UNEXPECTED_TEXT_TOKEN */5 :
              var token$3 = match$2.errorToken;
              if (token$3 !== undefined) {
                return showRange(token$3.range, "Unexpected token in text (" + (token$3.text + "); was expecting a double quote or interpolate expression"));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Unexpected text token at end of code");
              }
          case /* UNEXPECTED_GROUPING_TOKEN */6 :
              return showRange({
                          TAG: /* RANGE */1,
                          _0: Position$Poly.startPos(match$2.lparen.range),
                          _1: optionPosition(code, match$2.errorToken)
                        }, "Unbalanced parentheses; expecting an additional right parenthesis");
          case /* TUPLE_UNNAMED_AFTER_NAMED */7 :
              var range = match$2.range;
              if (match$2.mode) {
                return showRange(range, "Cannot have an unnamed function call parameter after a named one");
              } else {
                return showRange(range, "Cannot have an unnamed tuple parameter after a named one");
              }
          case /* UNEXPECTED_DICTIONARY_TOKEN */8 :
              var token$4 = match$2.errorToken;
              if (token$4 !== undefined) {
                return showRange(token$4.range, "Unexpected dictionary token (" + (token$4.text + ")"));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Unexpected dictionary token at end of code");
              }
          case /* DICTIONARY_NONVARIABLE_SHORTFORM */9 :
              return showRange(match$2.range, "Shortform dictionary items must be variables");
          case /* EXPECTING_UPPERCASE_TYPE */10 :
              return showRange(match$2.errorToken.range, "Types should start with a capital letter");
          case /* UNEXPECTED_TYPE_TOKEN */11 :
              var token$5 = match$2.errorToken;
              if (token$5 !== undefined) {
                return showRange(token$5.range, "Unexpected token (" + (token$5.text + "); was expecting a type"));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Expecting a type at end of code");
              }
          case /* UNEXPECTED_COMMA_IN_ARRAY_TYPE */12 :
              return showRange({
                          TAG: /* RANGE */1,
                          _0: Position$Poly.startPos(match$2.lbracketToken.range),
                          _1: Position$Poly.endPos(match$2.commaToken.range)
                        }, "Unexpected comma in array type. Maybe you meant to surround with parentheses to indicate a tuple element type?");
          case /* EXPECTING_ARRAY_TYPE_RBRACKET */13 :
              var errorToken = match$2.errorToken;
              if (errorToken !== undefined) {
                return showRange(errorToken.range, "Unexpected token in array type (" + (errorToken.text + "); was expecting a right bracket ']'"));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Expecting a right bracket at end of code to close out array type");
              }
          case /* EXPECTING_DICTIONARY_TYPE_RBRACE */14 :
              var errorToken$1 = match$2.errorToken;
              if (errorToken$1 !== undefined) {
                return showRange(errorToken$1.range, "Unexpected token in dictionary type (" + (errorToken$1.text + "); was expecting a right brace '}'"));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Expecting a right brace at end of code to close out dictionary type");
              }
          case /* EXPECTING_DICTIONARY_TYPE_COLON */15 :
              var errorToken$2 = match$2.errorToken;
              if (errorToken$2 !== undefined) {
                return showRange(errorToken$2.range, "Unexpected token in dictionary type (" + (errorToken$2.text + "); was expecting a colon ':'"));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Expecting a colon in dictionary type");
              }
          case /* UNEXPECTED_TUPLE_TYPE_TOKEN */16 :
              var token$6 = match$2.errorToken;
              if (token$6 !== undefined) {
                return showRange(token$6.range, "Unexpected token in tuple type (" + (token$6.text + "); was expecting a comma or right paren ')'"));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Expecting a tuple type token at end of code");
              }
          case /* EXPECTING_LOWERCASE_TUPLE_NAMED_PARAMETER */17 :
              return showRange(match$2.errorToken.range, "Tuple named parameters should start with a lowercase letter");
          case /* TUPLE_TYPE_UNNAMED_AFTER_NAMED */18 :
              return showRange(match$2.range, "If a tuple type has named parameters, all parameters must be named");
          case /* TUPLE_TYPE_NAMED_AFTER_UNNAMED */19 :
              return showRange(match$2.range, "If a tuple type has unnamed parameters, all parameters must be unnamed");
          case /* TUPLE_TYPE_NAMED_AFTER_DEFAULT */20 :
              return showRange(match$2.range, "Parameters with default values must go at end");
          case /* SHOULD_NOT_HAPPEN */21 :
              return {
                      hd: [
                        "Unexpected error",
                        /* RED */1
                      ],
                      tl: /* [] */0
                    };
          
        }
    case /* STATEMENT_ERROR */2 :
        var match$3 = match._0;
        switch (match$3.TAG | 0) {
          case /* EXPECTING_SEMICOLON_AFTER_DECLARATION_STATEMENT */0 :
              return showRange(match$3.range, "Expecting a semicolon to end the declaration statement.");
          case /* EXPECTING_EQUALS_AFTER_VARIABLE_DECLARATION_STATEMENT */1 :
              var errorToken$3 = match$3.errorToken;
              if (errorToken$3 !== undefined) {
                return showRange(errorToken$3.range, unexpectedToken(errorToken$3));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: match$3.position
                          }, "Expecting an equals after variable declaration");
              }
          case /* EXPECTING_VARIABLE_DECLARATION_STATEMENT */2 :
              var errorToken$4 = match$3.errorToken;
              if (errorToken$4 !== undefined) {
                return showRange(errorToken$4.range, unexpectedToken(errorToken$4));
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: match$3.position
                          }, "Expecting a variable declaration");
              }
          case /* EXPECTING_SEMICOLON_AFTER_REASSIGN_STATEMENT */3 :
              return showRange(match$3.range, "Expecting a semicolon to end the reassign statement.");
          case /* EXPECTING_EQUALS_AFTER_REASSIGN_STATEMENT */4 :
              return showRange({
                          TAG: /* POINT */0,
                          _0: match$3.position
                        }, "Expecting an equals after variable");
          case /* EXPECTING_SEMICOLON_AFTER_EXPRESSION_STATEMENT */5 :
              var errorToken$5 = match$3.errorToken;
              if (errorToken$5 !== undefined) {
                return showRange(errorToken$5.range, unexpectedToken(errorToken$5));
              } else {
                return showRange(match$3.range, "Expecting a semicolon to end expression statement");
              }
          case /* EXPECTING_RBRACE */6 :
              return showRange({
                          TAG: /* RANGE */1,
                          _0: Position$Poly.startPos(match$3.lbraceToken.range),
                          _1: match$3.position
                        }, "Expecting a right brace `}` to end block");
          case /* EXPECTING_BRACE_AFTER_IF */7 :
              return showRange({
                          TAG: /* POINT */0,
                          _0: match$3.position
                        }, "Expecting a left brace `{` after if expression");
          case /* EXPECTING_IF_OR_BLOCK_AFTER_ELSE */8 :
              return showRange({
                          TAG: /* POINT */0,
                          _0: match$3.position
                        }, "Expecting to start another if statement or enter block after else");
          case /* EXPECTING_IN_TOKEN_FOR_IN */9 :
              var errorToken$6 = match$3.errorToken;
              if (errorToken$6 !== undefined) {
                return showRange(errorToken$6.range, "Expecting \"in\" here to make for-in statement");
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Expecting \"in\" at end of code to make for-in statement");
              }
          case /* EXPECTING_BRACE_AFTER_FOR_IN */10 :
              var errorToken$7 = match$3.errorToken;
              if (errorToken$7 !== undefined) {
                return showRange(errorToken$7.range, "Expecting an opening brace `{` here in for-in statement");
              } else {
                return showRange({
                            TAG: /* POINT */0,
                            _0: IndexedText$Poly.endPosition(code)
                          }, "Expecting an opening brace `{` at end of code to continue for-in statement");
              }
          
        }
    
  }
}

exports.optionPosition = optionPosition;
exports.errorToMessage = errorToMessage;
/* No side effect */
