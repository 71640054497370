'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Char$Poly = require("../utils/Char.bs.js");
var Utils$Poly = require("../utils/Utils.bs.js");
var Caml_string = require("rescript/lib/js/caml_string.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var Nontrailing$Poly = require("./Nontrailing.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

function breakToString(b) {
  switch (b) {
    case /* Blank */0 :
        return "Blank";
    case /* Space */1 :
        return "Space";
    case /* Newline */2 :
        return "Newline";
    
  }
}

function atomToString(a) {
  switch (a.TAG | 0) {
    case /* String */0 :
        return Utils$Poly.variantToString("String", {
                    hd: Utils$Poly.$$escape(a._0),
                    tl: {
                      hd: String(a._1),
                      tl: {
                        hd: String(a._2),
                        tl: /* [] */0
                      }
                    }
                  });
    case /* Split */1 :
        return Utils$Poly.variantToString("Split", {
                    hd: atomToString(a._0),
                    tl: {
                      hd: atomToString(a._1),
                      tl: /* [] */0
                    }
                  });
    case /* Break */2 :
        return Utils$Poly.variantToString("Break", {
                    hd: breakToString(a._0),
                    tl: /* [] */0
                  });
    case /* GroupOne */3 :
        return Utils$Poly.variantToString("GroupOne", {
                    hd: Utils$Poly.boolToString(a._0),
                    tl: {
                      hd: Utils$Poly.listToString(List.map(atomToString, a._1)),
                      tl: /* [] */0
                    }
                  });
    case /* GroupAll */4 :
        return Utils$Poly.variantToString("GroupAll", {
                    hd: Utils$Poly.boolToString(a._0),
                    tl: {
                      hd: Utils$Poly.listToString(List.map(atomToString, a._1)),
                      tl: /* [] */0
                    }
                  });
    case /* Indent */5 :
        return Utils$Poly.variantToString("Indent", {
                    hd: String(a._0),
                    tl: {
                      hd: atomToString(a._1),
                      tl: /* [] */0
                    }
                  });
    
  }
}

var Overflow = /* @__PURE__ */Caml_exceptions.create("Formatter-Poly.Overflow");

function $$eval(width, tab, i, _a, p, lastBreak, isGroupAll) {
  while(true) {
    var a = _a;
    switch (a.TAG | 0) {
      case /* String */0 :
          var l = a._2;
          return [
                  a,
                  Caml_obj.caml_equal(lastBreak, /* Newline */2) ? (p + i | 0) + l | 0 : p + l | 0,
                  undefined
                ];
      case /* Split */1 :
          _a = isGroupAll ? a._1 : a._0;
          continue ;
      case /* Break */2 :
          switch (a._0) {
            case /* Blank */0 :
                if (lastBreak === undefined) {
                  return [
                          a,
                          p,
                          /* Blank */0
                        ];
                } else {
                  return [
                          a,
                          p,
                          lastBreak
                        ];
                }
            case /* Space */1 :
                if (lastBreak === undefined) {
                  return [
                          a,
                          p + 1 | 0,
                          /* Space */1
                        ];
                } else {
                  return [
                          a,
                          p,
                          lastBreak
                        ];
                }
            case /* Newline */2 :
                return [
                        a,
                        0,
                        /* Newline */2
                      ];
            
          }
      case /* GroupOne */3 :
          var canNest = a._0;
          var match = tryEvalListOne(width, tab, i, a._1, p, lastBreak, false, canNest, false);
          return [
                  {
                    TAG: /* GroupOne */3,
                    _0: canNest,
                    _1: match[0]
                  },
                  match[1],
                  match[2]
                ];
      case /* GroupAll */4 :
          var _as = a._1;
          var canNest$1 = a._0;
          var match$1;
          try {
            var match$2 = tryEvalListFlat(width, tab, i + tab | 0, _as, p, lastBreak);
            match$1 = [
              _as,
              match$2[0],
              match$2[1]
            ];
          }
          catch (raw_exn){
            var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
            if (exn.RE_EXN_ID === Overflow) {
              match$1 = evalListAll(width, i, tab, _as, p, lastBreak, canNest$1);
            } else {
              throw exn;
            }
          }
          return [
                  {
                    TAG: /* GroupAll */4,
                    _0: canNest$1,
                    _1: match$1[0]
                  },
                  match$1[1],
                  match$1[2]
                ];
      case /* Indent */5 :
          var n = a._0;
          var match$3 = $$eval(width, tab, i + Math.imul(n, tab) | 0, a._1, p, lastBreak, isGroupAll);
          return [
                  {
                    TAG: /* Indent */5,
                    _0: n,
                    _1: match$3[0]
                  },
                  match$3[1],
                  match$3[2]
                ];
      
    }
  };
}

function tryEvalFlat(width, tab, i, _a, p, lastBreak) {
  while(true) {
    var a = _a;
    var tryReturn = function (param) {
      var p = param[0];
      if (p > width) {
        throw {
              RE_EXN_ID: Overflow,
              Error: new Error()
            };
      }
      return [
              p,
              param[1]
            ];
    };
    switch (a.TAG | 0) {
      case /* String */0 :
          var l = a._2;
          return tryReturn([
                      Caml_obj.caml_equal(lastBreak, /* Newline */2) ? (p + i | 0) + l | 0 : p + l | 0,
                      undefined
                    ]);
      case /* Split */1 :
          _a = a._0;
          continue ;
      case /* Break */2 :
          switch (a._0) {
            case /* Blank */0 :
                if (lastBreak === undefined) {
                  return tryReturn([
                              p,
                              /* Blank */0
                            ]);
                } else {
                  return tryReturn([
                              p,
                              lastBreak
                            ]);
                }
            case /* Space */1 :
                if (lastBreak === undefined) {
                  return tryReturn([
                              p + 1 | 0,
                              /* Space */1
                            ]);
                } else {
                  return tryReturn([
                              p,
                              lastBreak
                            ]);
                }
            case /* Newline */2 :
                throw {
                      RE_EXN_ID: Overflow,
                      Error: new Error()
                    };
            
          }
      case /* GroupOne */3 :
      case /* GroupAll */4 :
          break;
      case /* Indent */5 :
          _a = a._1;
          continue ;
      
    }
    var match = tryEvalListFlat(width, tab, i + tab | 0, a._1, p, lastBreak);
    return [
            match[0],
            match[1]
          ];
  };
}

function tryEvalListFlat(width, tab, i, _as, p, lastBreak) {
  if (!_as) {
    return [
            p,
            lastBreak
          ];
  }
  var match = tryEvalFlat(width, tab, i, _as.hd, p, lastBreak);
  var match$1 = tryEvalListFlat(width, tab, i, _as.tl, match[0], match[1]);
  return [
          match$1[0],
          match$1[1]
        ];
}

function tryEvalListOne(width, tab, i, __as, p, lastBreak, canFail, canNest, inNest) {
  while(true) {
    var _as = __as;
    if (!_as) {
      return [
              _as,
              p,
              lastBreak
            ];
    }
    var a = _as.hd;
    if (a.TAG === /* Break */2) {
      switch (a._0) {
        case /* Blank */0 :
            var _as$1 = _as.tl;
            if (lastBreak === undefined) {
              try {
                var match = tryEvalListOne(width, tab, i, _as$1, p, /* Blank */0, true, canNest, inNest);
                return [
                        {
                          hd: {
                            TAG: /* Break */2,
                            _0: /* Blank */0
                          },
                          tl: match[0]
                        },
                        match[1],
                        match[2]
                      ];
              }
              catch (raw_exn){
                var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                if (exn.RE_EXN_ID === Overflow) {
                  var doIndent = canNest && !inNest;
                  var match$1 = tryEvalListOne(width, tab, doIndent ? i + tab | 0 : i, _as$1, 0, /* Newline */2, false, canNest, canNest);
                  var lastBreak$1 = match$1[2];
                  var p$1 = match$1[1];
                  var _as$2 = match$1[0];
                  if (doIndent) {
                    return [
                            {
                              hd: {
                                TAG: /* Break */2,
                                _0: /* Newline */2
                              },
                              tl: {
                                hd: {
                                  TAG: /* Indent */5,
                                  _0: 1,
                                  _1: {
                                    TAG: /* GroupOne */3,
                                    _0: false,
                                    _1: _as$2
                                  }
                                },
                                tl: /* [] */0
                              }
                            },
                            p$1,
                            lastBreak$1
                          ];
                  } else {
                    return [
                            {
                              hd: {
                                TAG: /* Break */2,
                                _0: /* Newline */2
                              },
                              tl: _as$2
                            },
                            p$1,
                            lastBreak$1
                          ];
                  }
                }
                throw exn;
              }
            } else {
              __as = _as$1;
              continue ;
            }
        case /* Space */1 :
            var _as$3 = _as.tl;
            if (lastBreak === undefined) {
              try {
                var match$2 = tryEvalListOne(width, tab, i, _as$3, p + 1 | 0, /* Space */1, true, canNest, inNest);
                return [
                        {
                          hd: {
                            TAG: /* Break */2,
                            _0: /* Space */1
                          },
                          tl: match$2[0]
                        },
                        match$2[1],
                        match$2[2]
                      ];
              }
              catch (raw_exn$1){
                var exn$1 = Caml_js_exceptions.internalToOCamlException(raw_exn$1);
                if (exn$1.RE_EXN_ID === Overflow) {
                  var doIndent$1 = canNest && !inNest;
                  var match$3 = tryEvalListOne(width, tab, doIndent$1 ? i + tab | 0 : i, _as$3, 0, /* Newline */2, false, canNest, canNest);
                  var lastBreak$2 = match$3[2];
                  var p$2 = match$3[1];
                  var _as$4 = match$3[0];
                  if (doIndent$1) {
                    return [
                            {
                              hd: {
                                TAG: /* Break */2,
                                _0: /* Newline */2
                              },
                              tl: {
                                hd: {
                                  TAG: /* Indent */5,
                                  _0: 1,
                                  _1: {
                                    TAG: /* GroupOne */3,
                                    _0: false,
                                    _1: _as$4
                                  }
                                },
                                tl: /* [] */0
                              }
                            },
                            p$2,
                            lastBreak$2
                          ];
                  } else {
                    return [
                            {
                              hd: {
                                TAG: /* Break */2,
                                _0: /* Newline */2
                              },
                              tl: _as$4
                            },
                            p$2,
                            lastBreak$2
                          ];
                  }
                }
                throw exn$1;
              }
            } else {
              __as = _as$3;
              continue ;
            }
        case /* Newline */2 :
            var _as$5 = _as.tl;
            var match$4 = inNest ? tryEvalListOne(width, tab, i - tab | 0, _as$5, 0, /* Newline */2, false, canNest, false) : tryEvalListOne(width, tab, i, _as$5, 0, /* Newline */2, false, canNest, false);
            var lastBreak$3 = match$4[2];
            var p$3 = match$4[1];
            var _as$6 = match$4[0];
            if (inNest) {
              return [
                      {
                        hd: {
                          TAG: /* Break */2,
                          _0: /* Newline */2
                        },
                        tl: {
                          hd: {
                            TAG: /* Indent */5,
                            _0: -1,
                            _1: {
                              TAG: /* GroupOne */3,
                              _0: false,
                              _1: _as$6
                            }
                          },
                          tl: /* [] */0
                        }
                      },
                      p$3,
                      lastBreak$3
                    ];
            } else {
              return [
                      {
                        hd: {
                          TAG: /* Break */2,
                          _0: /* Newline */2
                        },
                        tl: _as$6
                      },
                      p$3,
                      lastBreak$3
                    ];
            }
        
      }
    } else {
      var match$5;
      if (canFail) {
        var match$6 = tryEvalFlat(width, tab, i, a, p, lastBreak);
        match$5 = [
          a,
          match$6[0],
          match$6[1]
        ];
      } else {
        match$5 = $$eval(width, tab, i, a, p, lastBreak, false);
      }
      var match$7 = tryEvalListOne(width, tab, i, _as.tl, match$5[1], match$5[2], canFail, canNest, inNest);
      return [
              {
                hd: match$5[0],
                tl: match$7[0]
              },
              match$7[1],
              match$7[2]
            ];
    }
  };
}

function evalListAll(width, tab, i, __as, p, lastBreak, canNest) {
  while(true) {
    var _as = __as;
    if (!_as) {
      return [
              _as,
              p,
              lastBreak
            ];
    }
    var a = _as.hd;
    if (a.TAG === /* Break */2 && a._0 < 2) {
      var _as$1 = _as.tl;
      if (lastBreak === undefined) {
        var match = evalListAll(width, tab, canNest ? i + tab | 0 : i, _as$1, 0, /* Newline */2, false);
        var lastBreak$1 = match[2];
        var p$1 = match[1];
        var _as$2 = match[0];
        if (canNest) {
          return [
                  {
                    hd: {
                      TAG: /* Break */2,
                      _0: /* Newline */2
                    },
                    tl: {
                      hd: {
                        TAG: /* Indent */5,
                        _0: 1,
                        _1: {
                          TAG: /* GroupAll */4,
                          _0: false,
                          _1: _as$2
                        }
                      },
                      tl: /* [] */0
                    }
                  },
                  p$1,
                  lastBreak$1
                ];
        } else {
          return [
                  {
                    hd: {
                      TAG: /* Break */2,
                      _0: /* Newline */2
                    },
                    tl: _as$2
                  },
                  p$1,
                  lastBreak$1
                ];
        }
      }
      __as = _as$1;
      continue ;
    }
    var match$1 = $$eval(width, tab, i, a, p, lastBreak, true);
    var match$2 = evalListAll(width, tab, i, _as.tl, match$1[1], match$1[2], canNest);
    return [
            {
              hd: match$1[0],
              tl: match$2[0]
            },
            match$2[1],
            match$2[2]
          ];
  };
}

function render(width, tab, _as) {
  return $$eval(width, tab, 0, {
                TAG: /* GroupOne */3,
                _0: false,
                _1: _as
              }, 0, /* Newline */2, false)[0];
}

function fromString(s) {
  if (s === "") {
    return /* Empty */0;
  } else {
    return {
            TAG: /* Leaf */0,
            _0: {
              TAG: /* String */0,
              _0: s,
              _1: 0,
              _2: s.length
            }
          };
  }
}

function fromSplitString(s1, s2) {
  return {
          TAG: /* Leaf */0,
          _0: {
            TAG: /* Split */1,
            _0: {
              TAG: /* String */0,
              _0: s1,
              _1: 0,
              _2: s1.length
            },
            _1: {
              TAG: /* String */0,
              _0: s2,
              _1: 0,
              _2: s2.length
            }
          }
        };
}

function fromSubstring(s, o, l) {
  if (l === 0) {
    return /* Empty */0;
  } else {
    return {
            TAG: /* Leaf */0,
            _0: {
              TAG: /* String */0,
              _0: s,
              _1: o,
              _2: l
            }
          };
  }
}

var space = {
  TAG: /* Leaf */0,
  _0: {
    TAG: /* Break */2,
    _0: /* Space */1
  }
};

var newline = {
  TAG: /* Leaf */0,
  _0: {
    TAG: /* Break */2,
    _0: /* Newline */2
  }
};

function append(d1, d2) {
  return {
          TAG: /* Node */1,
          _0: d1,
          _1: d2
        };
}

function concatWithSpace(d1, d2) {
  return {
          TAG: /* Node */1,
          _0: d1,
          _1: {
            TAG: /* Node */1,
            _0: space,
            _1: d2
          }
        };
}

function toAtoms(d) {
  var aux = function (_d, _l) {
    while(true) {
      var l = _l;
      var d = _d;
      if (typeof d === "number") {
        return l;
      }
      if (d.TAG === /* Leaf */0) {
        return {
                hd: d._0,
                tl: l
              };
      }
      _l = aux(d._1, l);
      _d = d._0;
      continue ;
    };
  };
  return aux(d, /* [] */0);
}

function indent(d) {
  if (typeof d === "number") {
    return /* Empty */0;
  } else if (d.TAG === /* Leaf */0) {
    return {
            TAG: /* Leaf */0,
            _0: {
              TAG: /* Indent */5,
              _0: 1,
              _1: d._0
            }
          };
  } else {
    return {
            TAG: /* Node */1,
            _0: indent(d._0),
            _1: indent(d._1)
          };
  }
}

function nest(d) {
  return {
          TAG: /* Leaf */0,
          _0: {
            TAG: /* GroupOne */3,
            _0: true,
            _1: toAtoms(d)
          }
        };
}

function nestAll(d) {
  return {
          TAG: /* Leaf */0,
          _0: {
            TAG: /* GroupAll */4,
            _0: true,
            _1: toAtoms(d)
          }
        };
}

function group(d) {
  return {
          TAG: /* Leaf */0,
          _0: {
            TAG: /* GroupOne */3,
            _0: false,
            _1: toAtoms(d)
          }
        };
}

function groupAll(d) {
  return {
          TAG: /* Leaf */0,
          _0: {
            TAG: /* GroupAll */4,
            _0: false,
            _1: toAtoms(d)
          }
        };
}

function concat(ds) {
  return List.fold_left(append, /* Empty */0, ds);
}

function toDocList(d) {
  return List.map((function (x) {
                return {
                        TAG: /* Leaf */0,
                        _0: x
                      };
              }), toAtoms(d));
}

function separate(separator, ds) {
  var aux = function (ds) {
    if (ds) {
      return {
              TAG: /* Node */1,
              _0: separator,
              _1: {
                TAG: /* Node */1,
                _0: ds.hd,
                _1: aux(ds.tl)
              }
            };
    } else {
      return /* Empty */0;
    }
  };
  if (ds) {
    return {
            TAG: /* Node */1,
            _0: ds.hd,
            _1: aux(ds.tl)
          };
  } else {
    return /* Empty */0;
  }
}

function split(s, f, o, _l) {
  while(true) {
    var l = _l;
    if ((o + l | 0) === s.length) {
      return {
              hd: [
                o,
                l
              ],
              tl: /* [] */0
            };
    }
    if (Curry._1(f, Caml_string.get(s, o + l | 0))) {
      return {
              hd: [
                o,
                l
              ],
              tl: split(s, f, (o + l | 0) + 1 | 0, 0)
            };
    }
    _l = l + 1 | 0;
    continue ;
  };
}

function words(s) {
  return group(separate(space, List.map((function (param) {
                        return fromSubstring(s, param[0], param[1]);
                      }), split(s, Char$Poly.isWhitespace, 0, 0))));
}

function lines(s) {
  return separate(newline, List.map((function (param) {
                    return fromSubstring(s, param[0], param[1]);
                  }), split(s, (function (c) {
                        return c === /* '\n' */10;
                      }), 0, 0)));
}

var ShouldNotSplit = /* @__PURE__ */Caml_exceptions.create("Formatter-Poly.ShouldNotSplit");

function toString(a, tab) {
  var aux = function (_a, _i, lastBreak, ctx) {
    while(true) {
      var i = _i;
      var a = _a;
      switch (a.TAG | 0) {
        case /* String */0 :
            var ctx$1 = Caml_obj.caml_equal(lastBreak, /* Newline */2) ? Nontrailing$Poly.indent(ctx, i) : ctx;
            return [
                    Nontrailing$Poly.substring(ctx$1, a._0, a._1, a._2),
                    undefined
                  ];
        case /* Split */1 :
            _a = a._0;
            continue ;
        case /* Break */2 :
            switch (a._0) {
              case /* Blank */0 :
                  if (lastBreak === undefined) {
                    return [
                            ctx,
                            /* Blank */0
                          ];
                  } else {
                    return [
                            ctx,
                            lastBreak
                          ];
                  }
              case /* Space */1 :
                  if (lastBreak !== undefined) {
                    return [
                            ctx,
                            lastBreak
                          ];
                  }
                  var ctx$2 = Nontrailing$Poly.space(ctx);
                  return [
                          ctx$2,
                          /* Space */1
                        ];
              case /* Newline */2 :
                  var ctx$3 = Caml_obj.caml_equal(lastBreak, /* Newline */2) ? Nontrailing$Poly.indent(ctx, i) : ctx;
                  var ctx$4 = Nontrailing$Poly.newline(ctx$3);
                  return [
                          ctx$4,
                          /* Newline */2
                        ];
              
            }
        case /* GroupOne */3 :
        case /* GroupAll */4 :
            break;
        case /* Indent */5 :
            _i = i + Math.imul(a._0, tab) | 0;
            _a = a._1;
            continue ;
        
      }
      var _ctx = ctx;
      var _lastBreak = lastBreak;
      var __as = a._1;
      while(true) {
        var _as = __as;
        var lastBreak$1 = _lastBreak;
        var ctx$5 = _ctx;
        if (!_as) {
          return [
                  ctx$5,
                  lastBreak$1
                ];
        }
        var match = aux(_as.hd, i, lastBreak$1, ctx$5);
        __as = _as.tl;
        _lastBreak = match[1];
        _ctx = match[0];
        continue ;
      };
    };
  };
  var match = aux(a, 0, /* Newline */2, {
        result: "",
        nbSpaces: 0
      });
  return match[0].result;
}

var blank = {
  TAG: /* Leaf */0,
  _0: {
    TAG: /* Break */2,
    _0: /* Blank */0
  }
};

exports.breakToString = breakToString;
exports.atomToString = atomToString;
exports.Overflow = Overflow;
exports.$$eval = $$eval;
exports.tryEvalFlat = tryEvalFlat;
exports.tryEvalListFlat = tryEvalListFlat;
exports.tryEvalListOne = tryEvalListOne;
exports.evalListAll = evalListAll;
exports.render = render;
exports.fromString = fromString;
exports.fromSplitString = fromSplitString;
exports.fromSubstring = fromSubstring;
exports.space = space;
exports.blank = blank;
exports.newline = newline;
exports.append = append;
exports.concatWithSpace = concatWithSpace;
exports.toAtoms = toAtoms;
exports.indent = indent;
exports.nest = nest;
exports.nestAll = nestAll;
exports.group = group;
exports.groupAll = groupAll;
exports.concat = concat;
exports.toDocList = toDocList;
exports.separate = separate;
exports.split = split;
exports.words = words;
exports.lines = lines;
exports.ShouldNotSplit = ShouldNotSplit;
exports.toString = toString;
/* No side effect */
