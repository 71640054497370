'use strict';


function expressionForBinaryOp(binOp, e1, e2, binaryOperator) {
  switch (binOp) {
    case /* EQUALS_OP */0 :
        return {
                TAG: /* EQUALS */1,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* UNEQUALS_OP */1 :
        return {
                TAG: /* UNEQUALS */2,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* GREATER_OP */2 :
        return {
                TAG: /* GREATER */3,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* LESSER_OP */3 :
        return {
                TAG: /* LESSER */4,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* GREATER_EQUALS_OP */4 :
        return {
                TAG: /* GREATER_EQUALS */5,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* LESSER_EQUALS_OP */5 :
        return {
                TAG: /* LESSER_EQUALS */6,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* PLUS_OP */6 :
        return {
                TAG: /* PLUS */7,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* MINUS_OP */7 :
        return {
                TAG: /* MINUS */8,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* DIVIDE_OP */8 :
        return {
                TAG: /* DIVIDE */9,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* MULTIPLY_OP */9 :
        return {
                TAG: /* MULTIPLY */10,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* POWER_OP */10 :
        return {
                TAG: /* POWER */11,
                _0: {
                  left: e1,
                  right: e2,
                  binaryOperator: binaryOperator
                }
              };
    case /* RANGE_OP */11 :
        return {
                TAG: /* RANGE */12,
                _0: {
                  startExpression: e1,
                  endExpression: e2,
                  operator: binaryOperator
                }
              };
    
  }
}

function expressionForUnaryOp(unOp, e, token) {
  if (unOp) {
    return {
            TAG: /* NOT */14,
            _0: {
              operand: e,
              unaryOperator: token
            }
          };
  } else {
    return {
            TAG: /* NEGATE */13,
            _0: {
              operand: e,
              unaryOperator: token
            }
          };
  }
}

exports.expressionForBinaryOp = expressionForBinaryOp;
exports.expressionForUnaryOp = expressionForUnaryOp;
/* No side effect */
