'use strict';

var List = require("rescript/lib/js/list.js");

function firstExpressionSimplify(_expression) {
  while(true) {
    var expression = _expression;
    if (expression.TAG !== /* GROUPING */0) {
      return expressionSimplify(expression);
    }
    _expression = expression._0.expression;
    continue ;
  };
}

function ifSimplify(ifStatement) {
  var match = ifStatement.elseStatement;
  var tmp;
  tmp = match !== undefined ? (
      match.TAG === /* ELSE_IF */0 ? ({
            TAG: /* ELSE_IF */0,
            elseToken: match.elseToken,
            ifStatement: ifSimplify(match.ifStatement)
          }) : ({
            TAG: /* ELSE */1,
            elseToken: match.elseToken,
            block: blockSimplify(match.block)
          })
    ) : undefined;
  return {
          ifToken: ifStatement.ifToken,
          expression: firstExpressionSimplify(ifStatement.expression),
          block: blockSimplify(ifStatement.block),
          elseStatement: tmp
        };
}

function blockSimplify(block) {
  return {
          lbraceToken: block.lbraceToken,
          rbraceToken: block.rbraceToken,
          statements: List.map(statementSimplify, block.statements)
        };
}

function statementSimplify(statement) {
  if (typeof statement === "number") {
    return /* STATEMENT_ERROR */0;
  }
  switch (statement.TAG | 0) {
    case /* DECLARATION */0 :
        return {
                TAG: /* DECLARATION */0,
                variableDeclaration: statement.variableDeclaration,
                expression: firstExpressionSimplify(statement.expression),
                letToken: statement.letToken,
                assignToken: statement.assignToken,
                semicolonToken: statement.semicolonToken
              };
    case /* REASSIGN */1 :
        return {
                TAG: /* REASSIGN */1,
                variable: statement.variable,
                expression: firstExpressionSimplify(statement.expression),
                assignToken: statement.assignToken,
                semicolonToken: statement.semicolonToken
              };
    case /* EXPRESSION */2 :
        return {
                TAG: /* EXPRESSION */2,
                expression: firstExpressionSimplify(statement.expression),
                semicolonToken: statement.semicolonToken
              };
    case /* IF */3 :
        return {
                TAG: /* IF */3,
                _0: ifSimplify(statement._0)
              };
    case /* FOR_IN */4 :
        return {
                TAG: /* FOR_IN */4,
                forToken: statement.forToken,
                identifier: statement.identifier,
                inToken: statement.inToken,
                expression: firstExpressionSimplify(statement.expression),
                block: blockSimplify(statement.block)
              };
    case /* BLOCK */5 :
        return {
                TAG: /* BLOCK */5,
                _0: blockSimplify(statement._0)
              };
    
  }
}

function expressionSimplify(_expression) {
  while(true) {
    var expression = _expression;
    switch (expression.TAG | 0) {
      case /* GROUPING */0 :
          var g = expression._0.expression;
          switch (g.TAG | 0) {
            case /* GROUPING */0 :
            case /* LITERAL */18 :
                _expression = g;
                continue ;
            default:
              return expression;
          }
      case /* EQUALS */1 :
          return {
                  TAG: /* EQUALS */1,
                  _0: binOpSimplify(expression._0)
                };
      case /* UNEQUALS */2 :
          return {
                  TAG: /* UNEQUALS */2,
                  _0: binOpSimplify(expression._0)
                };
      case /* GREATER */3 :
          return {
                  TAG: /* GREATER */3,
                  _0: binOpSimplify(expression._0)
                };
      case /* LESSER */4 :
          return {
                  TAG: /* LESSER */4,
                  _0: binOpSimplify(expression._0)
                };
      case /* GREATER_EQUALS */5 :
          return {
                  TAG: /* GREATER_EQUALS */5,
                  _0: binOpSimplify(expression._0)
                };
      case /* LESSER_EQUALS */6 :
          return {
                  TAG: /* LESSER_EQUALS */6,
                  _0: binOpSimplify(expression._0)
                };
      case /* PLUS */7 :
          return {
                  TAG: /* PLUS */7,
                  _0: binOpSimplify(expression._0)
                };
      case /* MINUS */8 :
          return {
                  TAG: /* MINUS */8,
                  _0: binOpSimplify(expression._0)
                };
      case /* DIVIDE */9 :
          return {
                  TAG: /* DIVIDE */9,
                  _0: binOpSimplify(expression._0)
                };
      case /* MULTIPLY */10 :
          return {
                  TAG: /* MULTIPLY */10,
                  _0: binOpSimplify(expression._0)
                };
      case /* POWER */11 :
          return {
                  TAG: /* POWER */11,
                  _0: binOpSimplify(expression._0)
                };
      case /* RANGE */12 :
          var match = expression._0;
          var start = match.startExpression;
          if (start === undefined) {
            return {
                    TAG: /* RANGE */12,
                    _0: {
                      startExpression: undefined,
                      endExpression: expressionSimplify(match.endExpression),
                      operator: match.operator
                    }
                  };
          }
          var operator = match.operator;
          var endExpression = match.endExpression;
          if (start.TAG === /* LITERAL */18) {
            var match$1 = start._0;
            if (match$1.TAG === /* NUMBER */0 && match$1.number === 0.0) {
              return {
                      TAG: /* RANGE */12,
                      _0: {
                        startExpression: undefined,
                        endExpression: expressionSimplify(endExpression),
                        operator: operator
                      }
                    };
            }
            
          }
          return {
                  TAG: /* RANGE */12,
                  _0: {
                    startExpression: expressionSimplify(start),
                    endExpression: expressionSimplify(endExpression),
                    operator: operator
                  }
                };
          break;
      case /* NEGATE */13 :
          var u = expression._0;
          var match$2 = u.operand;
          if (match$2.TAG !== /* NEGATE */13) {
            return {
                    TAG: /* NEGATE */13,
                    _0: unOpSimplify(u)
                  };
          }
          _expression = match$2._0.operand;
          continue ;
      case /* NOT */14 :
          var u$1 = expression._0;
          var match$3 = u$1.operand;
          if (match$3.TAG !== /* NOT */14) {
            return {
                    TAG: /* NOT */14,
                    _0: unOpSimplify(u$1)
                  };
          }
          _expression = match$3._0.operand;
          continue ;
      case /* INDEX */16 :
          return {
                  TAG: /* INDEX */16,
                  expression: expressionSimplify(expression.expression),
                  index: firstExpressionSimplify(expression.index),
                  lbracket: expression.lbracket,
                  rbracket: expression.rbracket
                };
      case /* CALL */17 :
          return {
                  TAG: /* CALL */17,
                  expression: expressionSimplify(expression.expression),
                  unnamedArgs: List.map(firstExpressionSimplify, expression.unnamedArgs),
                  namedArgs: List.map((function (param) {
                          return [
                                  param[0],
                                  param[1],
                                  firstExpressionSimplify(param[2])
                                ];
                        }), expression.namedArgs),
                  lparen: expression.lparen,
                  rparen: expression.rparen,
                  callCommas: expression.callCommas
                };
      case /* LITERAL */18 :
          var fields = expression._0;
          if (fields.TAG !== /* ARRAY */3) {
            return expression;
          }
          var fields$1 = fields._0;
          return {
                  TAG: /* LITERAL */18,
                  _0: {
                    TAG: /* ARRAY */3,
                    _0: {
                      lbracket: fields$1.lbracket,
                      rbracket: fields$1.rbracket,
                      commas: fields$1.commas,
                      elements: List.map(firstExpressionSimplify, fields$1.elements)
                    }
                  }
                };
      case /* VARIABLE */15 :
      case /* EXPRESSION_ERROR */19 :
          return expression;
      
    }
  };
}

function unOpSimplify(unOp) {
  return {
          operand: expressionSimplify(unOp.operand),
          unaryOperator: unOp.unaryOperator
        };
}

function binOpSimplify(binOp) {
  return {
          left: expressionSimplify(binOp.left),
          right: expressionSimplify(binOp.right),
          binaryOperator: binOp.binaryOperator
        };
}

function programSimplify(program) {
  return List.map(statementSimplify, program);
}

exports.firstExpressionSimplify = firstExpressionSimplify;
exports.expressionSimplify = expressionSimplify;
exports.binOpSimplify = binOpSimplify;
exports.unOpSimplify = unOpSimplify;
exports.blockSimplify = blockSimplify;
exports.ifSimplify = ifSimplify;
exports.statementSimplify = statementSimplify;
exports.programSimplify = programSimplify;
/* No side effect */
