'use strict';

var $$String = require("rescript/lib/js/string.js");
var Utils$Poly = require("../utils/Utils.bs.js");
var Position$Poly = require("../position/Position.bs.js");

function charRepresentation(c) {
  var trimString = function (s) {
    return $$String.sub(s, 1, s.length - 2 | 0);
  };
  if (c !== 39) {
    return "'" + (trimString(Utils$Poly.$$escape($$String.make(1, c))) + "'");
  } else {
    return "'\\''";
  }
}

function errorRepresentation(error) {
  if (typeof error === "number") {
    if (error === /* THREE_DOTS */0) {
      return "THREE_DOTS";
    } else {
      return "UNTERMINATED_ESCAPE";
    }
  }
  switch (error.TAG | 0) {
    case /* NEWLINE_IN_QUOTE */0 :
        return Utils$Poly.variantToString("NEWLINE_IN_QUOTE", {
                    hd: Position$Poly.rangeToString(error._0),
                    tl: /* [] */0
                  });
    case /* ILLEGAL_ESCAPE */1 :
        return Utils$Poly.variantToString("ILLEGAL_ESCAPE", {
                    hd: charRepresentation(error._0),
                    tl: /* [] */0
                  });
    case /* UNKNOWN_CHARACTER */2 :
        return Utils$Poly.variantToString("UNKNOWN_CHARACTER", {
                    hd: charRepresentation(error._0),
                    tl: /* [] */0
                  });
    
  }
}

function errorCodeToMessage(error) {
  if (typeof error === "number") {
    if (error === /* THREE_DOTS */0) {
      return "Cannot have three dots in a row";
    } else {
      return "Unterminated escape character at end of file";
    }
  }
  switch (error.TAG | 0) {
    case /* NEWLINE_IN_QUOTE */0 :
        return "Newline in quoted text";
    case /* ILLEGAL_ESCAPE */1 :
        return "Illegal escape character: " + $$String.make(1, error._0);
    case /* UNKNOWN_CHARACTER */2 :
        return "Unknown character: " + $$String.make(1, error._0);
    
  }
}

function errorToString(error) {
  return Utils$Poly.dictToString({
              hd: [
                "error",
                errorRepresentation(error.error)
              ],
              tl: {
                hd: [
                  "range",
                  Position$Poly.rangeToString(error.range)
                ],
                tl: /* [] */0
              }
            });
}

function errorToSimple(error) {
  var c = error.error;
  if (typeof c === "number") {
    if (c === /* THREE_DOTS */0) {
      return /* THREE_DOTS */0;
    } else {
      return /* UNTERMINATED_ESCAPE */1;
    }
  }
  switch (c.TAG | 0) {
    case /* NEWLINE_IN_QUOTE */0 :
        return /* NEWLINE_IN_QUOTE */2;
    case /* ILLEGAL_ESCAPE */1 :
        return {
                TAG: /* ILLEGAL_ESCAPE */0,
                _0: c._0
              };
    case /* UNKNOWN_CHARACTER */2 :
        return {
                TAG: /* UNKNOWN_CHARACTER */1,
                _0: c._0
              };
    
  }
}

function errorSimpleToString(errorSimple) {
  if (typeof errorSimple !== "number") {
    if (errorSimple.TAG === /* ILLEGAL_ESCAPE */0) {
      return Utils$Poly.variantToString("ILLEGAL_ESCAPE", {
                  hd: charRepresentation(errorSimple._0),
                  tl: /* [] */0
                });
    } else {
      return Utils$Poly.variantToString("UNKNOWN_CHARACTER", {
                  hd: charRepresentation(errorSimple._0),
                  tl: /* [] */0
                });
    }
  }
  switch (errorSimple) {
    case /* THREE_DOTS */0 :
        return "THREE_DOTS";
    case /* UNTERMINATED_ESCAPE */1 :
        return "UNTERMINATED_ESCAPE";
    case /* NEWLINE_IN_QUOTE */2 :
        return "NEWLINE_IN_QUOTE";
    
  }
}

function errorToSimpleString(error) {
  return errorSimpleToString(errorToSimple(error));
}

exports.charRepresentation = charRepresentation;
exports.errorRepresentation = errorRepresentation;
exports.errorCodeToMessage = errorCodeToMessage;
exports.errorToString = errorToString;
exports.errorToSimple = errorToSimple;
exports.errorSimpleToString = errorSimpleToString;
exports.errorToSimpleString = errorToSimpleString;
/* No side effect */
