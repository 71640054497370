'use strict';

var List = require("rescript/lib/js/list.js");
var $$String = require("rescript/lib/js/string.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Utils$Poly = require("../utils/Utils.bs.js");

function fromString(s) {
  var _i = 0;
  var _currentLine = "";
  var _currentLinePosition = 0;
  var _lines = /* [] */0;
  var _linePositions = /* [] */0;
  while(true) {
    var linePositions = _linePositions;
    var lines = _lines;
    var currentLinePosition = _currentLinePosition;
    var currentLine = _currentLine;
    var i = _i;
    var c = Utils$Poly.getChar(s, i);
    if (c === undefined) {
      return {
              contents: s,
              lines: Pervasives.$at(lines, {
                    hd: currentLine,
                    tl: /* [] */0
                  }),
              linePositions: Pervasives.$at(linePositions, {
                    hd: currentLinePosition,
                    tl: /* [] */0
                  })
            };
    }
    if (c !== 10) {
      _currentLine = currentLine + $$String.make(1, c);
      _i = i + 1 | 0;
      continue ;
    }
    _linePositions = Pervasives.$at(linePositions, {
          hd: currentLinePosition,
          tl: /* [] */0
        });
    _lines = Pervasives.$at(lines, {
          hd: currentLine,
          tl: /* [] */0
        });
    _currentLinePosition = i + 1 | 0;
    _currentLine = "";
    _i = i + 1 | 0;
    continue ;
  };
}

function lineCount(indexedText) {
  return List.length(indexedText.lines);
}

function textLength(indexedText) {
  return indexedText.contents.length;
}

function lineLength(indexedText, line) {
  var lineIndex = List.nth(indexedText.linePositions, line);
  var lastLine = line === (List.length(indexedText.lines) - 1 | 0);
  var nextLineIndex = lastLine ? indexedText.contents.length : List.nth(indexedText.linePositions, line + 1 | 0);
  return (nextLineIndex - lineIndex | 0) - (
          lastLine ? 0 : 1
        ) | 0;
}

function endPosition(indexedText) {
  var x = Utils$Poly.last(indexedText.linePositions);
  return {
          line: List.length(indexedText.lines) - 1 | 0,
          col: x !== undefined ? indexedText.contents.length - x | 0 : indexedText.contents.length,
          char: indexedText.contents.length
        };
}

exports.fromString = fromString;
exports.lineCount = lineCount;
exports.textLength = textLength;
exports.lineLength = lineLength;
exports.endPosition = endPosition;
/* No side effect */
