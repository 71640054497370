'use strict';

var $$Map = require("rescript/lib/js/map.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");

function fileContents(file) {
  if (file.TAG === /* REAL_FILE */0) {
    return file.contents;
  } else {
    return file._0;
  }
}

function filename(file) {
  if (file.TAG === /* REAL_FILE */0) {
    return file.filename;
  } else {
    return "(virtual)";
  }
}

function formatPosition(position) {
  var match = position.file;
  if (match.TAG === /* REAL_FILE */0) {
    return match.filename + (":" + (String(position.position.line) + (":" + String(position.position.col))));
  } else {
    return String(position.position.line) + (":" + String(position.position.col));
  }
}

var compare = Caml_obj.caml_compare;

var StringMap = $$Map.Make({
      compare: compare
    });

exports.fileContents = fileContents;
exports.filename = filename;
exports.formatPosition = formatPosition;
exports.StringMap = StringMap;
/* StringMap Not a pure module */
