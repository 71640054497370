'use strict';

var $$String = require("rescript/lib/js/string.js");
var Char$Poly = require("../utils/Char.bs.js");
var LexContext$Poly = require("./LexContext.bs.js");

function whitespace(_ctx) {
  while(true) {
    var ctx = _ctx;
    var c = LexContext$Poly.currentChar(ctx);
    if (c === undefined) {
      return ctx;
    }
    if (!Char$Poly.isWhitespace(c)) {
      return ctx;
    }
    if (c === /* '\n' */10) {
      _ctx = LexContext$Poly.advanceLine(ctx, 1);
      continue ;
    }
    _ctx = LexContext$Poly.advanceCol(ctx, 1);
    continue ;
  };
}

function line(_ctx) {
  while(true) {
    var ctx = _ctx;
    var match = LexContext$Poly.currentChar(ctx);
    if (match === undefined) {
      return ctx;
    }
    if (match === 10) {
      return LexContext$Poly.advanceLine(ctx, 1);
    }
    _ctx = LexContext$Poly.advanceCol(ctx, 1);
    continue ;
  };
}

function peakNumber(ctx) {
  var _offset = 0;
  var _seenDot = false;
  var _lastSeenDot = false;
  var _s = "";
  while(true) {
    var s = _s;
    var lastSeenDot = _lastSeenDot;
    var seenDot = _seenDot;
    var offset = _offset;
    var c = LexContext$Poly.getCharOffset(ctx, offset);
    if (c !== undefined) {
      if (Char$Poly.isNumeric(c)) {
        _s = s + $$String.make(1, c);
        _lastSeenDot = false;
        _offset = offset + 1 | 0;
        continue ;
      }
      if (c === 46) {
        if (seenDot) {
          if (lastSeenDot) {
            return /* NUMBER */{
                    _0: $$String.sub(s, 0, offset - 1 | 0)
                  };
          }
          
        } else {
          _s = s + ".";
          _lastSeenDot = true;
          _seenDot = true;
          _offset = offset + 1 | 0;
          continue ;
        }
      }
      
    }
    if (s.length !== 0) {
      return /* NUMBER */{
              _0: s
            };
    } else {
      return /* NONE */0;
    }
  };
}

function peakVariable(ctx) {
  var _peakVariable = function (ctx, _offset, _first, _s, _v) {
    while(true) {
      var v = _v;
      var s = _s;
      var first = _first;
      var offset = _offset;
      var c = LexContext$Poly.getCharOffset(ctx, offset);
      if (c === undefined) {
        return [
                s,
                v
              ];
      }
      if (first) {
        if (Char$Poly.isLowerLetter(c)) {
          _v = /* IDENTIFIER */1;
          _s = s + $$String.make(1, c);
          _first = false;
          _offset = offset + 1 | 0;
          continue ;
        }
        if (!Char$Poly.isUpperLetter(c)) {
          return [
                  s,
                  v
                ];
        }
        _v = /* TYPECLASS */2;
        _s = s + $$String.make(1, c);
        _first = false;
        _offset = offset + 1 | 0;
        continue ;
      }
      if (!Char$Poly.isAlphanumeric(c)) {
        return [
                s,
                v
              ];
      }
      _s = s + $$String.make(1, c);
      _first = false;
      _offset = offset + 1 | 0;
      continue ;
    };
  };
  var match = _peakVariable(ctx, 0, true, "", /* NOTVAR */0);
  var s = match[0];
  switch (match[1]) {
    case /* NOTVAR */0 :
        return ;
    case /* IDENTIFIER */1 :
        return {
                TAG: /* IDENTIFIER */0,
                _0: s
              };
    case /* TYPECLASS */2 :
        return {
                TAG: /* TYPECLASS */2,
                _0: s
              };
    
  }
}

exports.whitespace = whitespace;
exports.line = line;
exports.peakNumber = peakNumber;
exports.peakVariable = peakVariable;
/* No side effect */
