'use strict';

var List = require("rescript/lib/js/list.js");
var $$String = require("rescript/lib/js/string.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Position$Poly = require("../position/Position.bs.js");
var ParseError$Poly = require("./ParseError.bs.js");
var Combinators$Poly = require("./Combinators.bs.js");
var ParseContext$Poly = require("./ParseContext.bs.js");
var ExpressionUtils$Poly = require("./ExpressionUtils.bs.js");

function equality(ctx) {
  return ExpressionUtils$Poly.leftAssociativeBinary(ctx, {
              hd: [
                /* EQUALS */24,
                /* EQUALS_OP */0
              ],
              tl: {
                hd: [
                  /* UNEQUALS */26,
                  /* UNEQUALS_OP */1
                ],
                tl: /* [] */0
              }
            }, comparison);
}

function range(ctx) {
  return ExpressionUtils$Poly.rightAssociativeBinary(ctx, {
              hd: [
                /* RANGE */5,
                /* RANGE_OP */11
              ],
              tl: /* [] */0
            }, unary);
}

function typeExpression(ctx) {
  var typeToken = ParseContext$Poly.currentToken(ctx);
  if (typeToken !== undefined) {
    var name = typeToken.token;
    if (typeof name === "number") {
      switch (name) {
        case /* LPAREN */10 :
            return Combinators$Poly.parseList(ParseContext$Poly.incrementPosition(ctx), /* RPAREN */11, {
                        unnamed: /* [] */0,
                        named: /* [] */0,
                        namedDefault: /* [] */0
                      }, (function (elements, expression) {
                          switch (expression.TAG | 0) {
                            case /* UNNAMED */0 :
                                return {
                                        unnamed: Pervasives.$at(elements.unnamed, {
                                              hd: expression._0,
                                              tl: /* [] */0
                                            }),
                                        named: elements.named,
                                        namedDefault: elements.namedDefault
                                      };
                            case /* NAMED */1 :
                                return {
                                        unnamed: elements.unnamed,
                                        named: Pervasives.$at(elements.named, {
                                              hd: [
                                                expression._0,
                                                expression._1,
                                                expression._2
                                              ],
                                              tl: /* [] */0
                                            }),
                                        namedDefault: elements.namedDefault
                                      };
                            case /* NAMED_DEFAULT */2 :
                                return {
                                        unnamed: elements.unnamed,
                                        named: elements.named,
                                        namedDefault: Pervasives.$at(elements.namedDefault, {
                                              hd: [
                                                expression._0,
                                                expression._1,
                                                expression._2,
                                                expression._3,
                                                expression._4
                                              ],
                                              tl: /* [] */0
                                            })
                                      };
                            
                          }
                        }), (function (expressionHandler) {
                          var match = ParseContext$Poly.currentToken(expressionHandler.ctx);
                          var match$1 = ParseContext$Poly.nextToken(expressionHandler.ctx);
                          if (match !== undefined) {
                            var match$2 = match.token;
                            var exit = 0;
                            if (typeof match$2 !== "number") {
                              switch (match$2.TAG | 0) {
                                case /* IDENTIFIER */0 :
                                case /* TYPECLASS */2 :
                                    exit = 2;
                                    break;
                                default:
                                  
                              }
                            }
                            if (exit === 2) {
                              var name = match$2._0;
                              if (match$1 !== undefined && match$1.token === 3) {
                                var ctx = ParseContext$Poly.offsetPosition(expressionHandler.ctx, 2);
                                if (List.length(expressionHandler.elements.unnamed) > 0) {
                                  ParseError$Poly.pushError(ctx, {
                                        TAG: /* EXPRESSION_ERROR */1,
                                        _0: {
                                          TAG: /* TUPLE_TYPE_NAMED_AFTER_UNNAMED */19,
                                          range: {
                                            TAG: /* RANGE */1,
                                            _0: Position$Poly.startPos(typeToken.range),
                                            _1: Position$Poly.endPos(match.range)
                                          },
                                          lparenToken: typeToken,
                                          colonToken: match$1
                                        }
                                      });
                                }
                                var v = match.token;
                                var ctx$1;
                                if (typeof v === "number" || v.TAG !== /* TYPECLASS */2) {
                                  ctx$1 = ctx;
                                } else {
                                  var warning = {
                                    TAG: /* EXPRESSION_ERROR */1,
                                    _0: {
                                      TAG: /* EXPECTING_LOWERCASE_VARIABLE */1,
                                      errorToken: match
                                    }
                                  };
                                  var beginningOfToken = Position$Poly.startPos(match.range);
                                  var firstLetterOfToken = Position$Poly.advanceCol(beginningOfToken, 1);
                                  var fixes_0 = {
                                    TAG: /* REPLACE */2,
                                    range: {
                                      TAG: /* RANGE */1,
                                      _0: beginningOfToken,
                                      _1: firstLetterOfToken
                                    },
                                    text: $$String.lowercase_ascii($$String.sub(v._0, 0, 1))
                                  };
                                  var fixes = {
                                    hd: fixes_0,
                                    tl: /* [] */0
                                  };
                                  ctx$1 = ParseError$Poly.pushWarningFixes(ctx, warning, fixes);
                                }
                                var match$3 = typeExpression(ctx$1);
                                var te = match$3[1];
                                var ctx$2 = match$3[0];
                                var assignToken = ParseContext$Poly.currentToken(ctx$2);
                                var exit$1 = 0;
                                if (assignToken !== undefined) {
                                  if (assignToken.token === 16) {
                                    var match$4 = equality(ParseContext$Poly.incrementPosition(ctx$2));
                                    return [
                                            match$4[0],
                                            {
                                              TAG: /* NAMED_DEFAULT */2,
                                              _0: name,
                                              _1: match$1,
                                              _2: te,
                                              _3: assignToken,
                                              _4: match$4[1]
                                            }
                                          ];
                                  }
                                  exit$1 = 3;
                                } else {
                                  exit$1 = 3;
                                }
                                if (exit$1 === 3) {
                                  if (List.length(expressionHandler.elements.namedDefault) > 0) {
                                    ParseError$Poly.pushError(ctx$2, {
                                          TAG: /* EXPRESSION_ERROR */1,
                                          _0: {
                                            TAG: /* TUPLE_TYPE_NAMED_AFTER_DEFAULT */20,
                                            range: {
                                              TAG: /* RANGE */1,
                                              _0: Position$Poly.startPos(typeToken.range),
                                              _1: Position$Poly.endPos(match.range)
                                            },
                                            lparenToken: typeToken,
                                            colonToken: match$1
                                          }
                                        });
                                  }
                                  return [
                                          ctx$2,
                                          {
                                            TAG: /* NAMED */1,
                                            _0: name,
                                            _1: match$1,
                                            _2: te
                                          }
                                        ];
                                }
                                
                              }
                              
                            }
                            
                          }
                          var match$5 = typeExpression(expressionHandler.ctx);
                          var ctx$3 = match$5[0];
                          if (List.length(expressionHandler.elements.named) > 0 || List.length(expressionHandler.elements.namedDefault) > 0) {
                            ParseError$Poly.pushError(ctx$3, {
                                  TAG: /* EXPRESSION_ERROR */1,
                                  _0: {
                                    TAG: /* TUPLE_TYPE_UNNAMED_AFTER_NAMED */18,
                                    range: {
                                      TAG: /* RANGE */1,
                                      _0: Position$Poly.startPos(typeToken.range),
                                      _1: ParseContext$Poly.currentPreviousTokenEndPosition(ctx$3)
                                    },
                                    lparenToken: typeToken
                                  }
                                });
                          }
                          return [
                                  ctx$3,
                                  {
                                    TAG: /* UNNAMED */0,
                                    _0: match$5[1]
                                  }
                                ];
                        }), (function (returnHandler) {
                          if (List.length(returnHandler.elements.named) > 0 || List.length(returnHandler.elements.namedDefault) > 0) {
                            return {
                                    TAG: /* NAMED_TUPLE_TYPE */3,
                                    tupleType: /* PAREN_ENCLOSED */{
                                      lparen: typeToken,
                                      rparen: returnHandler.closingToken
                                    },
                                    namedTypes: returnHandler.elements.named,
                                    defaultTypes: returnHandler.elements.namedDefault,
                                    commas: returnHandler.commas
                                  };
                          } else {
                            return {
                                    TAG: /* TUPLE_TYPE */2,
                                    tupleType: /* PAREN_ENCLOSED */{
                                      lparen: typeToken,
                                      rparen: returnHandler.closingToken
                                    },
                                    types: returnHandler.elements.unnamed,
                                    commas: returnHandler.commas
                                  };
                          }
                        }), (function (error) {
                          return ParseError$Poly.pushError(ctx, {
                                      TAG: /* EXPRESSION_ERROR */1,
                                      _0: {
                                        TAG: /* UNEXPECTED_TUPLE_TYPE_TOKEN */16,
                                        errorToken: error.errorToken,
                                        lparen: typeToken,
                                        commas: error.commas
                                      }
                                    });
                        }));
        case /* LBRACE */12 :
            var match = typeExpression(ParseContext$Poly.incrementPosition(ctx));
            var ctx$1 = match[0];
            var colonToken = ParseContext$Poly.currentToken(ctx$1);
            if (colonToken === undefined) {
              return ParseError$Poly.pushError(ctx$1, {
                          TAG: /* EXPRESSION_ERROR */1,
                          _0: {
                            TAG: /* EXPECTING_DICTIONARY_TYPE_COLON */15,
                            lbraceToken: typeToken,
                            errorToken: colonToken
                          }
                        });
            }
            if (colonToken.token !== 3) {
              return ParseError$Poly.pushError(ctx$1, {
                          TAG: /* EXPRESSION_ERROR */1,
                          _0: {
                            TAG: /* EXPECTING_DICTIONARY_TYPE_COLON */15,
                            lbraceToken: typeToken,
                            errorToken: colonToken
                          }
                        });
            }
            var match$1 = typeExpression(ParseContext$Poly.incrementPosition(ctx$1));
            var ctx$2 = match$1[0];
            var rbraceToken = ParseContext$Poly.currentToken(ctx$2);
            if (rbraceToken !== undefined && rbraceToken.token === 13) {
              return [
                      ParseContext$Poly.incrementPosition(ctx$2),
                      {
                        TAG: /* DICTIONARY_TYPE */4,
                        keyType: match[1],
                        valueType: match$1[1],
                        leftBrace: typeToken,
                        colon: colonToken,
                        rightBrace: rbraceToken
                      }
                    ];
            } else {
              return ParseError$Poly.pushError(ctx$2, {
                          TAG: /* EXPRESSION_ERROR */1,
                          _0: {
                            TAG: /* EXPECTING_DICTIONARY_TYPE_RBRACE */14,
                            lbraceToken: typeToken,
                            errorToken: rbraceToken
                          }
                        });
            }
        case /* LBRACKET */14 :
            var match$2 = typeExpression(ParseContext$Poly.incrementPosition(ctx));
            var ctx$3 = match$2[0];
            var rbracketToken = ParseContext$Poly.currentToken(ctx$3);
            var exit = 0;
            if (rbracketToken !== undefined) {
              var match$3 = rbracketToken.token;
              if (typeof match$3 === "number") {
                if (match$3 === 2) {
                  return ParseError$Poly.pushError(ctx$3, {
                              TAG: /* EXPRESSION_ERROR */1,
                              _0: {
                                TAG: /* UNEXPECTED_COMMA_IN_ARRAY_TYPE */12,
                                lbracketToken: typeToken,
                                commaToken: rbracketToken
                              }
                            });
                }
                if (match$3 === 15) {
                  return [
                          ParseContext$Poly.incrementPosition(ctx$3),
                          {
                            TAG: /* ARRAY_TYPE */1,
                            typeExpression: match$2[1],
                            leftBracket: typeToken,
                            rightBracket: rbracketToken
                          }
                        ];
                }
                exit = 2;
              } else {
                exit = 2;
              }
            } else {
              exit = 2;
            }
            if (exit === 2) {
              return ParseError$Poly.pushError(ctx$3, {
                          TAG: /* EXPRESSION_ERROR */1,
                          _0: {
                            TAG: /* EXPECTING_ARRAY_TYPE_RBRACKET */13,
                            lbracketToken: typeToken,
                            errorToken: rbracketToken
                          }
                        });
            }
            break;
        default:
          
      }
    } else {
      switch (name.TAG | 0) {
        case /* IDENTIFIER */0 :
            var name$1 = name._0;
            var warning = {
              TAG: /* EXPRESSION_ERROR */1,
              _0: {
                TAG: /* EXPECTING_UPPERCASE_TYPE */10,
                errorToken: typeToken
              }
            };
            var beginningOfToken = Position$Poly.startPos(typeToken.range);
            var firstLetterOfToken = Position$Poly.advanceCol(beginningOfToken, 1);
            var fixes_0 = {
              TAG: /* REPLACE */2,
              range: {
                TAG: /* RANGE */1,
                _0: beginningOfToken,
                _1: firstLetterOfToken
              },
              text: $$String.uppercase_ascii($$String.sub(name$1, 0, 1))
            };
            var fixes = {
              hd: fixes_0,
              tl: /* [] */0
            };
            var ctx$4 = ParseError$Poly.pushWarningFixes(ctx, warning, fixes);
            return [
                    ParseContext$Poly.incrementPosition(ctx$4),
                    {
                      TAG: /* TYPE */0,
                      name: name$1,
                      token: typeToken
                    }
                  ];
        case /* TYPECLASS */2 :
            return [
                    ParseContext$Poly.incrementPosition(ctx),
                    {
                      TAG: /* TYPE */0,
                      name: name._0,
                      token: typeToken
                    }
                  ];
        default:
          
      }
    }
  }
  return ParseError$Poly.pushError(ctx, {
              TAG: /* EXPRESSION_ERROR */1,
              _0: {
                TAG: /* UNEXPECTED_TYPE_TOKEN */11,
                errorToken: typeToken
              }
            });
}

function primary(ctx) {
  var match = literal(ctx);
  return postfix(match[0], match[1]);
}

function term(ctx) {
  return ExpressionUtils$Poly.leftAssociativeBinary(ctx, {
              hd: [
                /* PLUS */17,
                /* PLUS_OP */6
              ],
              tl: {
                hd: [
                  /* MINUS */18,
                  /* MINUS_OP */7
                ],
                tl: /* [] */0
              }
            }, factor);
}

function text(_ctx, startQuoteToken, _accumulated) {
  while(true) {
    var accumulated = _accumulated;
    var ctx = _ctx;
    var endQuoteToken = ParseContext$Poly.currentToken(ctx);
    if (endQuoteToken !== undefined) {
      var t = endQuoteToken.token;
      if (Caml_obj.caml_equal(t, startQuoteToken.token)) {
        return ExpressionUtils$Poly.advance(ctx, {
                    TAG: /* LITERAL */18,
                    _0: {
                      TAG: /* TEXT */1,
                      startQuoteToken: startQuoteToken,
                      endQuoteToken: endQuoteToken,
                      text: accumulated
                    }
                  });
      }
      if (typeof t === "number") {
        if (t === /* INTERP_START */8) {
          var match = equality(ParseContext$Poly.incrementPosition(ctx));
          var ctx$1 = match[0];
          var interpEndToken = ParseContext$Poly.currentToken(ctx$1);
          if (interpEndToken === undefined) {
            return ParseError$Poly.pushError(ctx$1, {
                        TAG: /* EXPRESSION_ERROR */1,
                        _0: {
                          TAG: /* EXPECTING_INTERP_END */4,
                          interpStartToken: endQuoteToken,
                          errorToken: interpEndToken
                        }
                      });
          }
          if (interpEndToken.token !== 9) {
            return ParseError$Poly.pushError(ctx$1, {
                        TAG: /* EXPRESSION_ERROR */1,
                        _0: {
                          TAG: /* EXPECTING_INTERP_END */4,
                          interpStartToken: endQuoteToken,
                          errorToken: interpEndToken
                        }
                      });
          }
          _accumulated = Pervasives.$at(accumulated, {
                hd: {
                  TAG: /* INTERPOLATE */1,
                  expression: match[1],
                  interpStartToken: endQuoteToken,
                  interpEndToken: interpEndToken
                },
                tl: /* [] */0
              });
          _ctx = ParseContext$Poly.incrementPosition(ctx$1);
          continue ;
        }
        
      } else if (t.TAG === /* TEXT */3) {
        _accumulated = Pervasives.$at(accumulated, {
              hd: {
                TAG: /* CONTENTS */0,
                text: t._0,
                textToken: endQuoteToken
              },
              tl: /* [] */0
            });
        _ctx = ParseContext$Poly.incrementPosition(ctx);
        continue ;
      }
      
    }
    return ParseError$Poly.pushError(ctx, {
                TAG: /* EXPRESSION_ERROR */1,
                _0: {
                  TAG: /* UNEXPECTED_TEXT_TOKEN */5,
                  startQuoteToken: startQuoteToken,
                  errorToken: endQuoteToken
                }
              });
  };
}

function postfix(_ctx, _result) {
  while(true) {
    var result = _result;
    var ctx = _ctx;
    var lbracket = ParseContext$Poly.currentToken(ctx);
    if (lbracket === undefined) {
      return [
              ctx,
              result
            ];
    }
    var match = lbracket.token;
    if (typeof match !== "number") {
      return [
              ctx,
              result
            ];
    }
    if (match !== 10) {
      if (match !== 14) {
        return [
                ctx,
                result
              ];
      }
      var ctx$1 = ParseContext$Poly.incrementPosition(ctx);
      var match$1 = equality(ctx$1);
      var ctx$2 = match$1[0];
      var rbracket = ParseContext$Poly.currentToken(ctx$2);
      if (rbracket !== undefined && rbracket.token === 15) {
        var match$2 = ExpressionUtils$Poly.advance(ctx$2, {
              TAG: /* INDEX */16,
              expression: result,
              index: match$1[1],
              lbracket: lbracket,
              rbracket: rbracket
            });
        _result = match$2[1];
        _ctx = match$2[0];
        continue ;
      }
      return ParseError$Poly.pushErrorFixes(ctx$2, {
                  TAG: /* EXPRESSION_ERROR */1,
                  _0: {
                    TAG: /* EXPECTING_INDEX_RBRACKET */3,
                    lbracket: lbracket,
                    errorToken: rbracket
                  }
                }, {
                  hd: {
                    TAG: /* INSERT */0,
                    position: ParseContext$Poly.currentPosition(ctx$2),
                    text: "]"
                  },
                  tl: /* [] */0
                });
    }
    var ctx$3 = ParseContext$Poly.incrementPosition(ctx);
    return tuple(ctx$3, lbracket, /* CALL */{
                _0: result
              });
  };
}

function literal(ctx) {
  var token = ParseContext$Poly.currentToken(ctx);
  if (token !== undefined) {
    var s = token.token;
    if (typeof s === "number") {
      switch (s) {
        case /* RANGE */5 :
            var ctx$1 = ParseContext$Poly.incrementPosition(ctx);
            var match = literal(ctx$1);
            return [
                    match[0],
                    {
                      TAG: /* RANGE */12,
                      _0: {
                        startExpression: undefined,
                        endExpression: match[1],
                        operator: token
                      }
                    }
                  ];
        case /* QUOTE */6 :
        case /* TICK */7 :
            return text(ParseContext$Poly.incrementPosition(ctx), token, /* [] */0);
        case /* LPAREN */10 :
            var ctx$2 = ParseContext$Poly.incrementPosition(ctx);
            return tuple(ctx$2, token, /* TUPLE */0);
        case /* LBRACE */12 :
            var match$1 = dictionary(ParseContext$Poly.incrementPosition(ctx), token);
            return [
                    match$1[0],
                    {
                      TAG: /* LITERAL */18,
                      _0: {
                        TAG: /* DICTIONARY */5,
                        _0: match$1[1]
                      }
                    }
                  ];
        case /* LBRACKET */14 :
            var match$2 = array(ParseContext$Poly.incrementPosition(ctx), token);
            return [
                    match$2[0],
                    {
                      TAG: /* LITERAL */18,
                      _0: {
                        TAG: /* ARRAY */3,
                        _0: match$2[1]
                      }
                    }
                  ];
        default:
          
      }
    } else {
      switch (s.TAG | 0) {
        case /* IDENTIFIER */0 :
            return ExpressionUtils$Poly.advance(ctx, {
                        TAG: /* VARIABLE */15,
                        _0: {
                          name: s._0,
                          variableToken: token
                        }
                      });
        case /* NUMBER */1 :
            return ExpressionUtils$Poly.advance(ctx, {
                        TAG: /* LITERAL */18,
                        _0: {
                          TAG: /* NUMBER */0,
                          number: Caml_format.caml_float_of_string(s._0),
                          token: token
                        }
                      });
        case /* TYPECLASS */2 :
            var i = s._0;
            var warning = {
              TAG: /* EXPRESSION_ERROR */1,
              _0: {
                TAG: /* EXPECTING_LOWERCASE_VARIABLE */1,
                errorToken: token
              }
            };
            var beginningOfToken = Position$Poly.startPos(token.range);
            var firstLetterOfToken = Position$Poly.advanceCol(beginningOfToken, 1);
            var fixes_0 = {
              TAG: /* REPLACE */2,
              range: {
                TAG: /* RANGE */1,
                _0: beginningOfToken,
                _1: firstLetterOfToken
              },
              text: $$String.lowercase_ascii($$String.sub(i, 0, 1))
            };
            var fixes = {
              hd: fixes_0,
              tl: /* [] */0
            };
            var ctx$3 = ParseError$Poly.pushWarningFixes(ctx, warning, fixes);
            return [
                    ParseContext$Poly.incrementPosition(ctx$3),
                    {
                      TAG: /* VARIABLE */15,
                      _0: {
                        name: i,
                        variableToken: token
                      }
                    }
                  ];
        default:
          
      }
    }
  }
  return ParseError$Poly.pushError(ctx, {
              TAG: /* EXPRESSION_ERROR */1,
              _0: {
                TAG: /* UNEXPECTED_PRIMARY_TOKEN */0,
                errorToken: token
              }
            });
}

function unary(ctx) {
  return ExpressionUtils$Poly.rightAssociativeUnary(ctx, {
              hd: [
                /* MINUS */18,
                /* NEGATE_OP */0
              ],
              tl: {
                hd: [
                  /* NOT */25,
                  /* NOT_OP */1
                ],
                tl: /* [] */0
              }
            }, primary);
}

function power(ctx) {
  return ExpressionUtils$Poly.rightAssociativeBinary(ctx, {
              hd: [
                /* POWER */23,
                /* POWER_OP */10
              ],
              tl: /* [] */0
            }, range);
}

function comparison(ctx) {
  return ExpressionUtils$Poly.leftAssociativeBinary(ctx, {
              hd: [
                /* GREATER */21,
                /* GREATER_OP */2
              ],
              tl: {
                hd: [
                  /* LESSER */22,
                  /* LESSER_OP */3
                ],
                tl: {
                  hd: [
                    /* GREATER_EQUALS */33,
                    /* GREATER_EQUALS_OP */4
                  ],
                  tl: {
                    hd: [
                      /* LESSER_EQUALS */34,
                      /* LESSER_EQUALS_OP */5
                    ],
                    tl: /* [] */0
                  }
                }
              }
            }, term);
}

function dictionary(ctx, leftBrace) {
  return Combinators$Poly.parseList(ctx, /* RBRACE */13, /* [] */0, (function (items, item) {
                return Pervasives.$at(items, {
                            hd: item,
                            tl: /* [] */0
                          });
              }), (function (expressionHandler) {
                var ctx = expressionHandler.ctx;
                var match = ParseContext$Poly.currentToken(ctx);
                var match$1 = ParseContext$Poly.nextToken(ctx);
                var exit = 0;
                if (match !== undefined) {
                  var match$2 = match.token;
                  var exit$1 = 0;
                  if (typeof match$2 === "number") {
                    exit = 1;
                  } else {
                    switch (match$2.TAG | 0) {
                      case /* IDENTIFIER */0 :
                      case /* TYPECLASS */2 :
                          exit$1 = 2;
                          break;
                      default:
                        exit = 1;
                    }
                  }
                  if (exit$1 === 2) {
                    if (match$1 !== undefined) {
                      if (match$1.token === 3) {
                        var key = {
                          TAG: /* LITERAL */18,
                          _0: {
                            TAG: /* DICTIONARY_ITEM_TEXT */2,
                            textToken: match,
                            contents: match$2._0
                          }
                        };
                        var ctx$1 = ParseContext$Poly.offsetPosition(ctx, 2);
                        var match$3 = equality(ctx$1);
                        return [
                                match$3[0],
                                {
                                  TAG: /* REGULAR */0,
                                  key: key,
                                  colon: match$1,
                                  value: match$3[1]
                                }
                              ];
                      }
                      exit = 1;
                    } else {
                      exit = 1;
                    }
                  }
                  
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  var expressionStartPosition = ParseContext$Poly.currentPosition(ctx);
                  var match$4 = equality(ctx);
                  var key$1 = match$4[1];
                  var ctx$2 = match$4[0];
                  var colon = ParseContext$Poly.currentToken(ctx$2);
                  var exit$2 = 0;
                  if (colon !== undefined) {
                    if (colon.token === 3) {
                      var match$5 = equality(ParseContext$Poly.incrementPosition(ctx$2));
                      return [
                              match$5[0],
                              {
                                TAG: /* REGULAR */0,
                                key: key$1,
                                colon: colon,
                                value: match$5[1]
                              }
                            ];
                    }
                    exit$2 = 2;
                  } else {
                    exit$2 = 2;
                  }
                  if (exit$2 === 2) {
                    if (key$1.TAG === /* VARIABLE */15) {
                      return [
                              ctx$2,
                              {
                                TAG: /* SHORTFORM */1,
                                key: key$1._0
                              }
                            ];
                    } else {
                      return ParseError$Poly.pushError(ctx$2, {
                                  TAG: /* EXPRESSION_ERROR */1,
                                  _0: {
                                    TAG: /* DICTIONARY_NONVARIABLE_SHORTFORM */9,
                                    range: {
                                      TAG: /* RANGE */1,
                                      _0: expressionStartPosition,
                                      _1: ParseContext$Poly.currentPreviousTokenEndPosition(ctx$2)
                                    },
                                    lbraceToken: leftBrace
                                  }
                                });
                    }
                  }
                  
                }
                
              }), (function (listReturn) {
                return {
                        leftBrace: leftBrace,
                        rightBrace: listReturn.closingToken,
                        dictionaryCommas: listReturn.commas,
                        items: listReturn.elements
                      };
              }), (function (error) {
                return ParseError$Poly.pushError(ctx, {
                            TAG: /* EXPRESSION_ERROR */1,
                            _0: {
                              TAG: /* UNEXPECTED_DICTIONARY_TOKEN */8,
                              errorToken: error.errorToken,
                              leftBrace: leftBrace,
                              commas: error.commas
                            }
                          });
              }));
}

function array(ctx, lbracket) {
  return Combinators$Poly.parseList(ctx, /* RBRACKET */15, /* [] */0, (function (elements, e) {
                return Pervasives.$at(elements, {
                            hd: e,
                            tl: /* [] */0
                          });
              }), (function (expressionHandler) {
                return equality(expressionHandler.ctx);
              }), (function (listReturn) {
                return {
                        lbracket: lbracket,
                        rbracket: listReturn.closingToken,
                        commas: listReturn.commas,
                        elements: listReturn.elements
                      };
              }), (function (error) {
                return ParseError$Poly.pushError(ctx, {
                            TAG: /* EXPRESSION_ERROR */1,
                            _0: {
                              TAG: /* UNEXPECTED_ARRAY_TOKEN */2,
                              errorToken: error.errorToken,
                              lbracket: lbracket,
                              commas: error.commas
                            }
                          });
              }));
}

function tuple(ctx, lparen, mode) {
  var failIfUnnamed = function (range, commas, namedTerms) {
    if (List.length(namedTerms) <= 0) {
      return ;
    }
    var mode$1 = mode ? /* CALL */1 : /* TUPLE */0;
    return ParseError$Poly.pushError(ctx, {
                TAG: /* EXPRESSION_ERROR */1,
                _0: {
                  TAG: /* TUPLE_UNNAMED_AFTER_NAMED */7,
                  range: range,
                  lparen: lparen,
                  commas: commas,
                  namedTerms: List.length(namedTerms),
                  mode: mode$1
                }
              });
  };
  var match = ParseContext$Poly.currentToken(ctx);
  if (mode && match !== undefined && match.token === 11) {
    return ExpressionUtils$Poly.advance(ctx, {
                TAG: /* CALL */17,
                expression: mode._0,
                unnamedArgs: /* [] */0,
                namedArgs: /* [] */0,
                lparen: lparen,
                rparen: match,
                callCommas: /* [] */0
              });
  }
  return Combinators$Poly.parseList(ctx, /* RPAREN */11, {
              unnamedTerms: /* [] */0,
              namedTerms: /* [] */0
            }, (function (elements, item) {
                if (item.TAG === /* UNNAMED */0) {
                  return {
                          unnamedTerms: Pervasives.$at(elements.unnamedTerms, {
                                hd: item._0,
                                tl: /* [] */0
                              }),
                          namedTerms: elements.namedTerms
                        };
                }
                var match = item._0;
                return {
                        unnamedTerms: elements.unnamedTerms,
                        namedTerms: Pervasives.$at(elements.namedTerms, {
                              hd: [
                                match[0],
                                match[1],
                                match[2]
                              ],
                              tl: /* [] */0
                            })
                      };
              }), (function (handler) {
                var ctx = handler.ctx;
                var expressionStartPosition = ParseContext$Poly.currentPosition(ctx);
                var match = equality(ctx);
                var e = match[1];
                var ctx$1 = match[0];
                var expressionEndPosition = ParseContext$Poly.currentPreviousTokenEndPosition(ctx$1);
                var expressionRange = {
                  TAG: /* RANGE */1,
                  _0: expressionStartPosition,
                  _1: expressionEndPosition
                };
                var match$1 = ParseContext$Poly.currentToken(ctx$1);
                if (e.TAG === /* VARIABLE */15 && match$1 !== undefined && match$1.token === 3) {
                  var match$2 = equality(ParseContext$Poly.incrementPosition(ctx$1));
                  return [
                          match$2[0],
                          {
                            TAG: /* NAMED */1,
                            _0: [
                              e._0.name,
                              match$1,
                              match$2[1]
                            ]
                          }
                        ];
                }
                failIfUnnamed(expressionRange, handler.commas, handler.elements.namedTerms);
                return [
                        ctx$1,
                        {
                          TAG: /* UNNAMED */0,
                          _0: e
                        }
                      ];
              }), (function (returnHandler) {
                var rparen = returnHandler.closingToken;
                var unnamedTerms = returnHandler.elements.unnamedTerms;
                var namedTerms = returnHandler.elements.namedTerms;
                var commas = returnHandler.commas;
                if (mode) {
                  return {
                          TAG: /* CALL */17,
                          expression: mode._0,
                          unnamedArgs: unnamedTerms,
                          namedArgs: namedTerms,
                          lparen: lparen,
                          rparen: rparen,
                          callCommas: commas
                        };
                }
                if (unnamedTerms && !unnamedTerms.tl && !namedTerms && !commas) {
                  return {
                          TAG: /* GROUPING */0,
                          _0: {
                            expression: unnamedTerms.hd,
                            lparen: lparen,
                            rparen: rparen
                          }
                        };
                }
                return {
                        TAG: /* LITERAL */18,
                        _0: {
                          TAG: /* TUPLE */4,
                          _0: {
                            tupleType: /* PAREN_ENCLOSED */{
                              lparen: lparen,
                              rparen: rparen
                            },
                            unnamedTerms: unnamedTerms,
                            namedTerms: namedTerms,
                            tupleCommas: commas
                          }
                        }
                      };
              }), (function (error) {
                return ParseError$Poly.pushError(ctx, {
                            TAG: /* EXPRESSION_ERROR */1,
                            _0: {
                              TAG: /* UNEXPECTED_GROUPING_TOKEN */6,
                              errorToken: error.errorToken,
                              lparen: lparen,
                              commas: error.commas,
                              namedTerms: List.length(error.elements.namedTerms),
                              unnamedTerms: List.length(error.elements.unnamedTerms)
                            }
                          });
              }));
}

function factor(ctx) {
  return ExpressionUtils$Poly.leftAssociativeBinary(ctx, {
              hd: [
                /* DIVIDE */20,
                /* DIVIDE_OP */8
              ],
              tl: {
                hd: [
                  /* MULTIPLY */19,
                  /* MULTIPLY_OP */9
                ],
                tl: /* [] */0
              }
            }, power);
}

var expression = equality;

function colonType(ctx) {
  var colonToken = ParseContext$Poly.currentToken(ctx);
  if (colonToken === undefined) {
    return [
            ctx,
            undefined
          ];
  }
  if (colonToken.token !== 3) {
    return [
            ctx,
            undefined
          ];
  }
  var match = typeExpression(ParseContext$Poly.incrementPosition(ctx));
  return [
          match[0],
          {
            colon: colonToken,
            typeExpression: match[1]
          }
        ];
}

function variable(ctx) {
  var variableToken = ParseContext$Poly.currentToken(ctx);
  if (variableToken !== undefined) {
    var match = variableToken.token;
    var exit = 0;
    if (typeof match !== "number") {
      switch (match.TAG | 0) {
        case /* IDENTIFIER */0 :
        case /* TYPECLASS */2 :
            exit = 2;
            break;
        default:
          
      }
    }
    if (exit === 2) {
      var v = variableToken.token;
      var ctx$1;
      if (typeof v === "number" || v.TAG !== /* TYPECLASS */2) {
        ctx$1 = ctx;
      } else {
        var warning = {
          TAG: /* EXPRESSION_ERROR */1,
          _0: {
            TAG: /* EXPECTING_LOWERCASE_VARIABLE */1,
            errorToken: variableToken
          }
        };
        var beginningOfToken = Position$Poly.startPos(variableToken.range);
        var firstLetterOfToken = Position$Poly.advanceCol(beginningOfToken, 1);
        var fixes_0 = {
          TAG: /* REPLACE */2,
          range: {
            TAG: /* RANGE */1,
            _0: beginningOfToken,
            _1: firstLetterOfToken
          },
          text: $$String.lowercase_ascii($$String.sub(v._0, 0, 1))
        };
        var fixes = {
          hd: fixes_0,
          tl: /* [] */0
        };
        ctx$1 = ParseError$Poly.pushWarningFixes(ctx, warning, fixes);
      }
      return [
              ParseContext$Poly.incrementPosition(ctx$1),
              {
                name: match._0,
                variableToken: variableToken
              }
            ];
    }
    
  }
  return ParseError$Poly.pushError(ctx, {
              TAG: /* STATEMENT_ERROR */2,
              _0: {
                TAG: /* EXPECTING_VARIABLE_DECLARATION_STATEMENT */2,
                errorToken: variableToken,
                position: ParseContext$Poly.currentPosition(ctx)
              }
            });
}

function variableType(ctx) {
  return [
          ctx,
          undefined
        ];
}

function variableDeclaration(ctx) {
  var match = variable(ctx);
  var match$1 = colonType(match[0]);
  return [
          match$1[0],
          {
            variable: match[1],
            variableType: match$1[1]
          }
        ];
}

exports.variableType = variableType;
exports.array = array;
exports.dictionary = dictionary;
exports.postfix = postfix;
exports.text = text;
exports.tuple = tuple;
exports.literal = literal;
exports.primary = primary;
exports.unary = unary;
exports.range = range;
exports.power = power;
exports.factor = factor;
exports.term = term;
exports.comparison = comparison;
exports.equality = equality;
exports.expression = expression;
exports.variableDeclaration = variableDeclaration;
exports.variable = variable;
exports.colonType = colonType;
exports.typeExpression = typeExpression;
/* No side effect */
