'use strict';

var React = require("react");
var App$Poly = require("./App.bs.js");
var ReactDOMRe = require("reason-react/src/legacy/ReactDOMRe.bs.js");

((require("./index.css")));

ReactDOMRe.renderToElementWithId(React.createElement(App$Poly.make, {}), "root");

/*  Not a pure module */
