'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var $$String = require("rescript/lib/js/string.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_string = require("rescript/lib/js/caml_string.js");

function getChar(s, c) {
  if (c >= s.length || c < 0) {
    return ;
  } else {
    return Caml_string.get(s, c);
  }
}

function firstChar(s) {
  return getChar(s, 0);
}

function lastChar(s) {
  return getChar(s, s.length - 1 | 0);
}

function countChar(s, c) {
  var _i = 0;
  var _count = 0;
  while(true) {
    var count = _count;
    var i = _i;
    var chr = getChar(s, i);
    if (chr === undefined) {
      return count;
    }
    if (chr === c) {
      _count = count + 1 | 0;
      _i = i + 1 | 0;
      continue ;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function substrToEnd(s, i) {
  return $$String.sub(s, i, s.length - i | 0);
}

function stringTail(s) {
  if (s === "") {
    return "";
  } else {
    return substrToEnd(s, 1);
  }
}

function $$escape(s) {
  var result = JSON.stringify(s);
  if (result !== undefined) {
    return result;
  } else {
    return "\"\"";
  }
}

function pop(l) {
  return List.map((function (param) {
                return param[1];
              }), List.filter(function (param) {
                    return param[0] !== (List.length(l) - 1 | 0);
                  })(List.mapi((function (i, x) {
                        return [
                                i,
                                x
                              ];
                      }), l)));
}

function escapeWithoutQuotes(s) {
  var escaped = $$escape(s);
  return $$String.sub(escaped, 1, escaped.length - 2 | 0);
}

function join(l) {
  if (!l) {
    return "";
  }
  var tail = l.tl;
  var tail$1 = l.hd;
  if (tail) {
    return tail$1 + ("," + join(tail));
  } else {
    return tail$1;
  }
}

function listToString(l) {
  return "list{" + (join(l) + "}");
}

function tupleToString(l) {
  return "(" + (join(l) + ")");
}

function dictToString(fields) {
  var _dictToString = function (fields) {
    if (!fields) {
      return "";
    }
    var tail = fields.tl;
    var match = fields.hd;
    var value = match[1];
    var key = match[0];
    if (tail) {
      return key + (":" + (value + ("," + _dictToString(tail))));
    } else {
      return key + (":" + value);
    }
  };
  return "{" + (_dictToString(fields) + "}");
}

function boolToString(b) {
  if (b) {
    return "true";
  } else {
    return "false";
  }
}

function variantToString(variant, fields) {
  return variant + ("(" + (join(fields) + ")"));
}

function variantDictToString(variant, fields) {
  return variant + ("(" + (dictToString(fields) + ")"));
}

function emptyJoin(l) {
  if (!l) {
    return "";
  }
  var tail = l.tl;
  var tail$1 = l.hd;
  if (tail) {
    return tail$1 + emptyJoin(tail);
  } else {
    return tail$1;
  }
}

function index(l, i) {
  if (i >= 0 && i < List.length(l)) {
    return Caml_option.some(List.nth(l, i));
  }
  
}

function last(l) {
  var x = List.length(l);
  if (x !== 0) {
    return Caml_option.some(List.nth(l, x - 1 | 0));
  }
  
}

var floatToString = Pervasives.string_of_float;

function floatToStringFormat(f) {
  return f.toString();
}

function optionToString(x, toStr) {
  if (x !== undefined) {
    return "Some(" + (Curry._1(toStr, Caml_option.valFromOption(x)) + ")");
  } else {
    return "None";
  }
}

function preline(s, index) {
  var _index = index;
  var _accrued = "";
  while(true) {
    var accrued = _accrued;
    var index$1 = _index;
    var c = getChar(s, index$1);
    if (c === undefined) {
      return accrued;
    }
    if (c === 10) {
      return accrued;
    }
    _accrued = $$String.make(1, c) + accrued;
    _index = index$1 - 1 | 0;
    continue ;
  };
}

function postline(s, index) {
  var _index = index;
  var _accrued = "";
  while(true) {
    var accrued = _accrued;
    var index$1 = _index;
    var c = getChar(s, index$1);
    if (c === undefined) {
      return accrued;
    }
    if (c === 10) {
      return accrued;
    }
    _accrued = accrued + $$String.make(1, c);
    _index = index$1 + 1 | 0;
    continue ;
  };
}

function intLength(i) {
  return String(i).length;
}

function repeat(s, times) {
  if (times !== 0) {
    return s + repeat(s, times - 1 | 0);
  } else {
    return "";
  }
}

exports.getChar = getChar;
exports.firstChar = firstChar;
exports.lastChar = lastChar;
exports.countChar = countChar;
exports.substrToEnd = substrToEnd;
exports.stringTail = stringTail;
exports.$$escape = $$escape;
exports.pop = pop;
exports.escapeWithoutQuotes = escapeWithoutQuotes;
exports.join = join;
exports.listToString = listToString;
exports.tupleToString = tupleToString;
exports.dictToString = dictToString;
exports.boolToString = boolToString;
exports.variantToString = variantToString;
exports.variantDictToString = variantDictToString;
exports.emptyJoin = emptyJoin;
exports.index = index;
exports.last = last;
exports.floatToString = floatToString;
exports.floatToStringFormat = floatToStringFormat;
exports.optionToString = optionToString;
exports.preline = preline;
exports.postline = postline;
exports.intLength = intLength;
exports.repeat = repeat;
/* No side effect */
