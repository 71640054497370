'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Error$Poly = require("./compiler/diagnostic/Error.bs.js");
var Lexer$Poly = require("./compiler/lexer/Lexer.bs.js");
var Parse$Poly = require("./compiler/parser/Parse.bs.js");
var Token$Poly = require("./compiler/lexer/Token.bs.js");
var Utils$Poly = require("./compiler/utils/Utils.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Parser$Poly = require("./compiler/parser/Parser.bs.js");
var Syntax$Poly = require("./compiler/lexer/Syntax.bs.js");
var AstHtml$Poly = require("./compiler/parser/AstHtml.bs.js");
var Compile$Poly = require("./compiler/compiler/Compile.bs.js");
var Message$Poly = require("./compiler/diagnostic/Message.bs.js");
var Compiler$Poly = require("./compiler/compiler/Compiler.bs.js");
var LexError$Poly = require("./compiler/lexer/LexError.bs.js");
var AstFormat$Poly = require("./compiler/parser/AstFormat.bs.js");
var AstSimple$Poly = require("./compiler/parser/AstSimple.bs.js");
var Webapi__Dom__Document = require("bs-webapi/src/Webapi/Dom/Webapi__Dom__Document.bs.js");
var ReactCopyToClipboard = require("react-copy-to-clipboard");

var CopyClipboard = {};

function App(Props) {
  var match = React.useState(function () {
        return "";
      });
  var setCode = match[1];
  var code = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setIntervertedColors = match$1[1];
  var invertedColors = match$1[0];
  var preRef = React.useRef(null);
  var _updateScroll = function (elem) {
    var o = preRef.current;
    if (!(o == null)) {
      o.scrollTop = elem.scrollTop;
      return ;
    }
    
  };
  var doc = Webapi__Dom__Document.asHtmlDocument(document);
  if (doc !== undefined) {
    (
        invertedColors ? (function (prim0, prim1) {
              prim1.add(prim0);
              
            }) : (function (prim0, prim1) {
              prim1.remove(prim0);
              
            })
      )("inverted", Caml_option.valFromOption(doc).documentElement.classList);
  }
  var invertColors = function (param) {
    return Curry._1(setIntervertedColors, (function (param) {
                  return !invertedColors;
                }));
  };
  var updateScroll = function ($$event) {
    return _updateScroll($$event.target);
  };
  var updateScrollKeyboard = function ($$event) {
    return _updateScroll($$event.target);
  };
  var updateScrollClipboard = function ($$event) {
    return _updateScroll($$event.target);
  };
  var updateScrollFocus = function ($$event) {
    return _updateScroll($$event.target);
  };
  var updateScrollMouse = function ($$event) {
    return _updateScroll($$event.target);
  };
  var updateScrollUI = function ($$event) {
    return _updateScroll($$event.target);
  };
  var updateScrollWheel = function ($$event) {
    return _updateScroll($$event.target);
  };
  var lexContext = Lexer$Poly.lexSimpleProgram(code);
  var indexedCode = lexContext.indexedText;
  var tokens = lexContext.tokens;
  var parseContext = Parser$Poly.parseFromLex(lexContext);
  var match$2 = Parser$Poly.parse(parseContext);
  var parseTree = match$2[1];
  var parseContext$1 = match$2[0];
  var parseDiagnostics = parseContext$1.diagnostics;
  var compileDiagnostics = Compiler$Poly.compileDiagnosticsFromContexts(parseContext$1);
  var diagnosticFree = List.length(compileDiagnostics) === 0;
  var errorFree = List.length(List.filter(function (x) {
              return Compile$Poly.getDiagnosticLevel(x) === /* ERROR */0;
            })(compileDiagnostics)) === 0;
  var compilerMessages = Message$Poly.messagesToHtml(List.map((function (param) {
              return Error$Poly.errorToMessage(param[1], param[0], indexedCode);
            }), compileDiagnostics));
  var highlights = Syntax$Poly.syntaxHighlight(lexContext);
  var parseErrors = List.map((function (d) {
          return Compile$Poly.compileErrorToSimpleString(d[0]);
        }), compileDiagnostics);
  var errors = lexContext.errors;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "code"
                }, React.createElement("textarea", {
                      cols: 80,
                      value: code,
                      onCut: updateScrollClipboard,
                      onPaste: updateScrollClipboard,
                      onKeyDown: updateScrollKeyboard,
                      onKeyUp: updateScrollKeyboard,
                      onFocus: updateScrollFocus,
                      onBlur: updateScrollFocus,
                      onChange: (function ($$event) {
                          _updateScroll($$event.target);
                          var value = $$event.target.value;
                          return Curry._1(setCode, (function (param) {
                                        return value;
                                      }));
                        }),
                      onInput: updateScroll,
                      onClick: updateScrollMouse,
                      onDragOver: updateScrollMouse,
                      onScroll: updateScrollUI,
                      onWheel: updateScrollWheel
                    }), React.createElement("pre", {
                      ref: preRef
                    }, $$Array.of_list(List.mapi((function (i, highlight) {
                                return React.createElement("span", {
                                            key: String(i),
                                            className: Token$Poly.classForTokenType(highlight.tokenType)
                                          }, i === (List.length(highlights) - 1 | 0) && Caml_obj.caml_equal(Utils$Poly.lastChar(highlight.text), /* '\n' */10) ? highlight.text + " " : highlight.text);
                              }), highlights)))), errorFree ? React.createElement("p", undefined, React.createElement("button", {
                        onClick: (function (param) {
                            console.log(AstFormat$Poly.atomsFromString(code));
                            return Curry._1(setCode, (function (param) {
                                          return AstFormat$Poly.formatFromString(code, 80);
                                        }));
                          })
                      }, "Format")) : React.createElement(React.Fragment, undefined), diagnosticFree ? React.createElement(React.Fragment, undefined) : compilerMessages, React.createElement("div", undefined, "Parse"), React.createElement("div", {
                  className: "block"
                }, AstHtml$Poly.programToHtml(parseTree)), parseDiagnostics ? React.createElement("div", {
                    className: "errors"
                  }, "Parse Errors:", React.createElement("ul", undefined, $$Array.of_list(List.mapi((function (i, diagnostic) {
                                  return React.createElement("li", {
                                              key: String(i)
                                            }, Parse$Poly.parseDiagnosticToString(diagnostic));
                                }), parseDiagnostics)))) : React.createElement(React.Fragment, undefined), React.createElement(ReactCopyToClipboard.CopyToClipboard, {
                  text: "test(" + (Utils$Poly.$$escape(code) + (", () => expect(parse(" + (Utils$Poly.$$escape(code) + (")) == (list{" + (Utils$Poly.join(parseErrors) + ("}," + (AstSimple$Poly.programToSimpleString(parseTree) + "));"))))))),
                  children: React.createElement("button", undefined, "Copy test case to clipboard")
                }), React.createElement("div", {
                  className: "block"
                }, "Tokens"), React.createElement("ul", undefined, $$Array.of_list(List.mapi((function (i, token) {
                            return React.createElement("li", {
                                        key: String(i)
                                      }, Token$Poly.toString(token.token));
                          }), tokens))), errors ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: "errors"
                      }, "Errors:", React.createElement("ul", undefined, $$Array.of_list(List.mapi((function (i, error) {
                                      return React.createElement("li", {
                                                  key: String(i)
                                                }, LexError$Poly.errorToString(error));
                                    }), errors)))), React.createElement(ReactCopyToClipboard.CopyToClipboard, {
                        text: "test(" + (Utils$Poly.$$escape(code) + (", () => expect(lexErrors(" + (Utils$Poly.$$escape(code) + (")) == list{" + ($$Array.of_list(List.map((function (error) {
                                                return LexError$Poly.errorRepresentation(error.error);
                                              }), errors)).join(", ") + "});"))))),
                        children: React.createElement("button", undefined, "Copy test case to clipboard")
                      })) : React.createElement(ReactCopyToClipboard.CopyToClipboard, {
                    text: "test(" + (Utils$Poly.$$escape(code) + (", () => expect(lex(" + (Utils$Poly.$$escape(code) + (")) == [" + ($$Array.of_list(List.map((function (token) {
                                            return "Token." + Token$Poly.toString(token.token);
                                          }), tokens)).join(", ") + "]);"))))),
                    children: React.createElement("button", undefined, "Copy test case to clipboard")
                  }), React.createElement("div", {
                  className: "brightness"
                }, React.createElement("button", {
                      onClick: invertColors
                    }, "Invert colors")));
}

var make = App;

exports.CopyClipboard = CopyClipboard;
exports.make = make;
/* react Not a pure module */
